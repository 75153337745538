import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'antd/lib/icon';

import { BackButton, BackButtonText } from './styles';

const BackToEditButton = ({ resetMapWidth, closePresentMap }) => (
  <BackButton data-html2canvas-ignore onClick={closePresentMap}>
    <Icon type="arrow-left" />
    <BackButtonText onClick={resetMapWidth}>Back to edit page</BackButtonText>
  </BackButton>
);

BackToEditButton.propTypes = {
  resetMapWidth: PropTypes.func.isRequired,
  closePresentMap: PropTypes.func.isRequired,
};

export default BackToEditButton;
