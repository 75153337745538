import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import Dimensions from '.';
import { limitSize, defaultPixelDensity } from '../../constants';

const calculateSize = (size, pixelDensity) => size * (pixelDensity / defaultPixelDensity);

const getDimensions = (pixelDensity, props) => {
  const { offsetWidth, offsetHeight } = document.getElementById('editMaps');

  // TODO: fix this one
  const presentMapWidth = get(props, 'presentMapWidth', offsetWidth);
  const presentMapHeight = get(props, 'presentMapHeight', offsetHeight);

  const mapWidth = presentMapWidth ? Math.floor(presentMapWidth) : offsetWidth;
  const mapHeight = presentMapWidth ? Math.floor(presentMapHeight) : offsetHeight;

  const width = calculateSize(mapWidth, pixelDensity);
  const height = calculateSize(mapHeight, pixelDensity);

  return ({
    dimensions: `${width} x ${height} px`,
    width,
    height,
  });
};

const DimensionsContainer = ({ pixelDensity, ...props }) => {
  const { dimensions, width, height } = getDimensions(pixelDensity, props);

  const dimensionsAlert = width > limitSize || height > limitSize;

  return (
    <Dimensions
      dimensionsAlert={dimensionsAlert}
      dimensions={dimensions}
    />
  );
};

const mapStateToProps = ({ exportSettings }) => ({ ...exportSettings });

export default connect(mapStateToProps, null)(DimensionsContainer);
