import React from 'react';
import PropTypes from 'prop-types';

import { StyledRadio, StyledRadioImage, StyledThumbnailTitle } from './styles';


const Thumbnail = ({ title, selected, imageSrc }) => (
  <div>
    <StyledRadio title={title} selected={selected} />
    <div>
      <StyledRadioImage src={imageSrc} srcSet={`${imageSrc} 2x`} />
    </div>
    <StyledThumbnailTitle>{title}</StyledThumbnailTitle>
  </div>
);

Thumbnail.propTypes = {
  title: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  imageSrc: PropTypes.string.isRequired,
};

export default Thumbnail;
