import html2canvas from 'html2canvas';
import get from 'lodash/get';

import { DownloadImageLink } from './DownloadImageLink';

const removeImage = () => {
  const previewCapture = document.querySelector('#previewCapture');

  const image = get(previewCapture, 'firstChild', null);

  if (image) {
    previewCapture.removeChild(image);
  }
};

const createPreviewCaptureOptions = (pixelDensity, props) => {
  const scale = 3;
  const { offsetWidth, offsetHeight } = document.getElementById('editMaps');
  const width = get(props, 'presentMapWidth', offsetWidth);
  const height = get(props, 'presentMapHeight', offsetHeight);

  return ({
    scale,
    width,
    height,
  });
};

const calculatePercentageDiff = (size, maxSize) => {
  const diff = size - maxSize;

  return diff / size;
};

const normalizeSize = (previewCaptureOptions, editMapsElement) => {
  const captureOptions = { ...previewCaptureOptions };
  const { offsetWidth, offsetHeight } = editMapsElement;
  const { width, height } = previewCaptureOptions;

  if (width > offsetWidth) {
    const percentageDiff = calculatePercentageDiff(width, offsetWidth);

    captureOptions.height = height - (height * percentageDiff);
    captureOptions.width = offsetWidth;
  }

  if (height > offsetHeight) {
    const percentageDiff = calculatePercentageDiff(height, offsetHeight);

    captureOptions.width = width - (width * percentageDiff);
    captureOptions.height = offsetHeight;
  }

  return captureOptions;
};

export const createCapture = (pixelDensity, props, mapName, imageFormat) => {
  const editMapsElement = document.querySelector('#editMaps');
  const title = document.querySelector('.mapboxgl-ctrl-attrib');
  const previewCaptureOptions = createPreviewCaptureOptions(pixelDensity, props);

  // ignore mapgl attribution when make screenshot
  title.setAttribute('data-html2canvas-ignore', 'true');

  removeImage();

  const captureOptions = normalizeSize(previewCaptureOptions, editMapsElement);

  html2canvas(editMapsElement, captureOptions).then((canvas) => {
    canvas.toBlob((blob) => {
      document.querySelector('#previewCapture').appendChild(canvas);

      const downloadPreviewImageLink = document.getElementById('downloadPreviewImageLink');
      const {
        updateLink,
        createLink,
      } = DownloadImageLink({ blob, mapName, imageFormat });

      if (downloadPreviewImageLink) {
        updateLink();
        return;
      }

      createLink();
    });
  });
};

export const downloadCapturedImage = () => {
  const downloadImageLink = document.querySelector('#downloadPreviewImageLink');

  downloadImageLink.click();
};
