import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'antd/lib/switch';
import get from 'lodash/get';
import find from 'lodash/find';

import { StyledBoundariesSubtotals, StyledLabel } from './styles';

// TODO: move to constants
const createLabel = splitValue => (splitValue === 'MAP_SHAPE' ? 'Show dataset subtotals' : 'Show boundaries subtotals');

const BoundariesSubtotals = ({
  form,
  insightId,
  splitValue,
  aggregates,
}) => {
  const { getFieldDecorator } = form;
  const aggregate = find(aggregates, { id: insightId });
  const showSubtotals = get(aggregate, 'showSubtotals', false);
  const label = createLabel(splitValue);

  return (
    <StyledBoundariesSubtotals>
      {getFieldDecorator('showSubtotals', { valuePropName: 'checked', initialValue: showSubtotals })(
        <Switch />,
      )}
      <StyledLabel>{label}</StyledLabel>
    </StyledBoundariesSubtotals>
  );
};

BoundariesSubtotals.propTypes = {
  aggregates: PropTypes.array,
  splitValue: PropTypes.string,
  form: PropTypes.object.isRequired,
  insightId: PropTypes.string,
};

export default BoundariesSubtotals;
