import styled from 'styled-components';
import { Popup } from 'react-map-gl';

export const StyledTitleContainer = styled.div`
  background-color: #0a2541;
  color: #fff;
  padding: 10px;
`;

export const StyledPopup = styled(Popup)`
  .mapboxgl-popup-content {
    padding: 0;
  }
  
  .mapboxgl-popup-close-button {
    color: #fff;
  }
`;
