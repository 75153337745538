class DownloadFileLink {
  static getURLOrigin = () => {
    const { origin } = window.location;
    const { NODE_ENV } = process.env;

    return (NODE_ENV === 'development') ? 'http://polimap.grm.test:4000' : origin;
  };

  static createLink = (pageId) => {
    const originURL = DownloadFileLink.getURLOrigin();

    return `${originURL}/api/v1/maps/${pageId}.csv`;
  };
}

export default DownloadFileLink;
