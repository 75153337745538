import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

const IncludeMidpoint = ({ gradient, toggleMidpoint }) => {
  const handleChange = ({ target }) => {
    toggleMidpoint(target.checked);
  };

  const isMidpointIncluded = Boolean(gradient.midpointValue);

  return (
    <Checkbox
      onChange={handleChange}
      defaultChecked={isMidpointIncluded}
    >
      Include midpoint colour
    </Checkbox>
  );
};

IncludeMidpoint.propTypes = {
  gradient: PropTypes.shape({
    lowestValue: PropTypes.string,
    midpointValue: PropTypes.string,
    highestValue: PropTypes.string,
  }).isRequired,
  toggleMidpoint: PropTypes.func.isRequired,
};

export default React.memo(IncludeMidpoint);
