import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';

import { fetchShapes } from 'graphql/queries';
import Boundaries from '.';

const BoundariesContainer = ({ boundaries }) => {
  const { data } = useQuery(fetchShapes);
  const shapeGroups = get(data, 'shapeGroups', []);

  return <Boundaries shapeGroups={shapeGroups} boundaries={boundaries} />;
};

BoundariesContainer.propTypes = {
  boundaries: PropTypes.array,
};

export default BoundariesContainer;
