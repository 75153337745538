import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { AddModal, MapDatasetModal } from 'components';
import { useModal } from 'utils';

import RenderEditMapDatasets from '../RenderEditMapDatasets';

const MapDataset = ({ datasets, ...props }) => {
  const modalProps = useModal();

  return (
    <Fragment>
      <AddModal title="Datasets" {...modalProps} />
      <MapDatasetModal
        datasets={datasets}
        {...modalProps}
        {...props}
      />
      <RenderEditMapDatasets {...modalProps} datasets={datasets} {...props} />
    </Fragment>
  );
};

MapDataset.propTypes = {
  datasets: PropTypes.array,
  props: PropTypes.object,
};

export default MapDataset;
