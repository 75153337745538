import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMapGL from 'react-map-gl';
import get from 'lodash/get';

import { MapControl, EditMapPopup } from 'components';
import addCustomAttribution from 'components/shared/Map/mapAttribution';
import { zoomToDatapoints } from 'components/shared/Map/zoomToPoints';
import { accessToken } from 'utils/constants';

import EditDatasetsContextMenu from './EditDatasetsContextMenu/container';
import { StyledEditMap } from './style';


const useZoomHandling = (mapDatapoints, changeMapState) => {
  useEffect(() => {
    // if we remove/create/update datapoints we need to change zoom
    zoomToDatapoints(mapDatapoints, changeMapState, 'datasets');
    // eslint-disable-next-line
  }, []);
};

const useCustomMapAttribution = (mapRef) => {
  useEffect(() => {
    // add information icon on the map
    const mapInstance = get(mapRef, 'current', false);
    if (mapInstance) {
      addCustomAttribution(mapInstance);
    }
    // eslint-disable-next-line
  }, []);
};

const usePopupDestroying = ({ selectPointEditMap, closeContextMenu }) => {
  useEffect(() => () => {
    selectPointEditMap({});
    closeContextMenu();
    // eslint-disable-next-line
  }, []);
};


const EditMapDatasets = ({
  mapDatapoints,
  mapViewport,
  changeMapViewport,
  onViewportChange,
  showMapPopup,
  selectPointEditMap,
  closeContextMenu,
  handleContextMenu,
}) => {
  const mapRef = useRef();
  useCustomMapAttribution(mapRef);

  useZoomHandling(mapDatapoints, changeMapViewport);

  usePopupDestroying({ selectPointEditMap, closeContextMenu });

  const { viewport } = mapViewport;

  return (
    <StyledEditMap>
      <ReactMapGL
        width="100%"
        height="100%"
        ref={mapRef}
        mapStyle={mapDatapoints}
        attributionControl={false}
        mapboxApiAccessToken={accessToken}
        onViewportChange={onViewportChange}
        onContextMenu={handleContextMenu}
        onClick={showMapPopup}
        {...viewport}
      >
        <MapControl />
        <EditDatasetsContextMenu />
        <EditMapPopup showEditButtons />
      </ReactMapGL>
    </StyledEditMap>
  );
};

EditMapDatasets.propTypes = {
  mapViewport: PropTypes.object.isRequired,
  mapDatapoints: PropTypes.object.isRequired,
  changeMapViewport: PropTypes.func.isRequired,
  onViewportChange: PropTypes.func.isRequired,
  selectPointEditMap: PropTypes.func.isRequired,
  showMapPopup: PropTypes.func.isRequired,
};

export default EditMapDatasets;
