import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { StyledContainer } from './style';
import Locations from './Locations';

const PreviewLocations = (marker) => {
  const areLocationsExist = get(marker, 'latitude', false);

  return areLocationsExist ? (
    <StyledContainer>
      <h4>Preview</h4>
      <Locations marker={marker} />
    </StyledContainer>
  ) : null;
};

PreviewLocations.propTypes = {
  marker: PropTypes.object,
};

export default PreviewLocations;
