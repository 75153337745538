export const noneMapStyle = {
  version: 8,
  name: 'Basic',
  metadata: {
    'mapbox:autocomposite': false,
    'mapbox:type': 'template',
    'maputnik:renderer': 'mbgljs',
    'openmaptiles:version': '3.x',
    'openmaptiles:mapbox:owner': 'openmaptiles',
    'openmaptiles:mapbox:source:url': 'mapbox://openmaptiles.4qljc88t',
  },
  sources: {
    boundaries: { type: 'vector' },
    openmaptiles: { type: 'vector', url: 'https://api.maptiler.com/tiles/v3/tiles.json?key=VgrAEOueVJidR76bfN0w' },
  },
  glyphs: 'https://api.maptiler.com/fonts/{fontstack}/{range}.pbf?key=VgrAEOueVJidR76bfN0w',
  layers: [
    {
      id: 'background',
      type: 'background',
      paint: { 'background-color': 'white' },
    },
  ],
  id: 'basic',
  zoom: 0.2784493448532956,
  bearing: 0,
  pitch: 0,
};
