class TreeShapes {
  static normalizeChildren = shapes => shapes.reduce((acc, { id, name }) => {
    acc.push({
      key: id,
      title: name,
      value: id,
    });

    return acc;
  }, []);

  static normalizeTreeData = shapeGroups => shapeGroups.reduce((acc, { id, name, shapes }) => {
    acc.push({
      key: `${id}-group`,
      title: name,
      value: `${id}-group`,
      children: TreeShapes.normalizeChildren(shapes),
    });

    return acc;
  }, []);

  static normalizeShapeFromProps = mapShapes => (
    mapShapes.map(({ name, shape }) => ({ label: name, value: shape.id }))
  );
}

export default TreeShapes;
