import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useRouter } from 'utils';

import AddLocationForm from '.';
import AddLocationService, { createMutationVariables } from './service';


let statistics = [];
const AddLocationFormContainer = ({
  okText,
  selectedDataPoint,
  statisticsFields,
  closeModal,
}) => {
  const { long, lat } = selectedDataPoint;

  const { pageId } = useRouter();
  const [address, getAddress] = useState(selectedDataPoint.address);
  const [locations, getLocations] = useState({ longitude: long, latitude: lat });

  const { addLocation } = AddLocationService(pageId, closeModal);

  const getStatistics = (data) => {
    statistics = data;
  };

  const createLocation = async ({ name, description }) => {
    const datasetId = Number(pageId);

    addLocation({
      ...createMutationVariables({
        name,
        description,
        datasetId,
        address,
        locations,
        statistics: statistics.statistics,
      }),
    });

    return null;
  };

  return (
    <AddLocationForm
      okText={okText}
      statisticsFields={statisticsFields}
      selectedDataPoint={selectedDataPoint}
      createLocation={createLocation}
      getStatistics={getStatistics}
      getAddress={getAddress}
      getLocations={getLocations}
    />
  );
};

AddLocationFormContainer.propTypes = {
  selectedDataPoint: PropTypes.object,
  okText: PropTypes.string.isRequired,
  statisticsFields: PropTypes.array,
  closeModal: PropTypes.func.isRequired,
};

AddLocationFormContainer.defaultProps = {
  selectedDataPoint: {
    id: '',
    name: '',
    address: '',
    properties: {
      description: '',
    },
  },
};

export default React.memo(AddLocationFormContainer);
