import styled from 'styled-components';
import Card from 'antd/lib/card';

export const BadgeWrapper = styled.div`
  .ant-badge-status-dot {
    width: 15px;
    height: 15px;
  }
  
  .ant-badge-status-text {
    display: none;
  }
`;

export const PresentMapDatasetContainer = styled(Card)`
  margin: .5rem;
  
  .ant-card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    
    &::before, &::after {
      content: none;
    }
  }
`;

export const PresentMapDatasetsLocations = styled.div`
  font-weight: 700;
  background-color: #f8f9fa;
  padding: 0 5px;
  margin-top: 5px;
`;
