import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';

import { Picker, PickedColor } from 'components';

import { ColorPickerLayout } from './styles';

const ColorPicker = ({ pickColor, initialColor }) => {
  const [visible, toggle] = useState(false);
  const [color, changeColor] = useState(initialColor);

  const togglePicker = () => toggle(!visible);

  const onChangeColor = ({ hex }) => {
    changeColor(hex);
    pickColor(hex);
  };

  return (
    <ColorPickerLayout>
      <Button onClick={togglePicker}>Choose colour</Button>
      <PickedColor color={color} />
      <Picker
        onChangeComplete={onChangeColor}
        visible={visible}
        togglePicker={togglePicker}
      />
    </ColorPickerLayout>
  );
};

ColorPicker.propTypes = {
  initialColor: PropTypes.string,
  pickColor: PropTypes.func.isRequired,
};

export default ColorPicker;
