import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/lib/modal';

import { CreateMapDatasetsForm, UpdateMapDatasetsForm } from 'components';

const MapDatasetModal = (props) => {
  const { closeModal, visible, type } = props;
  const isCreateMapDataset = type !== 'update';

  return (
    <Modal
      title="Map Dataset"
      visible={visible}
      onCancel={closeModal}
      footer={null}
    >
      {isCreateMapDataset
        ? <CreateMapDatasetsForm {...props} />
        : <UpdateMapDatasetsForm {...props} />
      }
    </Modal>
  );
};

MapDatasetModal.propTypes = {
  type: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default MapDatasetModal;
