import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'antd/lib/typography';
import isEmpty from 'lodash/isEmpty';

import { BoundariesContainer } from './styles';
import TreeSelectShapes from './TreeSelectShapes';

const { Title } = Typography;


const Boundaries = ({ shapeGroups, boundaries }) => (
  <BoundariesContainer>
    <Title level={4}>Boundaries</Title>
    {!isEmpty(shapeGroups) && (
      <TreeSelectShapes shapeGroups={shapeGroups} mapShapes={boundaries} />
    )}
  </BoundariesContainer>
);

Boundaries.propTypes = {
  shapeGroups: PropTypes.array,
  boundaries: PropTypes.array,
};

export default Boundaries;
