import styled from 'styled-components';
import Button from 'antd/lib/button';

export const StyledContainer = styled.div`
  background-color: #fafafa;
  padding: 12px;
  position: relative;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  color: #000;
`;

export const RemoveImportButton = styled(Button)`
  position: absolute;
  top: 5px;
  right: 0;
`;
