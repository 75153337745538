import styled from 'styled-components';

export const StyledThumbnailTitle = styled.p`
  font-weight: 600;
  margin-top: 5px;
`;

export const StyledRadio = styled.input.attrs(({ title, selected }) => ({
  type: 'radio',
  name: 'baseMap',
  value: title,
  defaultChecked: selected,
}))`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  
  &:checked + div {
    position: relative;  
    outline: 2px solid #00c300; 
     
    &:after {
      content: "\\2713";
      position: absolute;
      top: 0;
      left: 10px;
      display: block;
      font-size: 35px;
      color: #00c300;
    }
  }
`;

export const StyledRadioImage = styled.img`
 width: 150px;
 height: 140px;
`;
