import React from 'react';
import PropTypes from 'prop-types';

import { EditMapPageTitle, EditMapPageDescription } from 'components';
import { ifDataReturnComponent } from 'utils';


const EditMapPageInfo = ({ name, description }) => (
  <div>
    {ifDataReturnComponent(name)(<EditMapPageTitle title={name} />)}
    <EditMapPageDescription name={name} description={description} />
  </div>
);

EditMapPageInfo.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
};

EditMapPageInfo.defaultProps = {
  name: '',
  description: '',
};

export default EditMapPageInfo;
