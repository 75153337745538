const getRGB = (color) => {
  const r = color.substring(1, 3);
  const g = color.substring(3, 5);
  const b = color.substring(5, 7);

  return ({
    R: parseInt(r, 16),
    G: parseInt(g, 16),
    B: parseInt(b, 16),
  });
};

export const idealTextColor = (bgColor = '#ffffff') => {
  if (typeof bgColor === 'string') {
    const nThreshold = 105;
    const { R, G, B } = getRGB(bgColor);
    const bgDelta = (R * 0.299) + (G * 0.587) + (B * 0.114);

    return (255 - bgDelta) < nThreshold ? '#000' : '#fff';
  }

  return false;
};
