import styled from 'styled-components';
import Card from 'antd/lib/card';

export const ExportSettingsCard = styled(Card)`
  position: absolute;
  right: 50px;
  top: 50px;
  width: 525px;
  z-index: 2;
`;
