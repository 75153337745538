import { GET_IMPORT_LOCATIONS_PROGRESS_STATUS } from './types';

export const importLocationsProgressStatus = (state = {}, { type, progressStatus }) => {
  switch (type) {
    case GET_IMPORT_LOCATIONS_PROGRESS_STATUS:
      return { ...state, progressStatus };
    default:
      return {};
  }
};

export default importLocationsProgressStatus;
