import PropTypes from 'prop-types';
import React from 'react';

import { ColorPicker } from 'components';

const HeatmapColorPicker = ({ title, color, pickColor }) => (
  <div>
    <p>{title}</p>
    <ColorPicker initialColor={color} pickColor={pickColor} />
  </div>
);

HeatmapColorPicker.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  pickColor: PropTypes.func.isRequired,
};

HeatmapColorPicker.defaultProps = {
  color: '#fff',
};

export default HeatmapColorPicker;
