import { ADD_BOUNDARIES, REMOVE_BOUNDARIES } from './types';

const mapBoundariesReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ADD_BOUNDARIES:
      return { ...state, addBoundaries: payload, removeBoundaries: false };
    case REMOVE_BOUNDARIES:
      return { ...state, removeBoundaries: payload, addBoundaries: false };
    default:
      return state;
  }
};

export default mapBoundariesReducer;
