import { noneMapStyle } from './noneMapStyle';

const types = {
  LIGHT: 'positron',
  DARK: 'darkmatter',
  TERRAIN: 'basic',
};

const mapLink = mapType => `https://api.maptiler.com/maps/${types[mapType]}/style.json?key=VgrAEOueVJidR76bfN0w`;

export const fetchMap = mapType => fetch(mapLink(mapType))
  .then(res => res.json())
  .then(json => json);

export const createMapStyle = ({ basemap }) => {
  if (types[basemap]) {
    return fetchMap(basemap);
  }

  return noneMapStyle;
};
