import { DESTROY_DATA_POINT, UPDATE_DATA_POINT, CREATE_DATA_POINT } from './types';

const datapointReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DESTROY_DATA_POINT:
      return { ...state, ...payload };
    case UPDATE_DATA_POINT:
      return { ...state, ...payload };
    case CREATE_DATA_POINT:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default datapointReducer;
