import styled from 'styled-components';

export const StyledBoundItem = styled.div`
  display: flex;
  padding: 10px 15px;
  align-items: center;
  justify-content: space-between;
  
  button {
    margin-left: 1rem;
  }
  
  &:not(:last-of-type) {
    border-bottom: 1px solid #dee2e6;
  }
`;

export const StyledBoundName = styled.div`
  margin-right: 15px;
  flex: 1;
`;

export const StyledBoundState = styled.div`
  display: flex;
  flex: 1;
  width: max-content;
`;
