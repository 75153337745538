import React from 'react';
import PropTypes from 'prop-types';

import UseBoundsDispatch from '../UseBoundsDispatch';
import EditMapBoundsList from '.';

const EditMapBoundsListContainer = ({ shapes, renderBoundriesButtons }) => {
  const [addBoundaries, removeBoundaries] = UseBoundsDispatch();

  const handleAddBounds = (id) => {
    addBoundaries(id);
  };

  const handleRemoveBounds = (id) => {
    removeBoundaries(id);
  };

  return (
    <EditMapBoundsList
      shapes={shapes}
      renderBoundriesButtons={renderBoundriesButtons}
      handleAddBounds={handleAddBounds}
      handleRemoveBounds={handleRemoveBounds}
    />
  );
};

EditMapBoundsListContainer.propTypes = {
  renderBoundriesButtons: PropTypes.func,
  shapes: PropTypes.array.isRequired,
};

export default EditMapBoundsListContainer;
