import styled from 'styled-components';

export const PresentMapDescriptionContainer = styled.div`
  position: absolute;
  display: inline-block;
  width: 300px;
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  max-height: 95vh;
  overflow-x: hidden;
`;

export const StyledAggregateCardContainer = styled.div`
  margin: 0 0.5rem;
`;
