import React from 'react';
import PropTypes from 'prop-types';

const locationPrecision = number => parseFloat(number).toPrecision(8);

const Locations = ({ marker }) => {
  const { longitude, latitude } = marker;
  const lat = locationPrecision(latitude);
  const long = locationPrecision(longitude);

  return <span>{`Latitude: ${lat} Longitude ${long}`}</span>;
};

Locations.propTypes = {
  marker: PropTypes.object,
};

export default Locations;
