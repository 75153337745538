import styled from 'styled-components';

export const BackButton = styled.div`
  color: #007bff;
  margin: .5rem;
`;

export const BackButtonText = styled.span`
  margin-left: 5px;
  font-size: 15px;
  cursor: pointer;
`;
