import React from 'react';
import PropTypes from 'prop-types';
import Menu from 'antd/lib/menu';

import { StyledPopup, StyledMenuItem } from './style';


const ContextMenu = ({
  latitude,
  longitude,
  buttonText,
  handleOnCloseClick,
}) => (
  <StyledPopup
    closeOnClick
    tipSize={0}
    anchor="top-left"
    offsetLeft={5}
    closeButton={false}
    latitude={latitude}
    longitude={longitude}
    onClose={handleOnCloseClick}
  >
    <Menu>
      <StyledMenuItem>
        <span className="context-menu-item">{buttonText}</span>
      </StyledMenuItem>
    </Menu>
  </StyledPopup>
);

ContextMenu.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  buttonText: PropTypes.string,
  handleOnCloseClick: PropTypes.func,
};

export default ContextMenu;
