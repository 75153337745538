import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import isEmpty from 'lodash/isEmpty';

const { Option } = Select;

const styles = { width: 200 };

const WeightIntensity = ({
  heatmapWeightField,
  aggregatableFields,
  onChangeWeightIntensity,
}) => (
  <>
    <h3>Heatmap Intensity</h3>
    <p>Optional. Choose a numeric field in your dataset to increase how much each individual datapoint contributes to the appearance of your heatmap. If you don't choose a field, or do not have any numeric fields in your data, all locations will be weighted equally.</p>
    <Select
      style={styles}
      placeholder="Choose a field"
      defaultValue={heatmapWeightField || null}
      onChange={onChangeWeightIntensity}
    >
      <Option key="empty" value={null}>Choose a field</Option>
      {!isEmpty(aggregatableFields) && aggregatableFields.map(({ name }) => (
        <Option key={name} value={name}>{name}</Option>
      ))}
    </Select>
  </>
);

WeightIntensity.propTypes = {
  heatmapWeightField: PropTypes.string,
  aggregatableFields: PropTypes.array.isRequired,
  onChangeWeightIntensity: PropTypes.func.isRequired,
};

export default WeightIntensity;
