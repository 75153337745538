import React from 'react';
import PropTypes from 'prop-types';
import Select from 'antd/lib/select';

import { Label } from 'components';

import { StyledInputWrapper } from '../styles';

const { Option } = Select;

const ImageFormatSelect = ({ changeExportSettings }) => {
  const handleImageFormatChanges = (value) => {
    changeExportSettings({
      imageFormat: value,
    });
  };

  return (
    <StyledInputWrapper>
      <Label colon={false} label="Format">
        <Select defaultValue="png" onChange={handleImageFormatChanges}>
          <Option value="png">.png</Option>
          <Option value="jpg">.jpg</Option>
        </Select>
      </Label>
    </StyledInputWrapper>
  );
};

ImageFormatSelect.propTypes = {
  changeExportSettings: PropTypes.func.isRequired,
};

export default ImageFormatSelect;
