import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledInputWrapper = styled.div`
  .ant-select-selection {
     width: 210px;
  }
  
  .ant-form-item-control {
    line-height: 1;
  }
  
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const StyledSwitch = styled.div`
  height: 25px;
  display: flex;
  
  .ant-form-item-control-wrapper {
    float: left;
    margin-right: 10px;
  }
  
  .ant-form-item {
    margin-bottom: 0;
  }
`;
