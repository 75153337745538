import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/lib/modal';

import { AggregateDataForm } from 'components';

import UpdateInsightButton from './UpdateInsightButton';

const AggregateDataUpdate = (props) => {
  const { visible, closeModal, insightId } = props;

  return (
    <Modal
      title="Aggregate Data"
      okText="Save"
      visible={visible}
      onCancel={closeModal}
      footer={null}
    >
      <AggregateDataForm {...props}>
        {(formValues, { resetFields }, updateInsight) => (
          <UpdateInsightButton
            insightId={insightId}
            formValues={formValues}
            resetFields={resetFields}
            closeModal={closeModal}
            updateInsight={updateInsight}
          />
        )}
      </AggregateDataForm>
    </Modal>
  );
};

AggregateDataUpdate.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  insightId: PropTypes.string.isRequired,
  mapDatasets: PropTypes.array.isRequired,
  aggregatableFields: PropTypes.array.isRequired,
  aggregateName: PropTypes.string,
  aggregates: PropTypes.array.isRequired,
};

export default React.memo(AggregateDataUpdate);
