import { gql } from 'apollo-boost';

export const datasetDestroy = gql`
    mutation datasetDestroy($input: DatasetDestroyMutationInput!) {
        datasetDestroy(input: $input) {
            deletedId
        }
    }
`;

export const datasetCreate = gql`
    mutation datasetCreate($input: DatasetCreateMutationInput!) {
        datasetCreate(input: $input) {
            clientMutationId
            dataset {
                id
                name
                description
            }
        }
    }
`;

export const datasetUpdate = gql`
    mutation datasetUpdate($input: DatasetUpdateMutationInput!) {
        datasetUpdate(input: $input) {
            dataset {
                id
                name
                description
            }
        }
    }
`;

export const datasetImport = gql`
    mutation datasetImport($input: DatasetImportMutationInput!) {
        datasetImport(input: $input) {
            import {
                id
                fileName
                percentComplete
                errorMessage
            }
        }
    }
`;
