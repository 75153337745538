import { gql } from 'apollo-boost';

export const aggregateCreate = gql`
    mutation aggregateCreate($input: AggregateCreateMutationInput!) {
        aggregateCreate(input: $input) {
            aggregate {
                name
            }
        }
    }
`;

export const aggregateUpdate = gql`
    mutation aggregateUpdate($input: AggregateUpdateMutationInput!) {
        aggregateUpdate(input: $input) {
            aggregate {
                name
            }
        }
    }
`;

export const aggregateDestroy = gql`
    mutation aggregateDestroy($input: AggregateDestroyMutationInput!) {
        aggregateDestroy(input: $input) {
            deletedId
        }
    }
`;
