import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Section } from '../styles';
import { StyledShape } from './styles';

const shapesToShowCount = 5;

const MoreShapes = ({ shapes }) => {
  const moreShapesCount = shapes.length - shapesToShowCount;
  const areMoreShapes = shapes.length > 5;

  return areMoreShapes && <span>{`+ ${moreShapesCount} more`}</span>;
};

const Shapes = ({ shapes }) => {
  if (isEmpty(shapes)) {
    return null;
  }

  const shapesToShow = [...shapes];
  shapesToShow.length = shapesToShowCount;

  return (
    <Section>
      {shapesToShow.map(({ id, name }) => (
        <StyledShape key={id} color="#6c757d">{name}</StyledShape>))
      }
      <MoreShapes shapes={shapes} />
    </Section>
  );
};

Shapes.propTypes = {
  shapes: PropTypes.array,
};

Shapes.defaultProps = {
  shapes: [],
};

export default Shapes;
