import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { CreateModal, CreateView } from 'components';
import { useModal } from 'utils';

const Create = ({ buttonText, type }) => {
  const { openModal, ...modalProps } = useModal();

  return (
    <Fragment>
      <CreateView openModal={openModal} buttonText={buttonText} />
      <CreateModal type={type} {...modalProps} />
    </Fragment>
  );
};

Create.propTypes = {
  buttonText: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Create;
