const CHANGE_LOWEST_COLOR = 'CHANGE_LOWEST_COLOR';
const CHANGE_MIDPOINT_COLOR = 'CHANGE_MIDPOINT_COLOR';
const CHANGE_HIGHEST_COLOR = 'CHANGE_HIGHEST_COLOR';
const FETCH_INITIAL_COLORS = 'FETCH_INITIAL_COLORS';

export {
  CHANGE_LOWEST_COLOR,
  CHANGE_MIDPOINT_COLOR,
  CHANGE_HIGHEST_COLOR,
  FETCH_INITIAL_COLORS,
};
