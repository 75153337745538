import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import get from 'lodash/get';

import FieldsArray from './FieldsArray';

const parseStatisticsData = (statisticsData) => {
  let statistics = statisticsData;
  if (typeof statisticsData === 'string') {
    statistics = JSON.parse(statisticsData);
  }

  return statistics;
};

let statisticsValues = null;
const FormItems = ({
  statisticsFields,
  getStatistics,
  statistics,
}) => {
  if (getStatistics) {
    getStatistics(statisticsValues);
  }

  return (
    <Formik
      initialValues={{ statistics }}
      render={({ values }) => {
        statisticsValues = values;
        const statistics = get(values, 'statistics', []) || [];
        const statisticsData = parseStatisticsData(statistics);
        return (
          <FieldsArray statistics={statisticsData} statisticsFields={statisticsFields} />
        );
      }}
    />
  );
};

FormItems.propTypes = {
  statisticsFields: PropTypes.array,
  selectedDataPoint: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  getStatistics: PropTypes.func,
  statistics: PropTypes.array,
};

export default FormItems;
