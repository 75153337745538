import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/lib/modal';
import get from 'lodash/get';

import { CreateMapForm } from 'components';
import { rootPath } from 'utils/constants';
import { useRouter } from 'utils';

import CreateMapService from './service';

const CreateModal = ({ visible, closeModal, type }) => {
  const [modalFormValues, changeModalFormValues] = useState({});
  const { history, location } = useRouter();

  const pagePathName = get(location, 'pathname', rootPath);
  const { createEditPage } = CreateMapService({
    type,
    history,
    modalFormValues,
    pagePathName,
  });

  return (
    <Modal
      destroyOnClose
      title={`New ${type}`}
      visible={visible}
      footer={null}
      onCancel={closeModal}
    >
      <CreateMapForm
        type={type}
        createEditPage={createEditPage}
        getFormValues={changeModalFormValues}
      />
    </Modal>
  );
};

CreateModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default CreateModal;
