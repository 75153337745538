import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/lib/modal';

import { AggregateDataForm } from 'components';

import CreateAggregateButton from './CreateAggregateButton';

const AggregateDataCreate = ({
  id,
  visible,
  mapDatasets,
  closeModal,
  aggregatableFields,
}) => (
  <Modal
    title="Aggregate Data"
    okText="Save"
    visible={visible}
    onCancel={closeModal}
    destroyOnClose
    footer={null}
  >
    <AggregateDataForm
      id={id}
      mapDatasets={mapDatasets}
      aggregatableFields={aggregatableFields}
    >
      {(formValues, { resetFields }) => (
        <CreateAggregateButton
          formValues={formValues}
          closeModal={closeModal}
          resetFormFields={resetFields}
        />
      )}
    </AggregateDataForm>
  </Modal>
);

AggregateDataCreate.propTypes = {
  id: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  mapDatasets: PropTypes.array,
  closeModal: PropTypes.func.isRequired,
  aggregatableFields: PropTypes.array,
};

export default React.memo(AggregateDataCreate);
