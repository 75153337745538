import React from 'react';
import PropTypes from 'prop-types';
import Select from 'antd/lib/select';
import isEmpty from 'lodash/isEmpty';

import { StyledInputContainer } from '../styles';

const { Option } = Select;

const FieldSelect = ({
  initialValue,
  getFieldDecorator,
  aggregatableFields,
  areAllLocationsDisabled,
}) => (
  <StyledInputContainer>
    {getFieldDecorator('field', { initialValue })(
      <Select>
        <Option key="allLocations" value={null} disabled={areAllLocationsDisabled}>All locations</Option>
        {!isEmpty(aggregatableFields) && aggregatableFields.map(({ name }, index) => (
          <Option key={name + index} value={name}>{name}</Option>
        ))}
      </Select>,
    )}
  </StyledInputContainer>
);

FieldSelect.propTypes = {
  initialValue: PropTypes.string,
  getFieldDecorator: PropTypes.func.isRequired,
  aggregatableFields: PropTypes.array.isRequired,
  areAllLocationsDisabled: PropTypes.bool.isRequired,
};

export default FieldSelect;
