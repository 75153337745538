import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import {
  AddLocation,
  ImportLocationsModal,
  EditMapDatasetsContainer,
  EditDatasetPageInfo,
} from 'components';
import { useModal } from 'utils';
import usePageInfo from 'pages/edit/usePageInfo';
import { fetchDataset } from 'graphql/queries';

import updateMapDatapoints from './updateMapDatapoints';
import RenderDatapointsList from './RenderDatapointsList';

import { SideBar, ButtonsContainer } from './style';

const EditDatasets = (props) => {
  const {
    loading,
    pageInfo: {
      name,
      description,
      datapointsGeojson,
      imports,
      statisticsFields,
    },
  } = usePageInfo({ query: fetchDataset, type: 'dataset', ...props });

  const modalProps = useModal();
  const datapoint = useSelector(({ datapointReducer }) => datapointReducer);

  const datapointsGeojsonCopy = updateMapDatapoints(datapoint, datapointsGeojson);

  return (
    <Fragment>
      <SideBar>
        <EditDatasetPageInfo name={name} description={description} />
        <ButtonsContainer>
          <AddLocation statisticsFields={statisticsFields} {...props} />
          <ImportLocationsModal {...modalProps} {...props} />
        </ButtonsContainer>
        <RenderDatapointsList
          loading={loading}
          imports={imports}
          datapointsGeojson={datapointsGeojsonCopy}
        />
      </SideBar>
      {datapointsGeojsonCopy && (
        <EditMapDatasetsContainer
          statisticsFields={statisticsFields}
          datapointsGeojson={datapointsGeojsonCopy}
        />
      )}
    </Fragment>
  );
};

export default React.memo(EditDatasets);
