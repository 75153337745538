import styled from 'styled-components';
import Layout from 'antd/lib/layout';

const { Content: content } = Layout;

const Content = styled(content)`
  padding: 0.5rem 1rem;
`;

export default Content;
