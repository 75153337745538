import { useMutation } from '@apollo/react-hooks';
import get from 'lodash/get';

import { useRouter } from 'utils';
import { showErrorNotification } from 'components';
import { aggregateCreate, fetchMap } from 'graphql/queries';

import { createMutationVariables } from '../helpers';

const showErrorMessage = ({ error }) => {
  const errorMessage = get(error, 'graphQLErrors[0].message', false);
  showErrorNotification(errorMessage);
};

const AggregateCreateService = (closeModal) => {
  const { pageId } = useRouter();

  const [createAggregateMutation] = useMutation(aggregateCreate, {
    onError: error => showErrorMessage({ error }),
    onCompleted: () => {
      closeModal();
    },
  });

  const createAggregate = (formValues) => {
    const aggregatesVariables = createMutationVariables({ formValues, pageId });

    createAggregateMutation({
      variables: {
        input: { ...aggregatesVariables },
      },
      refetchQueries: [{
        query: fetchMap,
        variables: ({ id: pageId }),
      }],
    });
  };

  return [createAggregate];
};

export default AggregateCreateService;
