import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import get from 'lodash/get';

import { changeExportSettings } from 'state/exportSettings/actions';
import { defaultPixelDensity } from '../../constants';
import { getWidth, getHeight } from './utils';
import { StyledWrapper } from './styles';
import {
  ImageFormatSelect,
  WidthInput,
  HeightInput,
  PixelDensitySelect,
} from './ExportSettingsFormItems';
import ExportSettingsOptions from './ExportSettingsOptions/index';

const ExportSettingsForm = ({ changeExportSettings, presentMapUnits }) => {
  const initialValues = {
    pixelDensity: defaultPixelDensity,
    width: getWidth(defaultPixelDensity, presentMapUnits),
    height: getHeight(defaultPixelDensity, presentMapUnits),
  };

  return (
    <Formik initialValues={{ ...initialValues }}>
      {({ values, setFieldValue }) => {
        const ppi = get(values, 'pixelDensity', defaultPixelDensity);

        return (
          <div>
            <StyledWrapper>
              <div>
                <WidthInput
                  values={values}
                  ppi={ppi}
                  presentMapUnits={presentMapUnits}
                  setFieldValue={setFieldValue}
                  changeExportSettings={changeExportSettings}
                />
                <PixelDensitySelect
                  setFieldValue={setFieldValue}
                  changeExportSettings={changeExportSettings}
                  values={values}
                />
              </div>
              <div>
                <HeightInput
                  values={values}
                  ppi={ppi}
                  presentMapUnits={presentMapUnits}
                  setFieldValue={setFieldValue}
                  changeExportSettings={changeExportSettings}
                />
                <ImageFormatSelect changeExportSettings={changeExportSettings} />
              </div>
            </StyledWrapper>
            <ExportSettingsOptions />
          </div>
        );
      }}
    </Formik>
  );
};

ExportSettingsForm.propTypes = {
  changeExportSettings: PropTypes.func.isRequired,
  exportSettings: PropTypes.object,
  presentMapUnits: PropTypes.string,
};

const mapDispatchToProps = {
  changeExportSettings,
};

const mapStateToProps = ({ exportSettings }) => ({ ...exportSettings });

export default connect(mapStateToProps, mapDispatchToProps)(ExportSettingsForm);
