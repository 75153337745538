import React from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';

import { ButtonContainer } from './style';

const UpdateLocationButton = ({ handleSubmit, okText }) => {
  const updateLocation = () => {
    handleSubmit();
  };

  return (
    <ButtonContainer>
      <Button type="primary" onClick={updateLocation}>
        {okText}
      </Button>
    </ButtonContainer>
  );
};

UpdateLocationButton.propTypes = {
  okText: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default React.memo(UpdateLocationButton, () => true);
