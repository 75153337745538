import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useRouter } from 'utils';
import { showErrorNotification } from 'components';

import ImportLocationsModal from '.';
import { CreateImportsChannelSubscriptions } from '../ImportsChannel';
import ImportLocationsService from './ImportLocationsService';

const ImportLocationsModalContainer = ({ closeModal, ...props }) => {
  const { pageId } = useRouter();
  const dispatch = useDispatch();

  const [signedId, setSignedId] = useState();
  const { fetchImportedFileStatus } = ImportLocationsService(pageId, signedId);

  const showImportedFileStatus = async () => {
    const { id, fileName, errorMessage } = await fetchImportedFileStatus(signedId);

    if (errorMessage) {
      showErrorNotification('Import failed');
      return;
    }
    // TODO: create class for this checking
    localStorage.setItem(`${pageId}`, JSON.stringify(id));
    CreateImportsChannelSubscriptions(id, fileName, dispatch, closeModal);
  };

  const handleOnOkClick = async () => {
    await showImportedFileStatus();
    closeModal();
  };

  return (
    <ImportLocationsModal
      setSignedId={setSignedId}
      handleOnOkClick={handleOnOkClick}
      closeModal={closeModal}
      {...props}
    />
  );
};

ImportLocationsModalContainer.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default React.memo(ImportLocationsModalContainer);
