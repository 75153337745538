import React from 'react';
import PropTypes from 'prop-types';
import Input from 'antd/lib/input';

import { Label } from 'components';

import { StyledInputContainer } from '../styles';


const NameInput = ({ form, aggregateName }) => {
  const { getFieldDecorator } = form;

  return (
    <StyledInputContainer>
      <Label colon={false} label="Name">
        {getFieldDecorator('name', { initialValue: aggregateName || '' })(<Input autoFocus />)}
      </Label>
    </StyledInputContainer>
  );
};

NameInput.propTypes = {
  form: PropTypes.object.isRequired,
  aggregateName: PropTypes.string,
};

export default NameInput;
