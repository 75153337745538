import styled from 'styled-components';


export const SpaceWrapper = styled.div`
  margin-bottom: ${props => props.bottom}px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
