import React from 'react';
import PropTypes from 'prop-types';
import { showErrorNotification, showInfoNotification } from 'components';

import UploadLocationsButton from './index';
import uploadFileToS3Bucket from './S3BucketFileUploader';

const UploadLocationsButtonContainer = ({ setSignedId, enableImportButton, visible }) => {
  const uploadFile = ({ onSuccess, file }) => new Promise((async (resolve, reject) => {
    const { uploadStatus, signed_id } = await uploadFileToS3Bucket(file);
    const fileUploadedSuccessfully = uploadStatus.status === 200;

    if (fileUploadedSuccessfully) {
      resolve(onSuccess('done'));
      setSignedId(signed_id);
      showInfoNotification('File was uploaded');
      enableImportButton(true);
      return;
    }

    enableImportButton(false);
    reject();
    showErrorNotification('The file cannot be uploaded!');
  }));

  return (
    <UploadLocationsButton
      enableImportButton={enableImportButton}
      onCustomRequest={uploadFile}
      visible={visible}
    />
  );
};

UploadLocationsButtonContainer.propTypes = {
  setSignedId: PropTypes.func.isRequired,
  enableImportButton: PropTypes.func.isRequired,
};

export default UploadLocationsButtonContainer;
