import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import UseBoundsDispatch from 'components/maps/EditMapBound/UseBoundsDispatch';

const UseUpdateShapesFromMap = (addShape, removeShape) => {
  const boundaries = useSelector(store => store.mapBoundariesReducer);
  const [addBoundaries, removeBoundaries] = UseBoundsDispatch();

  const addedBoundaries = get(boundaries, 'addBoundaries', false);
  const removedBoundaries = get(boundaries, 'removeBoundaries', false);

  useEffect(() => {
    if (addedBoundaries) {
      addShape(addedBoundaries);
      addBoundaries(false);
    }

    if (removedBoundaries) {
      removeShape(removedBoundaries);
      removeBoundaries(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedBoundaries, removedBoundaries]);
};

export default UseUpdateShapesFromMap;
