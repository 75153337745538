import React from 'react';
import PropTypes from 'prop-types';
import { GithubPicker } from 'react-color';

import { ColorPickerOverlay, ColorPickerContainer } from './styles';


const Picker = ({ visible, onChangeComplete, togglePicker }) => visible && (
  <ColorPickerContainer>
    <ColorPickerOverlay onClick={togglePicker} />
    <GithubPicker onChangeComplete={onChangeComplete} />
  </ColorPickerContainer>
);

Picker.propTypes = {
  visible: PropTypes.bool.isRequired,
  onChangeComplete: PropTypes.func.isRequired,
};

export default Picker;
