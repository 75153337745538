import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const checkFileProcess = ({
  id,
  imports,
  fileName,
  errorMessage,
  percent,
}) => {
  const importedFiled = find(imports, { id });
  const importsErrorMessage = get(importedFiled, 'errorMessage', false);

  const doesImportHasErrors = isEmpty(errorMessage) && isEmpty(importsErrorMessage);
  const isFileInProcess = fileName && percent !== 100;

  return doesImportHasErrors && isFileInProcess;
};

export default checkFileProcess;
