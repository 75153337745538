import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { changeExportSettings } from 'state/exportSettings/actions';

import BackToEditButton from '.';

const BackToEditButtonContainer = ({ closePresentMap }) => {
  // TODO: fix the map reset
  const dispatchResetMapWidth = useDispatch();
  const resetMapWidth = () => dispatchResetMapWidth(changeExportSettings({
    showTitleDescription: true,
    showLegend: true,
    showInsights: true,
  }));

  return (
    <BackToEditButton
      resetMapWidth={resetMapWidth}
      closePresentMap={closePresentMap}
    />
  );
};

BackToEditButtonContainer.propTypes = {
  closePresentMap: PropTypes.func.isRequired,
};

export default React.memo(BackToEditButtonContainer);
