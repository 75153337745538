import get from 'lodash/get';

import { zoomTo, initialMapSettings } from 'components';

export const zoomToBounds = (mapStyles, setState) => {
  const boundaries = mapStyles.getIn(['sources', 'boundaries', 'data']);

  if (boundaries) {
    const fittedViewport = zoomTo(boundaries);
    const isFittedViewportValid = get(fittedViewport, 'scale', false);

    if (isFittedViewportValid) {
      setState({ viewport: { ...fittedViewport } });
    }

    return true;
  }

  return false;
};

const zoomToSinglePoint = (points, setState) => {
  if (points.get(0).getIn(['geometry', 'coordinates'])) {
    const [longitude, latitude] = points.get(0).getIn(['geometry', 'coordinates']).toArray();
    const singlePointZoom = 5;

    setState({
      viewport: {
        latitude,
        longitude,
        zoom: singlePointZoom,
      },
    });
  }
};

export const zoomToDatapoints = (mapStyles, setState, source) => {
  const datapoints = mapStyles.getIn(['sources', source, 'data']);
  const points = mapStyles.getIn(['sources', source, 'data', 'features']);

  const isPointSingle = points && points.size === 1;
  const isMapEmpty = points && points.size === 0;

  const singlePointsZoom = !isMapEmpty && isPointSingle;
  const multiPointsZoom = !isMapEmpty && !isPointSingle && datapoints;

  if (singlePointsZoom) {
    zoomToSinglePoint(points, setState);

    return true;
  }

  if (multiPointsZoom) {
    const fittedViewport = zoomTo(datapoints);
    setState({ viewport: { ...fittedViewport } });

    return true;
  }

  return false;
};

const checkDatapointsExists = (mapStyles) => {
  let isDatapointsExists;

  try {
    isDatapointsExists = mapStyles.getIn(['sources', 'datapoints-0', 'data', 'features']).size;
  } catch (e) {
    isDatapointsExists = false;
  }

  return isDatapointsExists;
};

export const defaultZoom = (mapStyles, setState) => {
  const areDatapointsExists = checkDatapointsExists(mapStyles);

  if (!areDatapointsExists) {
    const { zoom, latitude, longitude } = initialMapSettings;

    setState({
      viewport: {
        latitude,
        longitude,
        zoom,
      },
    });

    return false;
  }

  return false;
};
