import React from 'react';
import PropTypes from 'prop-types';
import Select from 'antd/lib/select';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import { Label } from 'components';

import { StyledInputContainer, StyledSelect } from './styles';

const { Option } = Select;

const wasDatasetSelected = (selectedDatasets, id) => !isEmpty(find(selectedDatasets, { id }));

const DatasetOptions = ({
  datasets,
  initialDataset,
  changeDatasetId,
  selectedDatasets,
}) => (
  <StyledInputContainer>
    <Label colon={false} label="Available datasets">
      <StyledSelect
        autoFocus
        name="datasetId"
        onChange={changeDatasetId}
        defaultValue={initialDataset}
      >
        {!isEmpty(datasets.datasets) && datasets.datasets.map(({ name, id }) => (
          <Option
            key={id}
            value={id}
            disabled={wasDatasetSelected(selectedDatasets, id)}
          >
            {name}
          </Option>
        ))}
      </StyledSelect>
    </Label>
  </StyledInputContainer>
);

DatasetOptions.propTypes = {
  datasets: PropTypes.object,
  changeDatasetId: PropTypes.func,
  initialDataset: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  selectedDatasets: PropTypes.array,
};

export default DatasetOptions;
