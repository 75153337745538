import {WebMercatorViewport} from 'react-map-gl';
import bbox from '@turf/bbox';
import isEmpty from 'lodash/isEmpty';

export const initialMapSettings = {
  latitude: -26.30,
  longitude: 130.42,
  zoom: 3.5,
};

const createWebMercator = () => {
  const { clientWidth, clientHeight } = document.body;
  const sideBarWidth = 400;
  const headerHeight = 80;

  const WebMercator = new WebMercatorViewport({
    width: clientWidth - sideBarWidth,
    height: clientHeight - headerHeight,
  });

  return WebMercator;
};

export const zoomTo = (points) => {
  const datapoints = points.toJS();

  try {
    if (!isEmpty(datapoints)) {
      const webMercator = createWebMercator();
      const [minLng, minLat, maxLng, maxLat] = bbox(datapoints);

      const bounds = [[minLng, minLat], [maxLng, maxLat]];

      const { width, height, ...fittedViewport } = webMercator.fitBounds(bounds);

      return fittedViewport;
    }
  } catch (e) {
    return [];
  }

  return [];
};
