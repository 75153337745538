import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'antd/lib/skeleton';

import { EditDatasetPageDescription, EditDatasetPageTitle } from 'components';
import { StyledEditDatasetPageInfo, StyledSkeletonContainer } from './style';


const EditDatasetPageInfo = ({ name, description }) => (
  <StyledEditDatasetPageInfo>
    {name ? (
      <Fragment>
        <EditDatasetPageTitle title={name} />
        <EditDatasetPageDescription name={name} description={description} />
      </Fragment>
    ) : (
      <StyledSkeletonContainer>
        <Skeleton active paragraph={{ rows: 1 }} />
      </StyledSkeletonContainer>
    )}
  </StyledEditDatasetPageInfo>
);

EditDatasetPageInfo.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
};

EditDatasetPageInfo.defaultProps = {
  name: '',
  description: '',
};

export default EditDatasetPageInfo;
