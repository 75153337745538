import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { sessionInfoQuery } from 'graphql/queries';

import UserName from './UserName';
import UserDropdown from './UserDropdown';
import UserAvatar from './UserAvatar';

import { StyledUser } from './style';

const handleSessionError = (error) => {
  const redirectPath = get(error, 'networkError.result.redirectPath', false);

  if (redirectPath) {
    window.location.replace(redirectPath);
  }
};

const User = () => {
  const { data, error } = useQuery(sessionInfoQuery);

  handleSessionError(error);

  if (isEmpty(data)) {
    return null;
  }

  const { avatar, fullName } = get(data, 'sessionInfo.currentAccount', null);
  const { coreUrl, signOutUrl } = get(data, 'sessionInfo', null);

  return (
    <StyledUser>
      <UserName name={fullName} />
      <UserAvatar avatar={avatar} />
      <UserDropdown coreUrl={coreUrl} signOutUrl={signOutUrl} />
    </StyledUser>
  );
};

export default User;
