import get from 'lodash/get';

export const getDataFromProps = (props) => {
  const { basemap, shapeBorderColor } = props;
  const mapDatasets = get(props, 'mapDatasets', false);
  const mapShapes = get(props, 'mapShapes', []);
  const color = get(mapDatasets, '[0].color', 'blue');

  return {
    basemap,
    shapeBorderColor,
    mapDatasets,
    mapShapes,
    color,
  };
};
