import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';

import { CardsView, Loader } from 'components';

const getCardsData = (dataQuery) => {
  const maps = get(dataQuery, 'maps', []);
  const datasets = get(dataQuery, 'datasets', []);

  return [...maps, ...datasets];
};

const CardsContainer = ({ query }) => {
  const { error, loading, data: dataQuery } = useQuery(query);

  const cards = getCardsData(dataQuery);

  return (
    <Loader loading={loading} error={error}>
      <CardsView cards={cards} />
    </Loader>
  );
};

CardsContainer.propTypes = {
  query: PropTypes.object.isRequired,
};


export default CardsContainer;
