import { useMutation } from '@apollo/react-hooks';

import { aggregateDestroy, fetchMap } from 'graphql/queries';

const createDestroyVariables = id => ({
  variables: {
    input: {
      id,
    },
  },
});

const CardInsightsService = (pageId) => {
  const [runMutation, aggregatesList] = useMutation(aggregateDestroy, {
    refetchQueries: () => [{
      query: fetchMap,
      variables: ({ id: pageId }),
    }],
  });

  const destroyInsight = id => runMutation(createDestroyVariables(id));

  return ({ destroyInsight, aggregatesList });
};

export default CardInsightsService;
