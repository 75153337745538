import React from 'react';
import PropTypes from 'prop-types';

import { DeleteIcon } from './style';

const DeleteItemButton = ({ arrayHelpers, index }) => {
  const handleClick = () => arrayHelpers.remove(index);

  return (
    <DeleteIcon type="minus-circle-o" onClick={handleClick} />
  );
};

DeleteItemButton.propTypes = {
  arrayHelpers: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default DeleteItemButton;
