import React from 'react';
import PropTypes from 'prop-types';
import Select from 'antd/lib/select';

import { Label } from 'components';

import { StyledInputContainer } from '../styles';

const { Option } = Select;

const DatasetSelect = ({ getFieldDecorator, aggregatesNames, datasets }) => (
  <StyledInputContainer>
    <Label colon={false} label="Filter by">
      {getFieldDecorator('mapDatasetIds', { initialValue: aggregatesNames })(
        <Select mode="multiple" placeholder="Make a selection">
          {datasets.map(({ id, name }) => (<Option key={id} value={id}>{name}</Option>))}
        </Select>,
      )}
    </Label>
  </StyledInputContainer>
);

DatasetSelect.propTypes = {
  aggregatesNames: PropTypes.array,
  datasets: PropTypes.array.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
};

export default DatasetSelect;
