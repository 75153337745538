import styled from 'styled-components';

export const StyledDescription = styled.div`
  font-size: 20px;
  display: flex;
  align-items: baseline;
  
  .ant-typography-edit-content {
    left: 0;
    margin-top: 0;
  }
  
  textarea {
    font-size: 20px;
    border: none;
    outline: none;
  }
  
  .ant-input {
    border: none;
    outline: none;
    padding: 0;
    background-color: transparent;
  }
`;

export const DescriptionTip = styled.div`
  font-size: 17px;
`;
