import React from 'react';

import PopupEditButton from './PopupEditButton';
import PopupRemoveButton from './PopupRemoveButton';

import { PopupEditButtonContainer } from './style';

const PopupEditButtons = () => (
  <PopupEditButtonContainer>
    <PopupEditButton />
    <PopupRemoveButton />
  </PopupEditButtonContainer>
);

export default PopupEditButtons;
