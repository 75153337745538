import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'antd/lib/modal';
import get from 'lodash/get';

import { MapPreview } from 'components';
import { useModal } from 'utils';

import { createCapture, downloadCapturedImage } from './PreviewImage';
import { StyledButton } from './styles';

const previewImageModalWidth = 800;

const checkDimensions = (props) => {
  const mapWidth = Math.floor(get(props, 'presentMapWidth', 0));
  const mapHeight = Math.floor(get(props, 'presentMapHeight', 0));

  // TODO: magic numbers
  return (mapWidth > 8000 || mapHeight > 8000);
};

const PreviewImageModal = ({
  mapName,
  imageFormat,
  pixelDensity,
  ...props
}) => {
  const { openModal, closeModal, visible } = useModal();

  const openPreviewImageModal = () => {
    createCapture(pixelDensity, props, mapName, imageFormat);
    openModal();
  };

  const handleDownloadCapturedImage = () => {
    downloadCapturedImage();
    closeModal();
  };

  const shouldDisablePreviewButton = checkDimensions(props);

  return (
    <Fragment>
      <StyledButton
        type="primary"
        disabled={shouldDisablePreviewButton}
        onClick={openPreviewImageModal}
      >
        Preview
      </StyledButton>
      <Modal
        data-html2canvas-ignore
        title="Preview"
        width={previewImageModalWidth}
        visible={visible}
        onCancel={closeModal}
        okText="Download image"
        onOk={handleDownloadCapturedImage}
      >
        <MapPreview />
      </Modal>
    </Fragment>
  );
};

PreviewImageModal.propTypes = {
  mapName: PropTypes.string.isRequired,
  imageFormat: PropTypes.string,
  pixelDensity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

const mapStateToProps = ({ exportSettings }) => ({ ...exportSettings });

export default connect(mapStateToProps, null)(React.memo(PreviewImageModal));
