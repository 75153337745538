import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledRadio,
  StyledRadioImage,
  StyledRadioContainer,
  StyledThumbnailTitle,
  StyledDescription,
} from './styles';

const VisualisationThumbnail = ({
  text,
  title,
  type,
  disabled,
  imageSrc,
  selected,
}) => (
  <div>
    <StyledRadioContainer>
      <StyledRadio visualisationType={type} selected={selected} disabled={disabled} />
      <div>
        <StyledRadioImage src={imageSrc} srcSet={`${imageSrc} 2x`} disabled={disabled} />
      </div>
    </StyledRadioContainer>
    <StyledDescription>
      <StyledThumbnailTitle>{title}</StyledThumbnailTitle>
      <p>{text}</p>
      {disabled && <i>Coming soon</i>}
    </StyledDescription>
  </div>
);

VisualisationThumbnail.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  imageSrc: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default VisualisationThumbnail;
