import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';


const CreateView = ({ buttonText, openModal }) => (
  <Fragment>
    <Button
      id="open-main-page-create-modal"
      type="primary"
      size="large"
      onClick={openModal}
    >
      {buttonText}
    </Button>
    <br />
    <br />
  </Fragment>
);

CreateView.propTypes = {
  buttonText: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default React.memo(CreateView, () => true);
