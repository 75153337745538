import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

import { useRouter } from 'utils';

import EditLocationService, { createEditLocationServiceVariables } from './service';
import EditLocationForm from '.';

const parseStatisticsData = (statisticsData) => {
  let statistics = statisticsData;
  if (typeof statisticsData === 'string') {
    statistics = JSON.parse(statisticsData);
  }

  return statistics;
};

let statistics = [];
const useFormValues = ({ address, long, lat }, closeModal) => {
  const { pageId } = useRouter();
  const { updateLocation } = EditLocationService(pageId, closeModal);

  const [currentAddress, getAddress] = useState(address);
  const [locations, getLocations] = useState({ longitude: long, latitude: lat });
  const getStatistics = (data) => {
    statistics = data;
  };

  const editLocation = async ({ name, description, ...props }) => {
    const { id } = props;
    const datasetId = Number(pageId);

    // TODO: REFACTOR THIS LOGIC!
    let statisticsValues;
    if (statistics.statistics.length >= props.statistics.length) {
      statisticsValues = statistics.statistics;
    } else {
      const statistics = parseStatisticsData(props.statistics);
      statisticsValues = statistics.map((item) => {
        const sid = get(item, 'id', 0);
        if (!find(statistics.statistics, { id: sid })) {
          item.destroy = true;
        }

        return item;
      });
    }

    updateLocation({
      ...createEditLocationServiceVariables({
        ...props,
        name,
        description,
        datasetId,
        address: currentAddress,
        locations: isEmpty(locations) ? { longitude: long, latitude: lat } : locations,
        id,
        statistics: statisticsValues,
      }),
    });
    return null;
  };

  return ({
    getAddress,
    getLocations,
    getStatistics,
    editLocation,
  });
};

const EditLocation = ({
  okText,
  closeModal,
  notFoundDatapoint,
}) => {
  const { selectedDataPoint } = useSelector(({ selectPointEditMap }) => selectPointEditMap);

  const {
    getAddress,
    getLocations,
    getStatistics,
    editLocation,
  } = useFormValues(selectedDataPoint, closeModal);

  return (
    <EditLocationForm
      notFoundDatapoint={notFoundDatapoint}
      getAddress={getAddress}
      getLocations={getLocations}
      okText={okText}
      selectedDataPoint={selectedDataPoint}
      editLocation={editLocation}
      getStatistics={getStatistics}
    />
  );
};

EditLocation.propTypes = {
  okText: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  notFoundDatapoint: PropTypes.object,
};

export default EditLocation;
