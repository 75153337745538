import React from 'react';
import Skeleton from 'antd/lib/skeleton';

const SideBarSkeleton = () => (
  <>
    <Skeleton active paragraph={{ rows: 1 }} />
    <Skeleton active paragraph={{ rows: 1 }} />
    <br />
    <br />
    <Skeleton active paragraph={{ rows: 2 }} />
    <br />
    <br />
    <Skeleton active paragraph={{ rows: 2 }} />
  </>
);

export default SideBarSkeleton;
