import { useMutation } from '@apollo/react-hooks';

import { useRouter } from 'utils';
import { aggregateUpdate, fetchMap } from 'graphql/queries';

import { createMutationVariables } from '../helpers';


const AggregateDataUpdateService = (insightId) => {
  const { pageId } = useRouter();
  const [updateAggregate, { data }] = useMutation(aggregateUpdate);

  const updateInsight = (formValues) => {
    const aggregatesVariables = createMutationVariables({ formValues, pageId });

    updateAggregate({
      variables: {
        input: {
          id: insightId,
          ...aggregatesVariables,
        },
      },
      refetchQueries: [{
        query: fetchMap,
        variables: ({ id: pageId }),
      }],
    });
  };

  return ({ updateInsight, data });
};

export default AggregateDataUpdateService;
