import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'react-map-gl';
import isEmpty from 'lodash/isEmpty';

import { MAP_DEFAULT_COLOR } from 'utils/constants';

import PopupInfo from './PopupInfo';
import LocationInfo from './LocationInfo';
import PopupEditButtons from './PopupEditButtons';

import { PopupContainer } from './style';

const getPopupInfoColor = (color) => {
  if (typeof color === 'string') {
    return color;
  }

  return MAP_DEFAULT_COLOR;
};

const EditMapPopup = ({ selectedDataPoint, showEditButtons, color }) => {
  const {
    lat,
    long,
    name,
    address,
    statistics,
  } = selectedDataPoint;

  const popupInfoColor = getPopupInfoColor(color);

  return (
    <PopupContainer>
      <Popup closeButton={false} latitude={lat} longitude={long}>
        <PopupInfo name={name} address={address} color={popupInfoColor} />
        {!isEmpty(statistics) && <LocationInfo statistics={statistics} />}
        {showEditButtons && <PopupEditButtons />}
      </Popup>
    </PopupContainer>
  );
};

EditMapPopup.propTypes = {
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  selectedDataPoint: PropTypes.object,
  showEditButtons: PropTypes.bool,
};

export default EditMapPopup;
