import { useMutation } from '@apollo/react-hooks';

import { showErrorMessage } from 'utils';
import { showInfoNotification } from 'components/shared/Notification';


const EditPageDescription = {
  createVariables: (pageId, text, name) => ({
    variables: {
      input: {
        id: pageId,
        name,
        description: text,
      },
    },
  }),
  UpdateDescriptionMutation: (query) => {
    const [updateDescription] = useMutation(query, {
      onCompleted: () => showInfoNotification('Description was updated successfully!'),
      onError: showErrorMessage,
    });

    return updateDescription;
  },
};

export default EditPageDescription;
