import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton } from './styles';


const ExportData = ({ downloadFileLink }) => (
  <StyledButton href={downloadFileLink}>
    Export Data
  </StyledButton>
);

ExportData.propTypes = {
  downloadFileLink: PropTypes.string.isRequired,
};

export default ExportData;
