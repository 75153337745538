import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/lib/modal';

import { OpenLocationsModal, UploadLocationsButton } from 'components';

const ImportLocationsModal = ({
  visible,
  closeModal,
  openModal,
  handleOnOkClick,
  setSignedId,
}) => {
  const [isImportButtonEnabled, enableImportButton] = useState(false);

  return (
    <Fragment>
      <Modal
        title="Import Dataset"
        okText="Import"
        visible={visible}
        onCancel={closeModal}
        onOk={handleOnOkClick}
        okButtonProps={{ disabled: !isImportButtonEnabled }}
      >
      <p>
        You can import data by providing a CSV file containing the name and location of each datapoint. Either addresses or geographic coordinates may be used, so long as your file conforms to one of the the below formats.
      </p>
      <div>
        For importing with addresses you must have columns titled:

        <ul>
          <li>Name</li>
          <li>Address</li>
        </ul>

        For geographic coordinates you must have columns titled:

        <ul>
          <li>Name</li>
          <li>Latitude</li>
          <li>Longitude</li>
        </ul>
      </div>
        <UploadLocationsButton
          closeModal={closeModal}
          setSignedId={setSignedId}
          enableImportButton={enableImportButton}
          visible={visible}
        />
      </Modal>
      <OpenLocationsModal onClick={openModal} />
    </Fragment>
  );
};

ImportLocationsModal.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  pageId: PropTypes.string,
  handleOnOkClick: PropTypes.func,
  setSignedId: PropTypes.func,
  getImportLocationProgressStatus: PropTypes.func,
};

export default ImportLocationsModal;
