import React from 'react';
import PropTypes from 'prop-types';

import { mapUpdate } from 'graphql/queries';
import { EditableDescription, EditPageDescription } from 'components';
import { useRouter } from 'utils';


const EditMapPageDescription = (props) => {
  const { pageId } = useRouter();

  const { UpdateDescriptionMutation, createVariables } = EditPageDescription;
  const updateDescription = UpdateDescriptionMutation(mapUpdate);

  return (
    <EditableDescription
      pageId={pageId}
      createVariables={createVariables}
      updateDescription={updateDescription}
      {...props}
    />
  );
};

EditMapPageDescription.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default React.memo(EditMapPageDescription);
