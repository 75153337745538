import { VISUALISATION_TYPES } from 'utils/constants/visualisationTypes';

import location from './1x/location.png';
import heatmap from './1x/heatmap.png';
import choropleth from './1x/choropleth.png';

import location2x from './2x/location@2x.png';
import heatmap2x from './2x/heatmap@2x.png';
import choropleth2x from './2x/choropleth@2x.png';


const { LOCATION, HEATMAP, CHOROPLETH } = VISUALISATION_TYPES;

export const thumbnails = [
  {
    id: 1,
    title: 'Locations',
    text: 'Show each location individually with relevant boundaries.',
    imageSrc: location,
    imageSrc2x: location2x,
    disabled: false,
    type: LOCATION,
  },
  {
    id: 2,
    title: 'Heatmap',
    text: 'Visualise location density with a colour gradient.',
    imageSrc: heatmap,
    imageSrc2x: heatmap2x,
    disabled: false,
    type: HEATMAP,
  },
  {
    id: 3,
    title: 'Choropleth',
    text: 'Compare insights across boundaries by proportionally shading areas as a whole.',
    imageSrc: choropleth,
    imageSrc2x: choropleth2x,
    disabled: true,
    type: CHOROPLETH,
  },
];
