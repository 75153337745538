export const gradientReducer = (state, { type, value }) => {
  switch (type) {
    case 'changeLowestColor':
      return { ...state, lowestValue: value };
    case 'changeMidpointColor':
      return { ...state, midpointValue: value };
    case 'changeHighestColor':
      return { ...state, highestValue: value };
    default:
      throw new Error('Unexpected action');
  }
};
