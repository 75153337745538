import React from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/react-hooks';
import { withFormik } from 'formik';
import find from 'lodash/find';
import get from 'lodash/get';

import { MapDatasetsForm } from 'components';
import { useRouter } from 'utils';
import { MAP_DEFAULT_COLOR } from 'utils/constants';

import UpdateMapDatasetService from './service';

const GetMapDatasetFromCache = (id) => {
  const client = useApolloClient();
  const cacheData = get(client, 'cache.data.data', null);

  return get(cacheData, `MapDataset:${id}`, { name: '', clustered: false, color: MAP_DEFAULT_COLOR });
};

const UpdateMapDatasets = (props) => {
  const { id, datasets } = props;
  const { pageId } = useRouter();
  const {
    name,
    displayName,
    clustered,
    color,
  } = GetMapDatasetFromCache(id);

  const { updateMapDataset } = UpdateMapDatasetService(pageId);

  const UpdateMapDatasetsForm = withFormik({
    mapPropsToValues: () => {
      const datasetId = get(props, 'dataset.id', '');
      const selectedDataset = find(datasets, item => item.id === datasetId);
      const mapDatasetName = displayName || name;

      return ({
        displayName: mapDatasetName,
        color,
        datasetId,
        initialDatasetsName: selectedDataset.name,
        clustered,
      });
    },
  })(MapDatasetsForm);

  return <UpdateMapDatasetsForm pageId={pageId} type="update" updateMapDataset={updateMapDataset} {...props} />;
};

UpdateMapDatasets.propTypes = {
  id: PropTypes.string,
};

export default UpdateMapDatasets;
