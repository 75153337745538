import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { debouncedFetch } from 'utils';
import { geocoderApi } from 'utils/constants';

import AddressSearch from '.';

const fetchGeocodeByAddress = async (address) => {
  const fetchAddressGeocodeURL = `${geocoderApi}&q=${address}`;

  const response = await fetch(fetchAddressGeocodeURL);
  const fetchedGeocode = await response.json();
  const addressGeocode = get(fetchedGeocode, 'items[0].position', false);

  if (addressGeocode) {
    const { lng, lat } = addressGeocode;
    return ({ longitude: lng, latitude: lat });
  }

  return false;
};

const getAddresses = async (address) => {
  const searchAddressLink = `${geocoderApi}&q=${address}`;
  const searchResponseData = await debouncedFetch(searchAddressLink);

  return get(searchResponseData, 'items', []);
};


const AddressSearchContainer = ({ getAddress, getLocations, selectedDataPoint }) => {
  const initialAddress = selectedDataPoint.address;
  const [addressInputValue, updateAddressInput] = useState(initialAddress);

  const [autoCompleteAddresses, changeAutoCompleteAddresses] = useState([]);
  const [addressCoordinates, changeAddressCoordinates] = useState({});

  const searchAddress = async (inputValue) => {
    updateAddressInput(inputValue);
    getAddress(inputValue);

    if (inputValue) {
      const addressesList = await getAddresses(inputValue);
      const addresses = addressesList.map(item => item.address.Label);
      changeAutoCompleteAddresses(addresses);
    }

    // clear data for the last debounced request
    if (inputValue && inputValue.length <= 1) {
      changeAutoCompleteAddresses([]);
    }
  };

  const updatePreviewMap = async (addressValue) => {
    const coordinates = await fetchGeocodeByAddress(addressValue);

    changeAddressCoordinates(coordinates);
  };

  return (
    <AddressSearch
      addressInputValue={addressInputValue}
      autoCompleteAddresses={autoCompleteAddresses}
      selectedDataPoint={selectedDataPoint}
      addressCoordinates={addressCoordinates}
      searchAddress={searchAddress}
      updatePreviewMap={updatePreviewMap}
      updateAddressInput={updateAddressInput}
      getAddress={getAddress}
      getLocations={getLocations}
    />
  );
};

AddressSearchContainer.propTypes = {
  getAddress: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  selectedDataPoint: PropTypes.object,
};

AddressSearchContainer.defaultProps = {
  selectedDataPoint: {
    longitude: 0,
    latitude: 0,
  },
};

export default AddressSearchContainer;
