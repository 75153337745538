import { createStore, compose, combineReducers } from 'redux';

import presentMap from './presentMap';
import spinnerReducer from './spinner';
import datapointReducer from './datapoint';
import boundsSelection from './selectBounds';
import exportSettings from './exportSettings';
import contextMenuReducer from './contextMenu';
import heatmapSettings from './heatmapSettings';
import selectPointEditMap from './pointEditMap';
import mapBoundariesReducer from './mapBoundaries';
import importLocationsProgressStatus from './progressImportLocations';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  presentMap,
  spinnerReducer,
  selectPointEditMap,
  exportSettings,
  boundsSelection,
  heatmapSettings,
  datapointReducer,
  contextMenuReducer,
  mapBoundariesReducer,
  importLocationsProgressStatus,
});

const store = createStore(rootReducer, composeEnhancer());

export default store;
