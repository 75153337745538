import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';

import { useRouter } from 'utils';
import { MapStyleFormContext } from 'contexts/MapStyleFormContext';
import { VISUALISATION_TYPES } from 'utils/constants/visualisationTypes';

import { BaseMap, ShapeColor, VisualisationType } from './FormItems';
import HeatmapOptions from './HeatmapOptions';

import { MapCustomizationFormService, createMutationVariables } from './service';
import { SpaceWrapper, ButtonContainer } from './styles';


const { HEATMAP } = VISUALISATION_TYPES;

const MapCustomizationForm = ({ closeModal }) => {
  const { pageId } = useRouter();
  const MapStyleFormData = useContext(MapStyleFormContext);

  const {
    basemap,
    shapeBorderColor,
    visualizationType,
    aggregatableFields,
    heatmapWeightField,
    heatmapGradient,
  } = MapStyleFormData;

  const [gradient, changeGradient] = useState(heatmapGradient);
  const [showMidpoint, toggleMidpoint] = useState(Boolean(gradient.midpointValue));
  const [stateBasemap, onChangeBasemap] = useState(basemap);
  const [pickedColor, pickColor] = useState(shapeBorderColor);
  const [weightIntensity, changeWeightIntensity] = useState(heatmapWeightField);
  const [stateVisualizationType, onChangeVisualizationType] = useState(visualizationType);

  const { updateMap } = MapCustomizationFormService(pageId);

  const handleUpdateMapStyles = () => {
    const variables = createMutationVariables({
      pageId,
      gradient,
      pickedColor,
      showMidpoint,
      stateBasemap,
      weightIntensity,
      stateVisualizationType,
    });
    updateMap(variables);

    closeModal();
  };

  return (
    <>
      <SpaceWrapper bottom={15}>
        <BaseMap
          basemap={basemap}
          onChangeBasemap={onChangeBasemap}
        />
      </SpaceWrapper>
      <SpaceWrapper bottom={30}>
        <VisualisationType
          visualizationType={visualizationType}
          onChangeVisualizationType={onChangeVisualizationType}
        />
      </SpaceWrapper>
      <h3>Options</h3>
      {stateVisualizationType === HEATMAP && (
        <HeatmapOptions
          gradient={gradient}
          showMidpoint={showMidpoint}
          changeGradient={changeGradient}
          toggleMidpoint={toggleMidpoint}
          heatmapWeightField={heatmapWeightField}
          aggregatableFields={aggregatableFields}
          changeWeightIntensity={changeWeightIntensity}
        />
      )}
      <SpaceWrapper bottom={30}>
        <ShapeColor initialColor={pickedColor} pickColor={pickColor} />
      </SpaceWrapper>
      <ButtonContainer>
        <Button type="primary" onClick={handleUpdateMapStyles}>Update map</Button>
      </ButtonContainer>
    </>
  );
};

MapCustomizationForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default React.memo(MapCustomizationForm);
