import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';

import HeatmapColorPicker from './HeatmapColorPicker';
import { gradientReducer } from './gradientReducer';

import { StyledSettingsContainer } from './styles';


const HeatmapSettings = ({ gradient, showMidpoint, changeGradient }) => {
  const [gradientColors, dispatch] = useReducer(gradientReducer, gradient);

  const { lowestValue, midpointValue, highestValue } = gradientColors;

  useEffect(() => {
    changeGradient({ lowestValue, midpointValue, highestValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lowestValue, midpointValue, highestValue]);

  return (
    <StyledSettingsContainer>
      <HeatmapColorPicker
        title="Lowest Value"
        color={lowestValue}
        pickColor={value => dispatch({ type: 'changeLowestColor', value })}
      />
      {showMidpoint && (
        <HeatmapColorPicker
          title="Midpoint Value"
          color={midpointValue}
          pickColor={value => dispatch({ type: 'changeMidpointColor', value })}
        />
      )}
      <HeatmapColorPicker
        title="Highest Value"
        color={highestValue}
        pickColor={value => dispatch({ type: 'changeHighestColor', value })}
      />
    </StyledSettingsContainer>
  );
};

HeatmapSettings.propTypes = {
  gradient: PropTypes.shape({
    lowestValue: PropTypes.string,
    midpointValue: PropTypes.string,
    highestValue: PropTypes.string,
  }).isRequired,
  showMidpoint: PropTypes.bool.isRequired,
  changeGradient: PropTypes.func.isRequired,
};

export default React.memo(HeatmapSettings);
