import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MapGL from 'react-map-gl';

import { accessToken, MapGLStyleLink } from 'utils/constants';

import Navigation from './Navigation';
import PreviewMapMarker from './PreviewMapMarker';

import { PreviewMapContainer } from './style';

const defaultZoom = 14;

class PreviewMap extends Component {
  constructor(props) {
    super(props);

    const { coordinates } = props;

    this.state = {
      viewport: {
        zoom: defaultZoom,
        ...coordinates,
      },
      marker: { ...coordinates },
    };
  }

  static getDerivedStateFromProps({ locations, marker }) {
    return {
      viewport: { zoom: defaultZoom, ...locations },
      marker: { ...marker },
    };
  }

  render() {
    const { locations, onMarkerChange, updatePreviewMap } = this.props;
    const { viewport, marker } = this.state;
    const isPreviewMapExist = locations.latitude;

    return isPreviewMapExist ? (
      <PreviewMapContainer>
        <MapGL
          width="100%"
          height="400px"
          scrollZoom={false}
          mapStyle={MapGLStyleLink}
          onViewportChange={updatePreviewMap}
          mapboxApiAccessToken={accessToken}
          {...viewport}
        >
          <PreviewMapMarker onMarkerChange={onMarkerChange} marker={marker} />
          <Navigation />
        </MapGL>
      </PreviewMapContainer>
    ) : null;
  }
}

PreviewMap.propTypes = {
  locations: PropTypes.object,
  marker: PropTypes.object,
  onMarkerChange: PropTypes.func.isRequired,
  updatePreviewMap: PropTypes.func.isRequired,
};

PreviewMap.defaultProps = {
  locations: {
    longitude: 0,
    latitude: 0,
  },
  marker: {
    longitude: 0,
    latitude: 0,
  },
};

export default React.memo(PreviewMap);
