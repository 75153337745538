import { useState } from 'react';

const useModal = () => {
  const [visible, changeVisibilityTo] = useState(false);

  const openModal = () => changeVisibilityTo(true);
  const closeModal = () => changeVisibilityTo(false);

  return ({
    openModal,
    closeModal,
    visible,
  });
};

export default useModal;
