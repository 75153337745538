import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { EditLocation } from 'components';
import Modal from 'antd/lib/modal';


const EditLocationModal = (modalProps) => {
  const { visible, closeModal } = modalProps;
  const { selectedDataPoint } = useSelector(({ selectPointEditMap }) => selectPointEditMap);

  return (
    <Modal
      destroyOnClose
      title="Location"
      visible={visible}
      onCancel={closeModal}
      width={700}
      footer={null}
    >
      {!isEmpty(selectedDataPoint) && <EditLocation okText="Update Datapoint" {...modalProps} />}
    </Modal>
  );
};

EditLocationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default React.memo(EditLocationModal);
