import { SELECT_BOUND } from './types';

const boundsSelection = (state = {}, { type, selectedBound }) => {
  switch (type) {
    case SELECT_BOUND:
      return { ...state, selectedBound };
    default:
      return state;
  }
};

export default boundsSelection;
