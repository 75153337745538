import React from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';

import { EditLocationModal } from 'components';
import { useModal } from 'utils';

import NotFoundDataPointService from './NotFoundDataPointService';
import { StyledButtonsContainer } from '../style';

const NotFoundDataPointModal = ({
  id,
  pageId,
  notFoundDatapoint,
  selectPointEditMap,
}) => {
  const { destroyDatapoint } = NotFoundDataPointService(id, pageId);
  const { openModal, ...modalProps } = useModal();

  // fill the data point modal with initial values
  const openEditDatapointModal = () => {
    openModal();

    // remove __typename for valid format
    // TODO: immutable
    notFoundDatapoint.statistics.forEach((item) => {
      delete item.__typename;
    });

    selectPointEditMap({ ...notFoundDatapoint, id, showPopup: false });
  };

  return (
    <StyledButtonsContainer>
      <EditLocationModal {...modalProps} />
      <Button type="link" onClick={openEditDatapointModal}>Edit</Button>
      <Button type="link" onClick={destroyDatapoint}>Remove</Button>
    </StyledButtonsContainer>
  );
};

NotFoundDataPointModal.propTypes = {
  id: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  notFoundDatapoint: PropTypes.object.isRequired,
  selectPointEditMap: PropTypes.func.isRequired,
};

export default NotFoundDataPointModal;
