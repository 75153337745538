import React, { useEffect, useState } from 'react';

import { EditMap, setEditMapData } from 'components';

import { createMapStyle, getDataFromProps, fetchBoundariesShapes } from './utils';

const MapContainer = (props) => {
  const { visualizationType } = props;
  const [mapData, setMapData] = useState(null);

  const {
    basemap,
    shapeBorderColor,
    mapDatasets,
    mapShapes,
    color,
  } = getDataFromProps(props);

  const mapShapesUrls = mapShapes.map(({ shape }) => shape.geojsonUrl);

  useEffect(() => {
    (async () => {
      const boundariesShapes = await fetchBoundariesShapes(mapShapesUrls);
      const map = await createMapStyle(props);

      const mapStyles = await setEditMapData(map, [mapDatasets, boundariesShapes], {
        lineColor: shapeBorderColor,
        visualizationType,
      });

      setMapData(mapStyles);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapShapes, mapDatasets, basemap, shapeBorderColor, visualizationType]);

  return mapData ? (
    <EditMap
      mapStyles={mapData}
      color={color}
      {...props}
    />
  ) : null;
};

export default MapContainer;
