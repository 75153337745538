import { idealTextColor } from 'utils';

export const clustersLayer = (color, index, source) => ({
  id: `clusters${color}${index}`,
  type: 'circle',
  filter: ['has', 'point_count'],
  source,
  paint: {
    'circle-color': [
      'step',
      ['get', 'point_count'],
      color,
      100,
      color,
      750,
      color,
    ],
    'circle-opacity': 0.8,
    'circle-radius': [
      'step',
      ['get', 'point_count'],
      20,
      100,
      30,
      750,
      40,
    ],
  },
});

export const clustersCountLayer = (color, index, source) => ({
  id: `cluster-count${color}${index}`,
  type: 'symbol',
  source,
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-size': 12,
  },
  paint: {
    'text-color': idealTextColor(color),
  },
});
