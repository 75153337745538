import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'antd/lib/typography';

import { Description, SubTtile } from './style';

const { Title } = Typography;

const PageDescription = ({ title, subtitle }) => (
  <Description>
    <Title>{title}</Title>
    <SubTtile>{subtitle}</SubTtile>
  </Description>
);

PageDescription.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default React.memo(PageDescription);
