import React from 'react';
import PropTypes from 'prop-types';
import List from 'antd/lib/list';

import DatasetItem from './DatasetItem/container';

const MapDataset = ({ mapDatasets, datasets }) => (
  <List
    bordered
    dataSource={mapDatasets}
    renderItem={item => <DatasetItem datasets={datasets} {...item} />}
  />
);

MapDataset.propTypes = {
  mapDatasets: PropTypes.array,
  datasets: PropTypes.array,
};

MapDataset.defaultProps = {
  mapDatasets: [],
};

export default MapDataset;
