import { gql } from 'apollo-boost';

export const fetchDatasets = gql`
    {
        datasets {
            id
            name
            description
            datapoints {
                totalCount
            }
        }
    }
`;

export const fetchDataset = gql`
    query Dataset($id: ID!) {
        dataset(id: $id) {
            id
            name
            description
            statisticsFields
            datapointsGeojson
            datapoints {
                totalCount
            }
            imports {
                id
                fileName
                percentComplete
                errorMessage
                notFoundDatapoints(first: 100) {
                    totalCount
                    nodes {
                        id
                        name
                        address
                        geocodeStatus
                        lonlat
                        statistics {
                            id
                            name
                            value
                        }
                    }
                }
            }
        }
    }
`;

export const fetchDataponts = gql`
    query Dataset($id: ID!) {
        dataset(id: $id) {
            id
            imports {
                id
                fileName
                percentComplete
                notFoundDatapoints(first: 100) {
                    nodes {
                        id
                        name
                        address
                    }
                }
            }
        }
    }
`;
