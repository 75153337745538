import isEmpty from 'lodash/isEmpty';

const MAX_ZOOM_LEVEL = 9;
const GRADIENT_RANGE = [0.2, 0.6, 1];

export const createGradient = (gradientColors, range) => {
  if (isEmpty(gradientColors)) {
    return [];
  }

  const { lowestValue, midpointValue, highestValue } = gradientColors;
  const [lowest, midpoint, highest] = range;

  if (midpointValue) {
    return [lowest, lowestValue, midpoint, midpointValue, highest, highestValue];
  }

  return [lowest, lowestValue, highest, highestValue];
};

export const heatmapLayer = (gradientColors, heatmapWeightField = '') => ({
  maxzoom: MAX_ZOOM_LEVEL,
  type: 'heatmap',
  paint: {
    'heatmap-weight': ['interpolate', ['linear'], ['get', heatmapWeightField], 0, 0, 6, 1],
    'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, MAX_ZOOM_LEVEL, 3],
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0, 'rgba(255,255,255,0)',
      ...createGradient(gradientColors, GRADIENT_RANGE),
    ],
    'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, MAX_ZOOM_LEVEL, 20],
    'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, MAX_ZOOM_LEVEL, 0],
  },
});
