import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Progress from 'antd/lib/progress';

import ProgressFileName from './ProgressFileName';

const ImportLocationsProgressBar = ({
  fileName,
  percent,
  status,
  strokeColor,
}) => (
  <Fragment>
    <ProgressFileName fileName={fileName} />
    <Progress strokeColor={strokeColor} percent={percent} status={status} showInfo={false} />
  </Fragment>
);

ImportLocationsProgressBar.propTypes = {
  fileName: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  status: PropTypes.string,
  strokeColor: PropTypes.string,
};

export default ImportLocationsProgressBar;
