import React from 'react';
import PropTypes from 'prop-types';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';

import { Label } from 'components';

import { SubmitButtonWrapper } from './style';

const CreateMapForm = (props) => {
  const {
    getFormValues,
    form: { getFieldsValue, getFieldDecorator },
    createEditPage,
    type,
  } = props;

  const ObservableInput = (name, autoFocus) => getFieldDecorator(name)(<Input autoFocus={autoFocus} size="large" />);

  const formValues = getFieldsValue(['name', 'description']);
  getFormValues(formValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    createEditPage();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Label colon={false} label="Name">
        {ObservableInput('name', true)}
      </Label>
      <Label colon={false} label="Description">
        {ObservableInput('description')}
      </Label>
      <SubmitButtonWrapper>
        <Button
          id={`create-${type.toLowerCase()}`}
          type="primary"
          htmlType="submit"
        >
          {`Create ${type}`}
        </Button>
      </SubmitButtonWrapper>
    </Form>
  );
};

CreateMapForm.propTypes = {
  getFormValues: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  createEditPage: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

const MapForm = React.memo(
  Form.create({ name: 'mapForm' })(
    CreateMapForm,
  ),
);

export default MapForm;
