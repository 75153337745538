import React from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';
import Badge from 'antd/lib/badge';

import { ListItem, LocationContainer } from './styles';

const actionsList = (openModal, destroyDataset) => ([
  <Button onClick={openModal}>Edit</Button>,
  <Button type="danger" onClick={destroyDataset}>Remove</Button>,
]);

const DatasetItemView = ({
  name,
  color,
  LocationsCount,
  destroyDataset,
  openModal,
  datapoints,
}) => (
  <ListItem actions={actionsList(openModal, destroyDataset)}>
    <div>
      <Badge color={color} />
      {name}
      <LocationContainer>
        <LocationsCount datapointsCount={datapoints.totalCount} />
      </LocationContainer>
    </div>
  </ListItem>
);

DatasetItemView.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  destroyDataset: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  LocationsCount: PropTypes.func.isRequired,
  datapoints: PropTypes.object.isRequired,
};

DatasetItemView.defaultProps = {
  name: '',
  color: '',
};

export default DatasetItemView;
