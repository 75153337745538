import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'antd/lib/typography';

const { Text } = Typography;

const DimensionsText = ({ dimensions, dimensionsAlert }) => (
  <Text type={dimensionsAlert && 'danger'}>{dimensions}</Text>
);

const Dimensions = props => (
  <div>
    <Text strong>
      Dimensions:
      {' '}
      <DimensionsText {...props} />
      {' '}
    </Text>
    <Text type="secondary">(8k x 8k max)</Text>
  </div>
);

Dimensions.propTypes = {
  dimensions: PropTypes.string.isRequired,
};

export default Dimensions;
