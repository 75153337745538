import find from 'lodash/find';

import { MAP_DEFAULT_COLOR } from 'utils/constants';

import { createGradient } from './heatmapLayer';


const MIN_ZOOM_LEVEL = 7;

const circleColors = {
  calculateMidpoint(min, max) {
    return (min + max) / 2;
  },
  getGradientRange({ heatmapWeight, aggregatableFields }) {
    const defaultRange = [0, 10, 20];
    const heatmapRange = find(aggregatableFields, { name: heatmapWeight });

    if (heatmapRange) {
      const { min, max } = heatmapRange;
      const mid = this.calculateMidpoint(min, max);

      return [min, mid, max];
    }

    return defaultRange;
  },
  create({ heatmapGradient, heatmapWeight, aggregatableFields }) {
    if (heatmapWeight === '') {
      return MAP_DEFAULT_COLOR;
    }

    const [min, mid, max] = this.getGradientRange({ heatmapWeight, aggregatableFields });

    return [
      'interpolate',
      ['linear'],
      ['get', heatmapWeight],
      ...createGradient(heatmapGradient, [min, mid, max]),
    ];
  },
};

export const heatmapCircleLayer = ({ heatmapGradient, heatmapWeight = '', aggregatableFields }) => ({
  id: 'circle-point',
  type: 'circle',
  minzoom: MIN_ZOOM_LEVEL,
  paint: {
    'circle-radius': ['get', heatmapWeight],
    'circle-color': circleColors.create({ heatmapGradient, heatmapWeight, aggregatableFields }),
    'circle-stroke-color': '#886d6d',
    'circle-stroke-width': 1,
    'circle-opacity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      7, 0,
      8, 1,
    ],
  },
});
