import { withFormik } from 'formik';
import get from 'lodash/get';

import { MapDatasetsForm } from 'components';
import { MAP_DEFAULT_COLOR } from 'utils/constants';

const createMapDatasetForm = props => withFormik({
  mapPropsToValues: () => {
    const datasetId = get(props, 'datasets[0].id', '');

    return ({
      displayName: '',
      color: MAP_DEFAULT_COLOR,
      datasetId,
      initialDatasetsName: '',
    });
  },
})(MapDatasetsForm);

export default createMapDatasetForm;
