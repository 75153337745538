import styled from 'styled-components';

export const StyledData = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSpace = styled.div`
  margin: 0 .5rem;
`;

export const StyledDataTitle = styled.p`
  margin-top: .5rem;
`;

export const StyledOptional = styled.span`
  font-size: 11px;
`;

export const StyledFilterTitle = styled.h4`
  margin-bottom: -5px;
`;
