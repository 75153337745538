import React from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import Typography from 'antd/lib/typography';

import { StyledContent } from './styles';

const { Title, Text } = Typography;

const SelectDatasets = ({ openModal }) => (
  <StyledContent>
    <Card>
      <StyledContent>
        <Title level={4}>You haven't selected any Datasets</Title>
        <Text>Datasets are groups of locations you can show on your map.</Text>
      </StyledContent>
      <Button type="primary" onClick={openModal}>Select a Dataset</Button>
    </Card>
  </StyledContent>
);

SelectDatasets.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default SelectDatasets;
