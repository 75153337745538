import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { AddAggregateCard } from 'components';

import AggregatesList from './AggregatesList';

const RenderInsights = (props) => {
  const { aggregatableFields, mapDatasets, mapShapes } = props;
  const aggregates = get(props, 'aggregates', []);

  return !isEmpty(aggregates)
    ? (
      <AggregatesList
        insights={aggregates}
        aggregatableFields={aggregatableFields}
        aggregates={aggregates}
        mapDatasets={mapDatasets}
        mapShapes={mapShapes}
      />
    )
    : (
      <AddAggregateCard
        mapShapes={mapShapes}
        aggregates={aggregates}
        mapDatasets={mapDatasets}
        aggregatableFields={aggregatableFields}
      />
    );
};

RenderInsights.propTypes = {
  mapShapes: PropTypes.array,
  mapDatasets: PropTypes.array,
  aggregatableFields: PropTypes.array,
};

RenderInsights.defaultProps = {
  mapDatasets: [],
};

export default React.memo(RenderInsights);
