import React from 'react';
import { useSelector } from 'react-redux';

import { useRouter } from 'utils';

import ExportData from '.';
import DownloadFileLink from './DownloadFileLink';

const ExportDataContainer = () => {
  const { pageId } = useRouter();
  const { presentMapEnabled } = useSelector(store => store.presentMap);

  const downloadFileLink = DownloadFileLink.createLink(pageId);

  return !presentMapEnabled ? <ExportData downloadFileLink={downloadFileLink} /> : null;
};

export default ExportDataContainer;
