import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ToggleExportSettingsButton from '.';


const ToggleExportSettingsButtonContainer = ({ presentMapEnabled }) => {
  const [showExportSettings, toggleExportSettings] = useState(presentMapEnabled);

  const handleToggleExportSettings = () => {
    toggleExportSettings(shouldShowExportSettings => !shouldShowExportSettings);
  };

  useEffect(() => () => {
    toggleExportSettings(presentMapEnabled);
  }, [presentMapEnabled]);

  return presentMapEnabled ? (
    <ToggleExportSettingsButton
      handleToggleExportSettings={handleToggleExportSettings}
      showExportSettings={showExportSettings}
    />
  ) : null;
};

ToggleExportSettingsButtonContainer.propTypes = {
  presentMapEnabled: PropTypes.bool,
};

const mapStateToProps = ({ presentMap }) => ({ ...presentMap });

export default connect(mapStateToProps, null)(ToggleExportSettingsButtonContainer);
