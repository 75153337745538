import React from 'react';
import PropTypes from 'prop-types';
import Radio from 'antd/lib/radio';

const { Group, Button } = Radio;

const ImageSize = ({ handleRadioChanges }) => (
  <div>
    <Group
      defaultValue="cm"
      buttonStyle="solid"
      onChange={handleRadioChanges}
    >
      <Button value="cm">cm</Button>
      <Button value="in">in</Button>
    </Group>
  </div>
);

ImageSize.propTypes = {
  handleRadioChanges: PropTypes.func.isRequired,
};

export default ImageSize;
