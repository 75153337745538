import { useMutation } from '@apollo/react-hooks';
import { importDestroy } from 'graphql/queries';

const ImportService = (id) => {
  const [destroyImport] = useMutation(importDestroy, {
    variables: {
      input: {
        id: Number(id),
      },
    },
  });

  return ({ destroyImport });
};

export default ImportService;
