import styled from 'styled-components';
import card from 'antd/lib/card';

export const Card = styled(card)`
  margin-bottom: 1rem;
  
  .ant-card-body {
    padding: 0;
  }
`;
