import styled from 'styled-components';

const NavigationControlContainer = styled.div`
  position: absolute;
  right: 0;
  margin: 10px;
`;

export const StyledEditMap = styled.div`
  height: calc(100vh - 64px);
`;

export default NavigationControlContainer;
