import { useContext } from 'react';
import { __RouterContext } from 'react-router';

const useRouter = () => {
  const {
    history,
    location,
    match: { params: { id } },
  } = useContext(__RouterContext);

  return ({ history, location, pageId: id });
};

export default useRouter;
