import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import has from 'lodash/has';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import TreeSelect from 'antd/lib/tree-select';

import { useRouter } from 'utils';

import UseUpdateShapesFromMap from '../UseUpdateShapesFromMap';
import BoundariesSelectService from '../service';
import TreeShapes from './TreeShapes';
import { StyledTreeSelect } from './styles';

const { SHOW_PARENT } = TreeSelect;

const getShapeId = (node) => {
  const shapeId = get(node, 'triggerNode.key', null);
  const isChecked = get(node, 'checked', true);

  return !isChecked ? node.triggerNode.props.eventKey : shapeId;
};

const getShapesIdsToUpdate = (shapesToUpdate, shapeGroups) => {
  const shapesFromGroup = shapesToUpdate.reduce((initialShapes, shape) => {
    const shapes = initialShapes;

    const isShapeGroup = shape.value.includes('group');
    if (isShapeGroup) {
      const shapeId = shape.value.split('-')[0];
      const shapeGroup = find(shapeGroups, { id: shapeId });

      shapes.push(...shapeGroup.shapes);
    } else {
      shapes.push({ id: shape.value });
    }

    return shapes;
  }, []);

  return shapesFromGroup.map(({ id }) => parseInt(id, 10));
};

let shapesToUpdate = [];
const TreeSelectShapes = ({ shapeGroups, mapShapes }) => {
  const treeData = useMemo(() => TreeShapes.normalizeTreeData(shapeGroups), [shapeGroups]);
  const initialShapes = useMemo(() => TreeShapes.normalizeShapeFromProps(mapShapes), [mapShapes]);

  const [treeSelectShapes, changeTreeSelectShapes] = useState(() => initialShapes);
  const { pageId } = useRouter();
  const {
    AddBounds,
    RemoveBounds,
    RemoveShapeGroup,
    MapShapesSet,
  } = useMemo(() => BoundariesSelectService(pageId), [pageId]);

  const addShape = AddBounds();
  const removeShapeGroup = RemoveShapeGroup();
  const removeShape = RemoveBounds(mapShapes);
  const setShapesIds = MapShapesSet();

  const handleNotDropDownEvents = (node) => {
    const isShapeGroup = get(node, 'triggerNode.props.children[0]', false);
    const notDropDown = !has(node, 'checked');
    const shapeId = getShapeId(node);

    if (notDropDown && isShapeGroup) {
      removeShapeGroup(shapeId);
    } else if (notDropDown) {
      removeShape(shapeId);
    }
  };

  const handleOnChangeTreeSelect = (boundaries, label, node) => {
    shapesToUpdate = boundaries;
    changeTreeSelectShapes(boundaries);

    handleNotDropDownEvents(node);
  };

  UseUpdateShapesFromMap(addShape, removeShape);

  // observe changes when a user closes the dropdown
  useEffect(() => {
    const observeDropdownEvents = (event) => {
      const observableClassName = get(event, '[0].target.className', false);
      const isDropdownClosed = !observableClassName.includes('ant-select-open');

      if (isDropdownClosed) {
        const shapesIds = getShapesIdsToUpdate(shapesToUpdate, shapeGroups);

        setShapesIds(shapesIds);
      }
    };

    const observer = new MutationObserver(((event) => {
      observeDropdownEvents(event);
    }));

    const treeSelect = document.querySelector('.ant-select');

    observer.observe(treeSelect, { attributeFilter: ['aria-expanded'] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // consistent shapes for tree component state
  useEffect(() => {
    changeTreeSelectShapes(initialShapes);
    shapesToUpdate = initialShapes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapShapes]);

  return (
    <StyledTreeSelect
      treeCheckable
      labelInValue
      searchPlaceholder="Select..."
      showCheckedStrategy={SHOW_PARENT}
      treeNodeFilterProp="title"
      treeData={treeData}
      value={treeSelectShapes}
      defaultValue={initialShapes}
      onChange={handleOnChangeTreeSelect}
    />
  );
};

TreeSelectShapes.propTypes = {
  shapeGroups: PropTypes.array,
  mapShapes: PropTypes.array,
};

export default React.memo(TreeSelectShapes, (prev, next) => isEqual(prev.mapShapes, next.mapShapes));
