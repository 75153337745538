import styled from 'styled-components';

export const StyledSubshapes = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 1.5rem;
  margin-top: .2rem;
`;

export const StyledSubtotal = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
`;
