import { useMutation } from '@apollo/react-hooks';
import get from 'lodash/get';

import { showErrorNotification, showInfoNotification } from 'components';
import {
  destroyMap,
  fetchMaps,
  datasetDestroy,
  fetchDatasets,
} from 'graphql/queries';

const createQueryObject = (destroy = null, fetch = null) => ({ destroyMutation: destroy, fetchQuery: fetch });

const getQueriesForType = {
  Map: createQueryObject(destroyMap, fetchMaps),
  Dataset: createQueryObject(datasetDestroy, fetchDatasets),
};

const showNotification = ({ data }) => {
  const deletedMapId = get(data, 'mapDestroy.deletedId', false);

  if (deletedMapId) {
    showInfoNotification(`Deleted the map with id ${deletedMapId}`);
  }
};

const showErrorMessage = (error) => {
  const deletedMapError = get(error, 'graphQLErrors[0].message', false);

  if (deletedMapError) {
    showErrorNotification(deletedMapError);
  }
};

const createVariables = id => ({
  input: {
    id,
  },
});

const CardService = (id, __typename) => {
  // eslint-disable-next-line
  const { destroyMutation, fetchQuery } = getQueriesForType[__typename];

  const [runMutation, data] = useMutation(destroyMutation, {
    variables: createVariables(id),
    refetchQueries: [{ query: fetchQuery }],
    onCompleted: response => showNotification({ data: response }),
    onError: error => showErrorMessage(error),
  });

  return ({ runMutation, data });
};

export default CardService;
