import AwesomeDebouncePromise from 'awesome-debounce-promise';

const searchDebounced = AwesomeDebouncePromise(fetch, 700);

const debouncedFetch = async (url) => {
  const response = await searchDebounced(url);

  return response.json();
};

export default debouncedFetch;
