import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';

import AggregateCard from './AggregateCard';


const AggregatesList = ({
  aggregates,
  mapShapes,
  mapDatasets,
  aggregatableFields,
}) => {
  const orderedAggregates = orderBy(aggregates, ['id'], ['asc']);

  return (
    <Fragment>
      {orderedAggregates.map((item, index) => (
        <AggregateCard
          key={item.id}
          aggregatableFields={aggregatableFields}
          aggregates={orderedAggregates}
          allMapDatasets={mapDatasets}
          aggregateInfo={orderedAggregates[index]}
          mapShapes={mapShapes}
          {...item}
        />
      ))}
    </Fragment>
  );
}

AggregatesList.propTypes = {
  mapShapes: PropTypes.array,
  aggregates: PropTypes.array,
  mapDatasets: PropTypes.array,
  aggregatableFields: PropTypes.array,
};

export default AggregatesList;
