import { createContext } from 'react';

export const MapStyleFormContext = createContext();

export const createMapStyleFormProps = ({
  basemap,
  heatmapGradient,
  shapeBorderColor,
  visualizationType,
  aggregatableFields,
  heatmapWeightField,
}) => ({
  basemap,
  heatmapGradient,
  shapeBorderColor,
  visualizationType,
  aggregatableFields,
  heatmapWeightField,
});
