import React from 'react';
import PropTypes from 'prop-types';
import Radio from 'antd/lib/radio';

import { Label } from 'components';
import { SPLIT_ON } from 'components/maps/Aggregates/constants';

const { NONE, MAP_DATASET, MAP_SHAPE } = SPLIT_ON;

const SplitOn = ({ form, splitValue, changeSplitOnValue }) => {
  const { getFieldDecorator } = form;
  const handleOnChange = ({ target }) => {
    changeSplitOnValue(target.value);
  };

  return (
    <div>
      <Label colon={false} label="Split on">
        {getFieldDecorator('splitOn', { initialValue: splitValue })(
          <Radio.Group buttonStyle="solid" onChange={handleOnChange}>
            <Radio.Button value={NONE}>None</Radio.Button>
            <Radio.Button value={MAP_DATASET}>Datasets</Radio.Button>
            <Radio.Button value={MAP_SHAPE}>Boundaries</Radio.Button>
          </Radio.Group>,
        )}
      </Label>
    </div>
  );
};

SplitOn.propTypes = {
  splitValue: PropTypes.string,
  form: PropTypes.object.isRequired,
  changeSplitOnValue: PropTypes.func.isRequired,
};

export default SplitOn;
