import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'antd/lib/modal';
import { AddLocationFormContainer } from 'components';

const AddLocationModal = (props) => {
  const { visible, closeModal } = props;

  return (
    <Modal
      destroyOnClose
      title="Location"
      visible={visible}
      onCancel={closeModal}
      width={700}
      footer={null}
    >
      <AddLocationFormContainer okText="Create Datapoint" {...props} />
    </Modal>
  );
};

AddLocationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default AddLocationModal;
