import React from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import Typography from 'antd/lib/typography';

import { AggregateDataCreate } from 'components';
import { useModal } from 'utils';

import { StyledTextContainer } from './styles';

const { Title, Text } = Typography;


const AddAggregateCard = ({
  id,
  mapDatasets,
  aggregatableFields,
}) => {
  const { openModal, ...modalProps } = useModal();

  return (
    <Card>
      <StyledTextContainer>
        <Title level={4}>Add some Insights</Title>
        <Text>Aggregate your location data to gain insights into each electorate.</Text>
      </StyledTextContainer>
      <Button type="primary" onClick={openModal}>
        Create an Insight
      </Button>
      <AggregateDataCreate
        id={id}
        mapDatasets={mapDatasets}
        aggregatableFields={aggregatableFields}
        {...modalProps}
      />
    </Card>
  );
};

AddAggregateCard.propTypes = {
  id: PropTypes.string,
  mapDatasets: PropTypes.array,
  aggregatableFields: PropTypes.array,
};

export default AddAggregateCard;
