import styled from 'styled-components';

export const StyledTitle = styled.div`
  font-size: 30px;
  font-weight: 700;
  
  .ant-typography-edit-content {
    left: 0;
    margin-top: 0;
    background-color: transparent;
  }
  
  textarea {
    font-size: 30px;
    font-weight: 700;
    border: none;
    outline: none;
    color: #000;
  }
  
  .ant-input {
    border: none;
    outline: none;
    padding: 0;
    background-color: transparent;
  }
`;
