import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { ImportLocationProgressStatusSelector } from 'state/progressImportLocations/selectors';
import { DatasetsEmptyLocations, ImportedFileCards } from 'components';

const checkMapLocations = ({ imports, datapointsGeojson }) => {
  const locations = get(datapointsGeojson, 'features', []);

  return (isEmpty(locations) && isEmpty(imports));
};

const RenderDatapointsList = ({ imports, loading, datapointsGeojson }) => {
  const { fileName } = useSelector(state => ImportLocationProgressStatusSelector(state));

  const isFileInProgress = Boolean(fileName);
  const isMapEmpty = checkMapLocations({ imports, datapointsGeojson });
  const shouldRenderDatasetsEmptyLocations = (isMapEmpty && !isFileInProgress && !loading);

  return (
    <Fragment>
      {imports && <ImportedFileCards imports={imports} />}
      {shouldRenderDatasetsEmptyLocations && <DatasetsEmptyLocations />}
    </Fragment>
  );
};

RenderDatapointsList.propTypes = {
  loading: PropTypes.bool.isRequired,
  datapointsGeojson: PropTypes.object,
  imports: PropTypes.array,
};

RenderDatapointsList.defaultProps = {
  datapointsGeojson: null,
  imports: null,
};

export default RenderDatapointsList;
