export const SPLIT_ON = {
  NONE: 'NONE',
  MAP_SHAPE: 'MAP_SHAPE',
  MAP_DATASET: 'MAP_DATASET',
};

export const FUNCTION_TYPES = {
  COUNT: 'COUNT',
  SUM: 'SUM',
  AVERAGE: 'AVERAGE',
  MEDIAN: 'MEDIAN',
};
