import { withFormik } from 'formik';
import get from 'lodash/get';

import { LocationForm } from 'components';

const EditLocationForm = withFormik({
  mapPropsToValues: ({ selectedDataPoint }) => {
    const description = get(selectedDataPoint, 'description', '');
    const normalizedDescription = description !== 'null' ? description : '';

    return ({ ...selectedDataPoint, description: normalizedDescription });
  },
  handleSubmit: async (values, { props }) => {
    await props.editLocation(values);
  },
})(LocationForm);

export default EditLocationForm;
