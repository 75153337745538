/* eslint-disable */
import remove from 'lodash/remove';

const updateCoordinates = (item, updatedDatapoint) => {
  const [type, lat, long] = updatedDatapoint.lonlat.split(' ');

  const lattitude = parseFloat(long);
  const longtitude = parseFloat(lat.substr(1));

  item.geometry.coordinates[0] = longtitude;
  item.geometry.coordinates[1] = lattitude;
};

const createCoordinates = (datapointCreate) => {
  const [type, lat, long] = datapointCreate.lonlat.split(' ');

  const lattitude = parseFloat(long);
  const longtitude = parseFloat(lat.substr(1));

  return [longtitude, lattitude];
};

class EditMapDataPointsMutation {
  static createDatapoint = (datapointsGeojson, datapointCreate) => {
    // add an object creator
    const createdDatapoint = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: createCoordinates(datapointCreate),
      },
      properties: datapointCreate,
      id: Number(datapointCreate.id),
    };

    datapointsGeojson.features.push(createdDatapoint);
  };

  static updateDatapoint = (datapointsGeojson, updatedDatapoint) => {
    const updatedPointId = parseInt(updatedDatapoint.id, 10);

    datapointsGeojson.features.forEach((item) => {
      if (item.id === updatedPointId) {
        item.properties = updatedDatapoint;
        updateCoordinates(item, updatedDatapoint);
      }
    });
  };

  static deleteDatapoint = (datapointsGeojson, deletedId) => {
    if (datapointsGeojson) {
      remove(datapointsGeojson.features, ({ id }) => id === deletedId);
    }
  };
}

export default EditMapDataPointsMutation;
