import mapboxGl from 'mapbox-gl';

const addCustomAttribution = (mapInstance) => {
  const attributions = new mapboxGl.AttributionControl({
    customAttribution: 'Advoc8 Maps',
    compact: true,
  });

  mapInstance.getMap().addControl(attributions);
};

export default addCustomAttribution;
