import styled from 'styled-components';

export const SideBar = styled.div`
  position: absolute;
  background: transparent;
  padding: 1rem;
  height: calc(100vh - 64px);
`;

export const ButtonsContainer = styled.div`
  padding-bottom: 1rem;
`;
