import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectPointEditMap } from 'state/pointEditMap/actions';
import { useRouter } from 'utils';

import PopupRemoveService from './service';
import { StyledWrapper, PopupEditButtonText, PopupEditIcon } from './style';

const PopupRemoveButton = () => {
  const { pageId } = useRouter();
  const dispatch = useDispatch();
  const { selectedDataPoint } = useSelector(state => state.selectPointEditMap);

  const { destroyDatapoint } = PopupRemoveService(pageId, selectedDataPoint);

  const destroy = () => {
    destroyDatapoint();
    dispatch(selectPointEditMap({}));
  };

  return (
    <StyledWrapper>
      <PopupEditIcon type="delete" />
      <PopupEditButtonText onClick={destroy}>Remove</PopupEditButtonText>
    </StyledWrapper>
  );
};

export default React.memo(PopupRemoveButton);
