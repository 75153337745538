import styled from 'styled-components';

export const PopupInfoContainer = styled.div`
  background-color: ${props => props.color};
  padding: 10px;
  color: #fff;
  
  span {
    color: ${props => props.textColor};
  }
`;
