import styled from 'styled-components';
import Icon from 'antd/lib/icon';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin-right: 15px;
  cursor: pointer;
`;

export const PopupEditButtonText = styled.div`
  margin-left: 5px;
  color: #1890ff;
`;

export const PopupEditIcon = styled(Icon)`
  color: #1890ff;
`;
