import styled from 'styled-components';

export const StyledWrapper = styled.div`
  position: relative;
  z-index: 1;
  border-radius: 4px;
  width: 360px;
  max-height: 70%;
  overflow-x: hidden;
`;
