import React from 'react';
import PropTypes from 'prop-types';
import List from 'antd/lib/list';

const Suggestion = List.Item;

const ChooseSuggestion = ({ changeAddress, address, getAddress }) => {
  const handleChoose = (e) => {
    e.preventDefault();

    changeAddress(address);
    getAddress(address);
  };

  return <a href="/" onClick={handleChoose}>Choose</a>;
};

const SuggestionsList = ({ changeAddress, address, getAddress }) => {
  const chooseSuggestion = (
    <ChooseSuggestion
      changeAddress={changeAddress}
      address={address}
      getAddress={getAddress}
    />
  );

  return (
    <Suggestion actions={[chooseSuggestion]}>
      {address}
    </Suggestion>
  );
};

SuggestionsList.propTypes = {
  address: PropTypes.string.isRequired,
  changeAddress: PropTypes.func.isRequired,
  getAddress: PropTypes.func.isRequired,
};

export default SuggestionsList;
