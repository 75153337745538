import { useMutation } from '@apollo/react-hooks';
import get from 'lodash/get';

import { showErrorNotification } from 'components';
import { showInfoNotification } from 'components/shared/Notification';


const EditPageTitle = {
  createVariables: (pageId, text) => ({
    variables: {
      input: {
        id: pageId,
        name: text,
      },
    },
  }),
  CreateUpdateTitleMutation: (query) => {
    const [updateTitle, { error }] = useMutation(query, {
      onCompleted: () => showInfoNotification('Title was updated successfully!'),
      onError: (errorResponse) => {
        const errorMessage = get(errorResponse, 'graphQLErrors[0].message', false);

        if (errorMessage) {
          const errorMessageWithoutPrefix = errorMessage.substring(19);

          showErrorNotification(errorMessageWithoutPrefix);
        }

        showErrorNotification('Cannot update the title');
      },
    });

    return { updateTitle, error };
  },
};

export default EditPageTitle;
