import styled from 'styled-components';

export const StyledSpinner = styled.div`
  position: relative;
  left: 6px;
  top: 8px;
  width: 50px;
  height: 50px;
  float: left;
  
  & img {
    position: absolute;
    left: 10px;
    top: 10px;
  }
  
  & svg {
    width: 100%;
    height: 100%;
    animation: spin 1s linear infinite;
  }
  
  & circle {
    stroke-width: 2;
    stroke-dasharray: 60, 20;
    fill: none;
    stroke: #EE4B81;
  }
  
  @keyframes spin { 
    100% { 
      transform: rotate(360deg);
    }
  }
`;
