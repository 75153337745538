import React from 'react';

import { EditLocationModal } from 'components';
import { useModal } from 'utils';

import { PopupEditButtonText, PopupEditIcon, StyledWrapper } from './style';

const PopupEditButton = () => {
  const { openModal, ...modalProps } = useModal();

  return (
    <StyledWrapper>
      <PopupEditIcon type="form" />
      <PopupEditButtonText onClick={openModal}>Edit</PopupEditButtonText>
      <EditLocationModal {...modalProps} />
    </StyledWrapper>
  );
};

export default PopupEditButton;
