import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { ImportLocationsProgressBar } from 'components';

import FileCardService from './service';
import CountNotFoundLocations from './CountNotFoundLocations';
import { RemoveImportButton } from './style';

const ImportProgressBar = ({
  id,
  pageId,
  fileName,
  percentComplete,
  notFoundDatapoints,
}) => {
  const { destroyImport } = FileCardService(id, pageId);

  const handleDestroyImport = (e) => {
    e.stopPropagation();

    destroyImport();
  };

  return (
    <Fragment>
      <ImportLocationsProgressBar
        fileName={fileName}
        percent={percentComplete}
        status="success"
        strokeColor="orange"
      />
      <CountNotFoundLocations notFoundDatapoints={notFoundDatapoints} />
      <RemoveImportButton type="link" onClick={handleDestroyImport}>Cancel</RemoveImportButton>
    </Fragment>
  );
};

ImportProgressBar.propTypes = {
  id: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  percentComplete: PropTypes.number.isRequired,
  notFoundDatapoints: PropTypes.object.isRequired,
};

export default ImportProgressBar;
