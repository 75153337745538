import styled from 'styled-components';
import Button from 'antd/lib/button';

const StyledButton = styled(Button)`
  position: relative;
  z-index: 1;
  margin-right: .5rem;
`;

export default StyledButton;
