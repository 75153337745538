import React from 'react';
import PropTypes from 'prop-types';
import Select from 'antd/lib/select';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { FUNCTION_TYPES } from 'components/maps/Aggregates/constants';
import { StyledInputContainer } from '../styles';

const { Option } = Select;
const {
  SUM,
  COUNT,
  MEDIAN,
  AVERAGE,
} = FUNCTION_TYPES;

const FunctionSelect = ({
  form,
  insightId,
  aggregates,
  aggregatableFields,
}) => {
  const { getFieldDecorator, getFieldValue } = form;

  const datasetToAggregate = find(aggregates, ['id', insightId]);
  const value = get(aggregates, '[0].totals.value', true);
  const initialValue = (datasetToAggregate && datasetToAggregate.function) || COUNT;

  const selectedField = getFieldValue('field');
  const isDisabled = selectedField === null || isEmpty(aggregatableFields);

  return (
    <StyledInputContainer>
      {getFieldDecorator('function', { initialValue: value ? initialValue : COUNT })(
        <Select>
          <Option value={COUNT}>count</Option>
          <Option disabled={isDisabled} value={SUM}>sum</Option>
          <Option disabled={isDisabled} value={AVERAGE}>average</Option>
          <Option disabled={isDisabled} value={MEDIAN}>median</Option>
        </Select>,
      )}
    </StyledInputContainer>
  );
};

FunctionSelect.propTypes = {
  aggregates: PropTypes.array,
  aggregatableFields: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  insightId: PropTypes.string,
};

export default FunctionSelect;
