import { createSelector } from 'reselect';

const getImportLocationProgressStatus = state => state.importLocationsProgressStatus;

const ImportLocationProgressStatusSelector = createSelector(
  getImportLocationProgressStatus,
  importLocationsProgressStatus => ({ ...importLocationsProgressStatus.progressStatus }),
);

export {
  ImportLocationProgressStatusSelector,
};
