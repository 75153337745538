import { useMutation } from '@apollo/react-hooks';

import { mapDatasetUpdate, fetchMap } from 'graphql/queries';

const UpdateMapDatasetService = (pageId) => {
  const [updateMapDataset] = useMutation(mapDatasetUpdate, {
    refetchQueries: [{
      query: fetchMap,
      variables: ({ id: pageId }),
    }],
  });

  return ({ updateMapDataset });
};

export default UpdateMapDatasetService;
