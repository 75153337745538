import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { DangerLink, Link as LinkContainer, Section } from '../styles';

const CardButtons = ({ editLink, deleteCard }) => (
  <Section>
    <LinkContainer type="link">
      <Link to={editLink}>Open</Link>
    </LinkContainer>
    <DangerLink id="delete-card" type="link" onClick={deleteCard}>Delete</DangerLink>
  </Section>
);

CardButtons.propTypes = {
  editLink: PropTypes.string.isRequired,
  deleteCard: PropTypes.func.isRequired,
};

export default CardButtons;
