import React from 'react';
import { useSelector } from 'react-redux';
import Layout from 'antd/lib/layout';

import { fetchMap } from 'graphql/queries';
import { MapContainer, PresentMapInfo } from 'components';
import usePageInfo from 'pages/edit/usePageInfo';

import SideBar from './SideBar';

const EditMaps = (props) => {
  const { presentMapEnabled } = useSelector(({ presentMap }) => presentMap);
  const { pageInfo } = usePageInfo({ query: fetchMap, type: 'map', ...props });

  return (
    <Layout id="editMaps">
      <div hidden={presentMapEnabled}>
        <SideBar pageInfo={pageInfo} props={props} />
      </div>
      {pageInfo && (
        <>
          <MapContainer {...pageInfo} />
          <PresentMapInfo {...pageInfo} />
        </>
      )}
    </Layout>
  );
};

export default React.memo(EditMaps);
