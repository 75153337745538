import { gql } from 'apollo-boost';

export const sessionInfoQuery = gql`
    {
        sessionInfo {
            currentAccount {
                avatar
                companyName
                fullName
            }
            coreUrl
            signOutUrl
        }
    }
`;
