import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'antd/lib/badge';
import isEmpty from 'lodash/isEmpty';

import {
  BadgeWrapper,
  PresentMapDatasetContainer,
  PresentMapDatasetsLocations,
} from './styles';

const PresentMapDatasets = ({ mapDatasets }) => {
  if (isEmpty(mapDatasets)) {
    return null;
  }

  return mapDatasets.map(({
    id,
    name,
    color,
    datapoints,
  }) => (
    <PresentMapDatasetContainer key={id}>
      <div>
        <div>{name}</div>
        <PresentMapDatasetsLocations>
          {`${datapoints.totalCount} locations`}
        </PresentMapDatasetsLocations>
      </div>
      <BadgeWrapper>
        <Badge color={color} />
      </BadgeWrapper>
    </PresentMapDatasetContainer>
  ));
};

PresentMapDatasets.propTypes = {
  name: PropTypes.string,
  datapoints: PropTypes.object,
  color: PropTypes.string,
};

export default PresentMapDatasets;
