import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'antd/lib/switch';

import { Label } from 'components';

import { StyledSwitch } from '../styles';

const LegendSwitch = ({ changeExportSettings }) => {
  const switchLegend = (show) => {
    changeExportSettings({
      showLegend: show,
    });
  };

  return (
    <StyledSwitch>
      <Label colon={false} label="Legend">
        <Switch
          defaultChecked
          size="small"
          onChange={switchLegend}
        />
      </Label>
    </StyledSwitch>
  );
};

LegendSwitch.propTypes = {
  changeExportSettings: PropTypes.func.isRequired,
};

export default LegendSwitch;
