import React from 'react';
import { NavigationControl } from 'react-map-gl';

import { NavigationContainer } from './style';

const Navigation = () => (
  <NavigationContainer>
    <NavigationControl />
  </NavigationContainer>
);

export default Navigation;
