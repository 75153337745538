import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';

import 'antd/dist/antd.min.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import client from 'graphql/client';
import initErrorReporting from 'error-reporting';

import { Index } from 'layout';

initErrorReporting();

// eslint-disable-next-line
ReactDOM.render(
  <ApolloProvider client={client}>
    <Index />
  </ApolloProvider>, document.getElementById('root'),
);
