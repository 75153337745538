import React from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';

import { StyledContainer, StyledTitle } from './style';

const AddModal = ({ title, openModal }) => (
  <StyledContainer>
    <StyledTitle level={4}>{title}</StyledTitle>
    <Button type="link" onClick={openModal}>Add</Button>
  </StyledContainer>
);

AddModal.propTypes = {
  title: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default AddModal;
