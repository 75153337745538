import React from 'react';
import PropTypes from 'prop-types';

import { PresentMapButtonView } from './styles';

const PresentMapButton = ({ showPresentMapAction }) => (
  <PresentMapButtonView
    data-html2canvas-ignore
    onClick={showPresentMapAction}
  >
    Present Map
  </PresentMapButtonView>
);

PresentMapButton.propTypes = {
  showPresentMapAction: PropTypes.func.isRequired,
};

export default PresentMapButton;
