import React from 'react';
import { useSelector } from 'react-redux';

import { Spinner } from 'components';
import PagesNavigation from 'layout/Header/PagesNavigation';

import User from './User';
import { Logo, Navbar } from './style';
import logo from './img/navbar_logo.png';

const Header = () => {
  const { presentMapEnabled } = useSelector(({ presentMap }) => presentMap);

  return !presentMapEnabled ? (
    <Navbar>
      <Spinner>
        <Logo src={logo} alt="logo of Advoc8 Maps" />
      </Spinner>
      <PagesNavigation />
      <User />
    </Navbar>
  ) : null;
};

export default React.memo(Header);
