import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'antd/lib/badge';

import { normalizeAggregatesCount } from '../../AggregatesNormalizer';
import { StyledTotalShape, StyledSubshapes } from './styles';

export const SubDatasets = ({ subDatasets }) => (
  subDatasets.map(({
    id, name, color, value,
  }) => (
    <StyledSubshapes key={id}>
      <Badge color={color} text={name} />
      <span>{normalizeAggregatesCount(value)}</span>
    </StyledSubshapes>
  ))
);

const ShapesSubTotals = ({
  shape,
  shapeTotals,
  showSubtotals,
  subDatasets,
}) => (
  <div>
    <StyledTotalShape>
      <div>{shape.name}</div>
      <div>{normalizeAggregatesCount(shapeTotals.value)}</div>
    </StyledTotalShape>
    {showSubtotals && <SubDatasets subDatasets={subDatasets} />}
  </div>
);

ShapesSubTotals.propTypes = {
  shape: PropTypes.object.isRequired,
  shapeTotals: PropTypes.object.isRequired,
  showSubtotals: PropTypes.bool.isRequired,
  subDatasets: PropTypes.array.isRequired,
};

export default ShapesSubTotals;
