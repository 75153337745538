import styled from 'styled-components';

export const StyledPreviewCapture = styled.div`
  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

export const StyledText = styled.p`
  font-size: 16px;
`;
