import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { showPresentMap } from 'state/presentMap/actions';
import { changeExportSettings } from 'state/exportSettings/actions';

import PresentMapButton from '.';

const PresentMapButtonContainer = ({
  mapShapes,
  showPresentMap,
  presentMapEnabled,
  changeExportSettings,
}) => {
  const showPresentMapAction = () => showPresentMap(true);

  changeExportSettings({ hasMapBounds: !isEmpty(mapShapes) });

  return !presentMapEnabled && <PresentMapButton showPresentMapAction={showPresentMapAction} />;
};

PresentMapButtonContainer.propTypes = {
  showPresentMap: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  showPresentMap,
  changeExportSettings,
};

const mapStateToProps = ({ presentMap }) => ({ ...presentMap });

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PresentMapButtonContainer));
