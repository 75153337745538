import React from 'react';
import PropTypes from 'prop-types';
import { RenderAggregates, AggregateDataCreate, AddModal } from 'components';

import { StyledContainer } from './style';

const Aggregates = ({ pageInfo, modalProps }) => (
  <StyledContainer>
    <AddModal title="Insights" {...modalProps} />
    <AggregateDataCreate {...modalProps} {...pageInfo} />
    <RenderAggregates {...pageInfo} />
  </StyledContainer>
);

Aggregates.propTypes = {
  pageInfo: PropTypes.object,
  modalProps: PropTypes.object,
};

export default Aggregates;
