import React from 'react';
import PropTypes from 'prop-types';

import StyledButton from './style';

const OpenAddLocationModal = ({ openModal }) => (
  <StyledButton
    type="submit"
    icon="plus"
    onClick={openModal}
  >
    Add
  </StyledButton>
);

OpenAddLocationModal.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default React.memo(OpenAddLocationModal, () => true);
