import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Layout from 'antd/lib/layout';

const { Header } = Layout;

export const Navbar = styled(Header)`
  background-color: #0a2541;
  padding: 0 1rem;
`;

export const Logo = styled.img`
  height: 30px;
`;

export const Link = styled(NavLink)`
  color: #fff;
  padding-left: 1rem;
  opacity: .5;
  
  &:hover {
    color: #fff;
    opacity: .75;
  }
`;

export const activeStyle = {
  opacity: 1,
};
