import { useMutation } from '@apollo/react-hooks';

import { datasetImport } from 'graphql/queries/datasets';
import get from 'lodash/get';

const createImportedFileStatusVariables = (datasetId, signedId) => ({
  variables: {
    input: {
      id: datasetId,
      signedId,
    },
  },
});

const ImportLocationsService = (pageId, signedId) => {
  const [fetchImportedFileStatusMutation] = useMutation(datasetImport);

  const fetchImportedFileStatus = async () => {
    const importedFileStatusVariables = createImportedFileStatusVariables(pageId, signedId);
    const importedFileStatus = await fetchImportedFileStatusMutation(importedFileStatusVariables);
    const statusResponse = get(importedFileStatus, 'data.datasetImport.import', null);

    return statusResponse;
  };

  return ({ fetchImportedFileStatus });
};

export default ImportLocationsService;
