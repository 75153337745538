import styled from 'styled-components';
import Typography from 'antd/lib/typography';

const { Title } = Typography;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 0 !important;
`;
