import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import { FUNCTION_TYPES } from 'components/maps/Aggregates/constants';

import FieldSelect from '.';

const { COUNT } = FUNCTION_TYPES;

const getInitialValue = ({ aggregates, insightId }) => {
  const updateAggregate = find(aggregates, { id: insightId });
  const value = get(aggregates, '[0].totals.value', true);

  return (isEmpty(updateAggregate) || !value) ? null : updateAggregate.field;
};

const FieldSelectContainer = ({
  form,
  insightId,
  aggregates,
  aggregatableFields,
}) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  const selectedFunction = getFieldValue('function');
  const areAllLocationsDisabled = selectedFunction !== COUNT;

  const initialValue = getInitialValue({
    aggregates,
    insightId,
  });

  const anotherFunction = !areAllLocationsDisabled ? null : initialValue;

  useEffect(() => {
    setFieldsValue({ field: anotherFunction });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return (
    <FieldSelect
      initialValue={initialValue}
      getFieldDecorator={getFieldDecorator}
      aggregatableFields={aggregatableFields}
      areAllLocationsDisabled={areAllLocationsDisabled}
    />
  );
};

FieldSelectContainer.propTypes = {
  form: PropTypes.object.isRequired,
  insightId: PropTypes.string,
  aggregates: PropTypes.array,
  aggregatableFields: PropTypes.array.isRequired,
};

export default FieldSelectContainer;
