import React from 'react';
import PropTypes from 'prop-types';

import { FileCard } from 'components';

import ImportLocationsProgress from './ImportLocationsProgress/container';
import FilesWithError from './FilesWithError';
import { StyledWrapper } from './style';


const ImportedFileCards = (props) => {
  const { imports, percent } = props;

  return (
    <StyledWrapper>
      <FilesWithError imports={imports} />
      <ImportLocationsProgress {...props} />
      {imports.map(({ id, ...datapoints }) => {
        const hasImportNotFoundDatapoints = datapoints.notFoundDatapoints.totalCount > 0;

        return hasImportNotFoundDatapoints && (
          <FileCard key={id} id={id} percent={percent} {...datapoints} />
        );
      })}
    </StyledWrapper>
  );
};

ImportedFileCards.propTypes = {
  imports: PropTypes.array.isRequired,
  pageId: PropTypes.string.isRequired,
  percent: PropTypes.number,
  fileName: PropTypes.string,
};

export default ImportedFileCards;
