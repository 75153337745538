import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import DatasetSelect from '.';

const DatasetSelectContainer = ({
  form,
  aggregates,
  insightId,
  mapDatasets,
  allMapDatasets,
}) => {
  const { getFieldDecorator, setFieldsValue } = form;

  const datasetToAggregate = find(aggregates, ['id', insightId]);
  const aggregatesNames = (
    datasetToAggregate && datasetToAggregate.mapDatasets.map(({ id }) => id)
  );

  // TODO: FIX
  const datasets = isEmpty(allMapDatasets) ? mapDatasets : allMapDatasets;

  return (
    <DatasetSelect
      datasets={datasets}
      aggregatesNames={aggregatesNames}
      getFieldDecorator={getFieldDecorator}
      setFieldsValue={setFieldsValue}
    />
  );
};

DatasetSelectContainer.propTypes = {
  form: PropTypes.object.isRequired,
  aggregates: PropTypes.array,
  insightId: PropTypes.string,
  mapDatasets: PropTypes.array.isRequired,
  allMapDatasets: PropTypes.array,
};

export default DatasetSelectContainer;
