import styled from 'styled-components';

export const StyledTitle = styled.h3`
  margin-bottom: 15px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  
  & > div {
    position: relative;
    cursor: pointer;
  }
`;
