import styled from 'styled-components';

const lowest = ({ lowestValue }) => lowestValue;
const midpoint = ({ midpointValue, showMidpoint }) => (showMidpoint ? `${midpointValue} 50%,` : '');
const highest = ({ highestValue }) => highestValue;

export const Gradient = styled.div`
  height: 40px;
  background: linear-gradient(90deg, ${lowest} 0%, ${midpoint} ${highest} 100%);
`;
