import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { showErrorNotification } from 'components/shared/Notification';
import { datapointUpdate, fetchDataset } from 'graphql/queries';
import { updateDatapointAction } from 'state/datapoint/actions';

const normalizeDatapoint = (data) => {
  data.data.datapointUpdate.datapoint.statistics.forEach((item) => {
    delete item.__typename;
  });

  data.data.datapointUpdate.datapoint.data = data.data.datapointUpdate.datapoint.statistics;

  const { datapoint } = data.data.datapointUpdate;

  return datapoint;
};

const createLongLat = ({ latitude, longitude }) => `POINT(${longitude} ${latitude})`;

export const createEditLocationServiceVariables = ({
  name,
  description,
  datasetId,
  address,
  locations,
  id,
  statistics,
  datapointId,
}) => {
  const lonlat = createLongLat(locations);

  return {
    variables: {
      input: {
        id: Number(datapointId) || Number(id),
        name,
        description,
        datasetId,
        address,
        lonlat,
        statistics,
      },
    },
  };
};

const EditLocationService = (pageId, closeModal) => {
  const dispatch = useDispatch();

  const [updateLocation] = useMutation(datapointUpdate, {
    refetchQueries: [{ query: fetchDataset, variables: { id: pageId } }],
    onCompleted: () => {
      closeModal();
    },
    update: (proxy, data) => {
      // update a data point on the map at once
      const datapoint = normalizeDatapoint(data);

      if (!isEmpty(datapoint)) {
        const updatedDatapoint = ({
          datapointUpdate: datapoint,
          datapointCreate: null,
          deletedDatapointId: null,
        });

        dispatch(updateDatapointAction(updatedDatapoint));
      }
    },
    onError: (error) => {
      const errorMessage = get(error, 'graphQLErrors[0].message', false);
      showErrorNotification(errorMessage);
    },
  });

  return ({ updateLocation });
};

export default EditLocationService;
