import light from './1x/light.png';
import dark from './1x/dark.png';
import terrain from './1x/terrain.png';
import none from './1x/none.png';

import light2x from './2x/light@2x.png';
import dark2x from './2x/dark@2x.png';
import terrain2x from './2x/terrain@2x.png';
import none2x from './2x/none@2x.png';

export const thumbnails = [
  {
    id: 1,
    title: 'Light',
    imageSrc: light,
    imageSrc2x: light2x,
  },
  {
    id: 2,
    title: 'Dark',
    imageSrc: dark,
    imageSrc2x: dark2x,
  },
  {
    id: 3,
    title: 'Terrain',
    imageSrc: terrain,
    imageSrc2x: terrain2x,
  },
  {
    id: 4,
    title: 'None',
    imageSrc: none,
    imageSrc2x: none2x,
  },
];
