import { CHANGE_EXPORT_SETTINGS } from './types';

const exportSettings = (state = { presentMapUnits: 'cm', pixelDensity: 150, imageFormat: 'png' }, { type, settings }) => {
  switch (type) {
    case CHANGE_EXPORT_SETTINGS:
      return { ...state, ...settings };
    default:
      return state;
  }
};

export default exportSettings;
