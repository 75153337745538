import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { StyledEditableText } from './style';

const EditableText = ({
  name,
  pageId,
  initialText,
  saveChanges,
  createVariables,
}) => {
  const [text, changeText] = useState('');

  useEffect(() => {
    changeText(initialText);
  }, [initialText]);

  const updateText = (inputValue) => {
    saveChanges(createVariables(pageId, inputValue, name));

    if (initialText !== '') {
      changeText(inputValue);
    }
  };

  return (
    <StyledEditableText editable={{ onChange: updateText }}>
      {text}
    </StyledEditableText>
  );
};

EditableText.propTypes = {
  name: PropTypes.string,
  initialText: PropTypes.string,
  pageId: PropTypes.string.isRequired,
  saveChanges: PropTypes.func.isRequired,
  createVariables: PropTypes.func.isRequired,
};

export default React.memo(EditableText);
