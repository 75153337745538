import { useMutation } from '@apollo/react-hooks';

import { fetchMap, mapUpdate } from 'graphql/queries';


export const MapCustomizationFormService = (pageId) => {
  const [updateMap] = useMutation(mapUpdate, {
    refetchQueries: [{ query: fetchMap, variables: { id: pageId } }],
  });

  return { updateMap };
};

export const createMutationVariables = ({
  pageId,
  gradient,
  pickedColor,
  showMidpoint,
  stateBasemap,
  weightIntensity,
  stateVisualizationType,
}) => {
  const basemap = stateBasemap.toUpperCase();
  const visualizationType = stateVisualizationType.toUpperCase();

  // when a user disables midpoint we need to remove midpointValue from the heatmapGradient object
  const heatmapGradient = { ...gradient };
  delete heatmapGradient.__typename;

  if (!showMidpoint) {
    heatmapGradient.midpointValue = null;
  } else {
    heatmapGradient.midpointValue = gradient.midpointValue || '#fff';
  }

  return ({
    variables: {
      input: {
        id: pageId,
        basemap,
        visualizationType,
        shapeBorderColor: pickedColor,
        heatmapWeightField: weightIntensity,
        heatmapGradient,
      },
    },
  });
};
