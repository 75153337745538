import React from 'react';
import PropTypes from 'prop-types';

import { StyledPresentMapTitle, StyledTitle, DescriptionText } from './styles';

const PresentMapTitle = ({ name, description }) => (
  <StyledPresentMapTitle>
    <StyledTitle level={3}>{name}</StyledTitle>
    {description && <DescriptionText>{description}</DescriptionText>}
  </StyledPresentMapTitle>
);

PresentMapTitle.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default PresentMapTitle;
