import { SHOW_PRESENT_MAP } from './types';

const presentMap = (state = { presentMapEnabled: false }, { type, presentMapEnabled }) => {
  switch (type) {
    case SHOW_PRESENT_MAP:
      return { ...state, presentMapEnabled };
    default:
      return state;
  }
};

export default presentMap;
