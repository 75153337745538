import debouncedFetch from './debouncedFetch';
import defaultMapStyles from './mapStyles';
import useRouter from './useRouter';
import useModal from './useModal';
import showErrorMessage from './showErrorMessage';
import { idealTextColor } from './ColorTransformer';

// TODO: refactor this solution
export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }

  return false;
};

export const createLink = (action, id) => `${action}/${id}`;

export const generateUniqKey = pre => `${pre}_${new Date().getTime()}`;

export const transformToNumber = array => array && array.map(Number);

export const ifDataReturnComponent = data => component => (data ? component : null);

export {
  debouncedFetch,
  defaultMapStyles,
  useRouter,
  showErrorMessage,
  idealTextColor,
  useModal,
};
