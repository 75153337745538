import React from 'react';
import PropTypes from 'prop-types';

const CountNotFoundLocations = ({ notFoundDatapoints }) => (
  <div>
    {notFoundDatapoints.totalCount}
    {' '}
    locations not found
  </div>
);

CountNotFoundLocations.propTypes = {
  notFoundDatapoints: PropTypes.object.isRequired,
};


export default CountNotFoundLocations;