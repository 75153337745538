import { centimetersPerInch } from '../../constants';

export const convertUnitsToPx = (presentMapUnits, value, ppi) => {
  const formattedValue = Number(value);

  if (presentMapUnits === 'cm') {
    return formattedValue * (ppi / centimetersPerInch);
  }

  if (presentMapUnits === 'in') {
    return formattedValue * ppi;
  }

  return value;
};

export const convertWithUnit = (presentMapUnits, value, ppi) => {
  const formattedValue = Number(value);

  if (presentMapUnits === 'cm') {
    return Number(formattedValue / (ppi / centimetersPerInch)).toFixed(2);
  }

  if (presentMapUnits === 'in') {
    return Number(formattedValue / ppi).toFixed(2);
  }

  return value;
};

export const getWidth = (ppi, presentMapUnits) => {
  const { offsetWidth } = document.getElementById('editMaps');

  return convertWithUnit(presentMapUnits, offsetWidth, ppi);
};

export const getHeight = (ppi, presentMapUnits) => {
  const { offsetHeight } = document.getElementById('editMaps');

  return convertWithUnit(presentMapUnits, offsetHeight, ppi);
};
