import { OPEN_CONTEXT_MENU, CLOSE_CONTEXT_MENU } from './types';

export const openContextMenuAction = contextInfo => ({
  type: OPEN_CONTEXT_MENU,
  payload: {
    isContextMenuOpen: true,
    contextInfo,
  },
});

export const closeContextMenuAction = () => ({
  type: CLOSE_CONTEXT_MENU,
  payload: {
    isContextMenuOpen: false,
    contextInfo: null,
  },
});
