import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import EditMapDataPointsMutation from 'components/datasets/EditMapDatasets/EditMapDataPointsMutation';

const updateMapDatapoints = (datapoint, datapointsGeojson) => {
  if (isEmpty(datapointsGeojson)) {
    return null;
  }

  // TODO: use immutable
  const datapointsGeojsonCopy = { ...datapointsGeojson };

  const deletedDatapointId = get(datapoint, 'deletedDatapointId', false);
  if (deletedDatapointId) {
    EditMapDataPointsMutation.deleteDatapoint(datapointsGeojsonCopy, deletedDatapointId);
  }

  const datapointUpdate = get(datapoint, 'datapointUpdate', false);
  if (datapointUpdate) {
    EditMapDataPointsMutation.updateDatapoint(datapointsGeojsonCopy, datapointUpdate);
  }

  const datapointCreate = get(datapoint, 'datapointCreate', false);
  if (datapointCreate) {
    EditMapDataPointsMutation.createDatapoint(datapointsGeojsonCopy, datapointCreate);
  }

  return datapointsGeojsonCopy;
};

export default updateMapDatapoints;
