import styled from 'styled-components';
import { Popup } from 'react-map-gl';
import Menu from 'antd/lib/menu';

export const StyledPopup = styled(Popup)`
  .mapboxgl-popup-content {
    padding: 0;
  }
  
  .mapboxgl-popup-close-button {
    color: #fff;
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  height: 20px !important;
  line-height: 20px !important;
`;
