import React from 'react';
import PropTypes from 'prop-types';

const UserName = ({ name }) => <div>{name}</div>;

UserName.propTypes = {
  name: PropTypes.string.isRequired,
};

export default UserName;
