import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';

import { showSpinnerAction } from 'state/spinner/actions';


const FetchPageData = ({ match, query, type }) => {
  const dispatch = useDispatch();
  const pageId = get(match, 'params.id', false);
  const { data, loading } = useQuery(query, {
    variables: {
      id: pageId,
    },
  });

  const pageInfo = get(data, type, false);

  dispatch(showSpinnerAction(loading));

  return { loading, pageInfo };
};

const usePageInfo = ({
  match,
  query,
  type,
}) => {
  const { loading, pageInfo } = FetchPageData({ match, query, type });

  return { pageInfo, loading };
};

export default usePageInfo;
