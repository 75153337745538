import React from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';

import { ButtonLayout } from './style';

const UpdateInsightButton = ({
  closeModal,
  resetFields,
  formValues,
  updateInsight,
}) => {
  const update = () => {
    closeModal();
    resetFields();
    updateInsight(formValues);
  };

  return (
    <ButtonLayout>
      <Button type="primary" onClick={update}>Save</Button>
    </ButtonLayout>
  );
};

UpdateInsightButton.propTypes = {
  formValues: PropTypes.object.isRequired,
  resetFields: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateInsight: PropTypes.func.isRequired,
};

export default UpdateInsightButton;
