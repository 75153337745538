import styled from 'styled-components';
import Select from 'antd/lib/select';

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const StyledInputContainer = styled.div`
  .ant-form-item {
    margin-bottom: 0;
  }
`;
