import get from 'lodash/get';
import find from 'lodash/find';

const createPoint = (datapointInfo, datapointColor, statisticsFields) => {
  const {
    id,
    properties,
    geometry: { coordinates },
  } = datapointInfo;

  const {
    name, address, description, data,
  } = properties;

  const [long, lat] = coordinates;

  return {
    lat,
    long,
    name,
    color: datapointColor,
    address,
    description,
    datapointId: id,
    statisticsFields,
    coordinates,
    statistics: data,
  };
};

const showPopup = (selectPointEditMap, popupData) => {
  const {
    datapointInfo, datapointColor, leftButton, statisticsFields,
  } = popupData;

  if (leftButton) {
    const point = createPoint(datapointInfo, datapointColor, statisticsFields);

    selectPointEditMap(point);
  }
};

export const createPopupData = ({ event, datapointsGeojson, statisticsFields }) => {
  const { leftButton } = event;
  const { features } = datapointsGeojson;

  const datapointId = get(event, 'features[0].id', false);
  const datapointColor = get(event, 'features[0].layer.paint.circle-color', false);
  const datapointInfo = find(features, { id: datapointId });

  if (datapointInfo) {
    return {
      leftButton,
      datapointInfo,
      datapointColor,
      statisticsFields,
    };
  }

  return null;
};

export default showPopup;
