import React from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';
import { showErrorNotification } from 'components';

import { StyledButtonContainer } from './styles';
import { createMutationVariables, validateDatasetsForm } from './service';


const SaveMapDatasetForm = ({
  id,
  type,
  values,
  pageId,
  closeModal,
  updateMapDataset,
  pickedColor,
  isClustered,
}) => {
  const saveMapDatasets = () => {
    const variables = createMutationVariables({
      id,
      type,
      pageId,
      color: pickedColor,
      clustered: isClustered,
      formValues: values,
    });

    const { isValid, validationError } = validateDatasetsForm(variables);

    if (isValid) {
      closeModal();
      updateMapDataset(variables);
    } else {
      showErrorNotification(validationError);
    }
  };

  return (
    <StyledButtonContainer>
      <Button
        type="primary"
        htmlType="submit"
        onClick={saveMapDatasets}
      >
        Save
      </Button>
    </StyledButtonContainer>
  );
};

SaveMapDatasetForm.propTypes = {
  pageId: PropTypes.string,
  closeModal: PropTypes.func,
  id: PropTypes.string,
  isClustered: PropTypes.bool,
  pickedColor: PropTypes.string,
  values: PropTypes.object,
  updateMapDataset: PropTypes.func,
  type: PropTypes.string,
};

export default SaveMapDatasetForm;
