import { useMutation } from '@apollo/react-hooks';
import get from 'lodash/get';

import { showErrorNotification } from 'components';
import {
  createMap,
  fetchMaps,
  datasetCreate,
  fetchDatasets,
} from 'graphql/queries';
import { editPagePath } from 'utils/constants';

const redirectToEditPage = ({
  data,
  history,
  createdDataPath,
  pagePathName,
}) => {
  const pageId = get(data, createdDataPath, false);
  const pathToEditPage = pagePathName + editPagePath + pageId;

  if (pageId) {
    history.push(pathToEditPage);
  }
};

const createQueryObject = (create = null, fetch = null, createdDataPath = null) => ({
  createMutation: create,
  fetchQuery: fetch,
  createdDataPath,
});

const getQueriesForType = {
  Map: createQueryObject(createMap, fetchMaps, 'mapCreate.map.id'),
  Dataset: createQueryObject(datasetCreate, fetchDatasets, 'datasetCreate.dataset.id'),
};

const showErrorMessage = ({ error }) => {
  const errorMessage = get(error, 'graphQLErrors[0].message', false);
  showErrorNotification(errorMessage);
};

const CreateMapService = ({
  modalFormValues,
  pagePathName,
  type,
  history,
}) => {
  const { createMutation, fetchQuery, createdDataPath } = getQueriesForType[type];

  const [createEditPage] = useMutation(createMutation, {
    variables: {
      input: modalFormValues,
    },
    refetchQueries: [{ query: fetchQuery }],
    onCompleted: data => redirectToEditPage({
      data,
      history,
      createdDataPath,
      pagePathName,
    }),
    onError: error => showErrorMessage({ error }),
  });

  return ({ createEditPage });
};

export default CreateMapService;
