import React from 'react';
import Typography from 'antd/lib/typography';

import { StyledCard } from './style';

const { Title } = Typography;

const DatasetsEmptyLocations = () => (
  <StyledCard>
    <Title level={4}>This Dataset doesn't have any locations</Title>
    <div>
      You can add locations one at a time or bulk import with
      a CSV file by using the buttons above.
    </div>
  </StyledCard>
);


export default React.memo(DatasetsEmptyLocations);
