import React from 'react';
import PropTypes from 'prop-types';

import DatasetOptions from '.';
import { FetchDataset } from './service';

const DatasetOptionsContainer = ({
  setFieldValue,
  initialDataset,
  selectedDatasets,
}) => {
  const datasets = FetchDataset();
  const changeDatasetId = (datasetId) => {
    setFieldValue('datasetId', datasetId);
  };

  return (
    <DatasetOptions
      datasets={datasets}
      selectedDatasets={selectedDatasets}
      initialDataset={initialDataset}
      changeDatasetId={changeDatasetId}
    />
  );
};

DatasetOptionsContainer.propTypes = {
  setFieldValue: PropTypes.func,
  initialDataset: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  selectedDatasets: PropTypes.array,
};

export default DatasetOptionsContainer;
