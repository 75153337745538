import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Layer, Source } from 'react-map-gl';


import { VISUALISATION_TYPES } from 'utils/constants/visualisationTypes';

import { heatmapLayer } from './heatmapLayer';
import { heatmapCircleLayer } from './heatmapCircleLayer';


const { HEATMAP } = VISUALISATION_TYPES;

const updatedPropertiesWithWeightField = (datapointsGeojson, heatmapWeightField) => {
  datapointsGeojson.features.forEach((feature) => {
    feature.properties.data.forEach((property) => {
      feature.properties[heatmapWeightField] = Number(property.value);
    });
  });
};

const HeatmapLayer = ({
  heatmapGradient,
  visualizationType,
  datapointsGeojson,
  heatmapWeightField,
  aggregatableFields,
}) => {
  const heatmapWeight = heatmapWeightField || '';

  if (visualizationType !== HEATMAP || !datapointsGeojson) {
    return null;
  }

  updatedPropertiesWithWeightField(datapointsGeojson, heatmapWeightField);

  return (
    <Source type="geojson" data={datapointsGeojson}>
      <Layer {...heatmapCircleLayer({ heatmapWeight, heatmapGradient, aggregatableFields })} />
      <Layer {...heatmapLayer(heatmapGradient, heatmapWeight)} />
    </Source>
  );
};

HeatmapLayer.propTypes = {
  datapointsGeojson: PropTypes.object,
  heatmapWeightField: PropTypes.string,
  aggregatableFields: PropTypes.array.isRequired,
  visualizationType: PropTypes.string.isRequired,
  heatmapGradient: PropTypes.shape({
    lowestValue: PropTypes.string,
    midpointValue: PropTypes.string,
    highestValue: PropTypes.string,
  }).isRequired,
};

export default memo(HeatmapLayer);
