import React from 'react';
import PropTypes from 'prop-types';

import HeatmapGradient from './HeatmapGradient';
import HeatmapSettings from './HeatmapSettings';
import IncludeMidpoint from './IncludeMidpoint';
import WeightIntensity from './WeightIntensity';

import { SpaceWrapper } from '../styles';

const HeatmapOptions = ({
  gradient,
  showMidpoint,
  changeGradient,
  toggleMidpoint,
  heatmapWeightField,
  aggregatableFields,
  changeWeightIntensity,
}) => (
  <>
    <SpaceWrapper bottom={15}>
      <HeatmapGradient showMidpoint={showMidpoint} gradient={gradient} />
    </SpaceWrapper>
    <SpaceWrapper bottom={15}>
      <HeatmapSettings
        gradient={gradient}
        showMidpoint={showMidpoint}
        changeGradient={changeGradient}
      />
    </SpaceWrapper>
    <SpaceWrapper bottom={15}>
      <IncludeMidpoint gradient={gradient} toggleMidpoint={toggleMidpoint} />
    </SpaceWrapper>
    <SpaceWrapper bottom={15}>
      <WeightIntensity
        heatmapWeightField={heatmapWeightField}
        aggregatableFields={aggregatableFields}
        onChangeWeightIntensity={changeWeightIntensity}
      />
    </SpaceWrapper>
  </>
);

HeatmapOptions.propTypes = {
  gradient: PropTypes.shape({
    lowestValue: PropTypes.string,
    midpointValue: PropTypes.string,
    highestValue: PropTypes.string,
  }).isRequired,
  showMidpoint: PropTypes.bool.isRequired,
  changeGradient: PropTypes.func.isRequired,
  toggleMidpoint: PropTypes.func.isRequired,
  heatmapWeightField: PropTypes.string,
  aggregatableFields: PropTypes.array.isRequired,
  changeWeightIntensity: PropTypes.func.isRequired,
};

export default HeatmapOptions;
