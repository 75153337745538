import Humanize from 'humanize-plus';

export const normalizeAggregatesCount = (value) => {
  const isValueExist = value > 0;
  const isValueMore100k = value > 100000;

  if (isValueExist && isValueMore100k) {
    return Humanize.compactInteger(value);
  }

  if (isValueExist) {
    return new Intl.NumberFormat('en-AU', { maximumFractionDigits: 2 }).format(value);
  }

  return '-';
};
