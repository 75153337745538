import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactMapGL from 'react-map-gl';
import { Map } from 'immutable';
import get from 'lodash/get';

import addCustomAttribution from 'components/shared/Map/mapAttribution';
import { zoomToDatapoints, zoomToBounds } from 'components/shared/Map/zoomToPoints';
import showPopup, { createPopupData } from 'components/shared/Map/showPopup';
import BoundButtons from 'components/maps/EditMapBound/EditMapBoundsList/BoundButtons';
import EditMapBoundContextMenu from 'components/maps/EditMapBound/EditMapBoundContextMenu';
import ExportDataButton from 'components/maps/ExportData/container';
import MapCustomization from 'components/maps/MapCustomization';
import {
  MapControl,
  PresentMapButton,
  ToggleExportSettingsButton,
  EditMapBound,
  EditMapPopup,
  initialMapSettings,
} from 'components';

import { selectPointEditMap } from 'state/pointEditMap/actions';
import { changeExportSettings } from 'state/exportSettings/actions';
import { selectBoundAction } from 'state/selectBounds/actions';

import { accessToken } from 'utils/constants';
import { MapStyleFormContext, createMapStyleFormProps } from 'contexts/MapStyleFormContext';

import HeatmapLayer from './HeatmapLayer';
import getMapSize from './EditMapSizeCreator';
import { getInitialCoordinates } from './utils/coordinatesCreator';

import { StyledMapWrapper } from './styles';


const mergeMapDatasetsDatapoints = (mapDatasets) => {
  return mapDatasets.reduce((accumulator, mapDataset) => {
    const features = get(mapDataset, 'datapointsGeojson.features', []);
    accumulator.features = [...accumulator.features, ...features];

    return accumulator;
  }, { type: 'FeatureCollection', features: [] });
};

class EditMap extends Component {
  constructor(props) {
    super(props);

    const { mapStyles } = props;

    const { zoom } = initialMapSettings;
    const [longitude, latitude] = getInitialCoordinates(mapStyles);
    this.mapRef = React.createRef();

    this.state = {
      viewport: {
        latitude,
        longitude,
        zoom,
      },
      canOpenContextMenu: false,
    };
  }

  componentDidMount() {
    const { changeExportSettings, name } = this.props;

    this.zoomToPoints();
    changeExportSettings({ mapName: name });

    const mapInstance = get(this.mapRef, 'current', false);

    if (mapInstance) {
      addCustomAttribution(mapInstance);
    }
  }

  componentDidUpdate(prev) {
    const { mapStyles } = this.props;
    const pointsUpdated = prev.mapStyles.getIn(['sources']).size !== mapStyles.getIn(['sources']).size;
    const boundariesList = mapStyles.getIn(['sources', 'boundaries', 'data', 'features'], Map());
    const prevBoundariesList = prev.mapStyles.getIn(['sources', 'boundaries', 'data', 'features'], Map());

    if (boundariesList.size !== prevBoundariesList.size) {
      this.zoomToPoints();
    }

    if (pointsUpdated) {
      this.zoomToPoints();
    }
  }

  componentWillUnmount() {
    // remove a pop up when change the data set
    const { selectPointEditMap, selectBound } = this.props;
    selectPointEditMap({});

    // remove boundaries popup information
    selectBound({});
  }

  get dataAttr() {
    const { viewport } = this.state;
    const [value] = get(viewport, 'center', [0]);

    return Math.floor(value);
  }

  zoomToPoints = () => {
    const { mapStyles } = this.props;
    const setState = this.setState.bind(this);

    zoomToBounds(mapStyles, setState)
    || zoomToDatapoints(mapStyles, setState, 'datapoints-0');
  };

  onViewportChange = ({ width, height, ...viewport }) => {
    this.setState({ viewport });
  };

  showEditMapPopup = (event) => {
    if (event.rightButton) {
      return false;
    }

    const { selectPointEditMap, selectBound } = this.props;
    const mapDatasets = get(this.props, 'mapDatasets', []);

    const datapointsGeojson = mergeMapDatasetsDatapoints(mapDatasets);

    const popupData = createPopupData({ event, datapointsGeojson });

    if (popupData) {
      this.closeContextMenu();
      selectBound(false);

      showPopup(selectPointEditMap, popupData);

      return;
    }

    // close popups on outside click
    selectPointEditMap({});
    this.closeContextMenu();
  };

  handleContextMenu = (event) => {
    event.preventDefault();

    const { selectBound } = this.props;
    const isContextMenuClick = event.target.className !== 'context-menu-item';

    if (isContextMenuClick) {
      // show a context menu
      this.setState({ canOpenContextMenu: true, selectedMapBoundPopup: event });
      // close boundaries popup
      selectBound({});
    }
  };

  closeContextMenu = () => {
    this.setState({ canOpenContextMenu: false });
  };

  render() {
    const {
      color,
      mapShapes,
      mapStyles,
      mapDatasets,
      exportSettings,
      heatmapGradient,
      visualizationType,
      selectedDataPoint,
      presentMapEnabled,
      heatmapWeightField,
      aggregatableFields,
    } = this.props;
    const { viewport, canOpenContextMenu, selectedMapBoundPopup } = this.state;
    const { width, height } = getMapSize(exportSettings, presentMapEnabled);
    const datapointsGeojson = mergeMapDatasetsDatapoints(mapDatasets);

    return (
      <StyledMapWrapper
        id="editMapsMap"
        data-map-center={this.dataAttr}
        presentMapEnabled={presentMapEnabled}
      >
        <ReactMapGL
          attributionControl={false}
          ref={this.mapRef}
          preserveDrawingBuffer
          width={width}
          height={height}
          onViewportChange={this.onViewportChange}
          onClick={this.showEditMapPopup}
          onContextMenu={this.handleContextMenu}
          mapboxApiAccessToken={accessToken}
          mapStyle={mapStyles}
          {...viewport}
        >
          <MapControl />
          <HeatmapLayer
            heatmapGradient={heatmapGradient}
            datapointsGeojson={datapointsGeojson}
            visualizationType={visualizationType}
            heatmapWeightField={heatmapWeightField}
            aggregatableFields={aggregatableFields}
          />
          <MapStyleFormContext.Provider value={createMapStyleFormProps(this.props)}>
            <MapCustomization />
          </MapStyleFormContext.Provider>
          <EditMapPopup
            color={color}
            showEditButtons={false}
            selectedDataPoint={selectedDataPoint}
          />
          <EditMapBoundContextMenu
            closeContextMenu={this.closeContextMenu}
            canOpenContextMenu={canOpenContextMenu}
            selectedBoundPopupInfo={selectedMapBoundPopup}
          />
          <EditMapBound
            renderBoundriesButtons={props => (<BoundButtons mapShapes={mapShapes} {...props} />)}
          />
        </ReactMapGL>
        <PresentMapButton mapShapes={mapShapes} />
        <ExportDataButton />
        <ToggleExportSettingsButton mapDatasets={mapDatasets} />
      </StyledMapWrapper>
    );
  }
}

EditMap.propTypes = {
  presentMapEnabled: PropTypes.bool,
  color: PropTypes.string.isRequired,
  basemap: PropTypes.string.isRequired,
  mapShapes: PropTypes.array.isRequired,
  mapStyles: PropTypes.object.isRequired,
  mapDatasets: PropTypes.array.isRequired,
  exportSettings: PropTypes.object.isRequired,
  shapeBorderColor: PropTypes.string.isRequired,
  visualizationType: PropTypes.string.isRequired,
  heatmapWeightField: PropTypes.string,
  aggregatableFields: PropTypes.array.isRequired,
};

const mapStateToProps = ({
  presentMap,
  exportSettings,
  heatmapSettings,
}) => ({ heatmapSettings, exportSettings, ...presentMap });

const mapDispatchToProps = {
  selectPointEditMap,
  selectBound: selectBoundAction,
  changeExportSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMap);
