export const boundariesLayer = (lineColor = '#165391') => ({
  id: 'electorate',
  type: 'line',
  source: 'boundaries',
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
  paint: {
    'line-color': lineColor,
    'line-width': 2,
    'line-opacity': 1,
  },
});

export const createBoundaries = (boundariesShapes) => {
  if (boundariesShapes.length > 1) {
    const boundaries = boundariesShapes.reduce((acc, next) => {
      acc.features.push(next);

      return acc;
    }, { type: 'FeatureCollection', features: [] });

    return boundaries;
  }

  return boundariesShapes[0];
};
