import React from 'react';
import PropTypes from 'prop-types';

import { ImportLocationsProgressBar } from 'components';
import { useRouter } from 'utils';

import BrokenImportedFileService from './service';

import {
  StyledContainer,
  RemoveImportButton,
} from './style';

const BrokenImportedFile = ({ id, fileName, errorMessage }) => {
  const { pageId } = useRouter();
  const { destroyImport } = BrokenImportedFileService(id, pageId);

  return (
    <StyledContainer>
      <ImportLocationsProgressBar strokeColor="red" fileName={fileName} percent={100} />
      <span>{errorMessage}</span>
      <RemoveImportButton type="link" onClick={destroyImport}>Cancel</RemoveImportButton>
    </StyledContainer>
  );
};

BrokenImportedFile.propTypes = {
  id: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};

export default BrokenImportedFile;
