import React from 'react';
import PropTypes from 'prop-types';

const LocationsCount = ({ datapointsCount }) => {
  const areLocationsExist = datapointsCount > 0;

  return areLocationsExist ? <div>{`${datapointsCount} locations`}</div> : 'Empty';
};

LocationsCount.propTypes = {
  datapointsCount: PropTypes.number.isRequired,
};

export default LocationsCount;
