import React from 'react';
import PropTypes from 'prop-types';
import Input from 'antd/lib/input';
import get from 'lodash/get';

import { Label } from 'components';

import { convertUnitsToPx } from '../utils';
import { StyledInputWrapper } from '../styles';

const HeightInput = ({
  presentMapUnits,
  values,
  ppi,
  changeExportSettings,
  setFieldValue,
}) => {
  const initialHeight = get(values, 'height', '');
  const changeHeight = ({ target }) => {
    const height = target.value;
    const heightInPixels = convertUnitsToPx(presentMapUnits, height, ppi);

    changeExportSettings({ presentMapHeight: heightInPixels });

    setFieldValue('height', height);
  };

  return (
    <StyledInputWrapper>
      <Label colon={false} label="Height">
        <Input
          name="height"
          addonAfter={presentMapUnits}
          onChange={changeHeight}
          value={initialHeight}
        />
      </Label>
    </StyledInputWrapper>
  );
};

HeightInput.propTypes = {
  changeExportSettings: PropTypes.func.isRequired,
  presentMapUnits: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default HeightInput;
