import React, { Fragment } from 'react';

import MapLicense from './MapLicense';
import MapBoundariesLicense from './MapBoundariesLicense';

import { StyledPreviewCapture, StyledText } from './styles';

const MapPreview = () => (
  <Fragment>
    <StyledPreviewCapture id="previewCapture" />
    <StyledText>
      When publishing this map, please include the following attribution
      in the same manner you would cite a photograph.
    </StyledText>
    <MapLicense />
    <MapBoundariesLicense />
  </Fragment>
);

export default MapPreview;
