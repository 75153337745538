import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { AggregateDataUpdate } from 'components';
import { useRouter, useModal } from 'utils';

import AggregateCardBody from './AggregateCardBody';
import CardInsightsButtons from './CardInsightsButtons';

import CardInsightsService from './service';
import AggregateDataUpdateService from '../../../AggregateData/AggregateDataUpdate/service';

const AggregateCard = ({
  id,
  name,
  displayName,
  allMapDatasets,
  mapDatasets,
  mapShapes,
  totals,
  aggregatableFields,
  aggregates,
  aggregateInfo,
}) => {
  const modalProps = useModal();
  const { openModal } = modalProps;

  const { pageId } = useRouter();
  const { destroyInsight } = CardInsightsService(pageId);

  const { updateInsight } = AggregateDataUpdateService(id);

  return (
    <Fragment>
      <AggregateDataUpdate
        aggregateName={displayName}
        insightId={id}
        aggregatableFields={aggregatableFields}
        aggregates={aggregates}
        mapDatasets={mapDatasets}
        allMapDatasets={allMapDatasets}
        updateInsight={updateInsight}
        {...modalProps}
      />
      <AggregateCardBody
        name={name}
        totals={totals}
        mapShapes={mapShapes}
        displayName={displayName}
        allMapDatasets={allMapDatasets}
        aggregateInfo={aggregateInfo}
      >
        <CardInsightsButtons id={id} destroyInsight={destroyInsight} openModal={openModal} />
      </AggregateCardBody>
    </Fragment>
  );
};

AggregateCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  mapDatasets: PropTypes.array,
  allMapDatasets: PropTypes.array,
  mapShapes: PropTypes.array,
  displayName: PropTypes.string,
  totals: PropTypes.object,
  pageInfo: PropTypes.object,
  destroyInsight: PropTypes.func,
  aggregatableFields: PropTypes.array,
  aggregateName: PropTypes.string,
  aggregates: PropTypes.array,
  aggregateInfo: PropTypes.object,
};

export default AggregateCard;
