import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { selectBoundAction } from 'state/selectBounds/actions';

import EditMapBoundsList from './EditMapBoundsList/container';

import { StyledTitleContainer, StyledPopup } from './styles';

const EditMapBound = ({ renderBoundriesButtons }) => {
  const dispatch = useDispatch();
  const { selectedBound } = useSelector(({ boundsSelection }) => ({ ...boundsSelection }));

  if (isEmpty(selectedBound)) {
    return null;
  }

  const closePopup = () => dispatch(selectBoundAction({}));

  const { shapes, lngLat } = selectedBound;
  const [longitude, latitude] = lngLat;

  return (
    <StyledPopup
      latitude={latitude}
      longitude={longitude}
      closeOnClick={false}
      onClose={closePopup}
    >
      <StyledTitleContainer>
        <strong>Boundaries</strong>
      </StyledTitleContainer>
      <EditMapBoundsList shapes={shapes} renderBoundriesButtons={renderBoundriesButtons} />
    </StyledPopup>
  );
};

EditMapBound.propTypes = {
  renderBoundriesButtons: PropTypes.func,
};

export default EditMapBound;
