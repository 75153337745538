import styled from 'styled-components';

export const OptionContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const InputContainer = styled.div`
    width: 44%;
    margin-right: 17px;
`;
