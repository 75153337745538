import React from 'react';
import get from 'lodash/get';

import usePointEditMap from 'state/pointEditMap/usePointEditMap';
import useContextMenu from 'state/contextMenu/useContextMenu';

import { useModal } from 'utils';
import { reverseGeocoderApiLink } from 'utils/constants';

import EditDatasetsContextMenu from '.';


const fetchAddress = async ({ latitude, longitude }) => {
  const geocodeLocationsLink = `${reverseGeocoderApiLink}${latitude},${longitude}`;
  const locationsResponse = await fetch(geocodeLocationsLink);
  const locations = await locationsResponse.json();

  return get(locations, 'items[0].title', null);
};

const EditDatasetsContextMenuContainer = () => {
  const { selectedDataPoint, selectPointEditMap } = usePointEditMap();
  const { contextMenu, closeContextMenu } = useContextMenu();
  const { openModal, ...modalProps } = useModal();

  const lngLat = get(contextMenu, 'contextInfo.lngLat', [0, 0]);
  const [longitude, latitude] = lngLat;
  const isShowPopup = longitude !== 0;

  const handleContextMenuClick = async () => {
    const address = await fetchAddress({ latitude, longitude });

    selectPointEditMap({
      address,
      lat: latitude,
      long: longitude,
      coordinates: lngLat,
    });

    openModal();
    closeContextMenu();
  };

  return (
    <EditDatasetsContextMenu
      latitude={latitude}
      longitude={longitude}
      modalProps={modalProps}
      isShowPopup={isShowPopup}
      selectedDataPoint={selectedDataPoint}
      handleContextMenuClick={handleContextMenuClick}
    />
  );
};

export default React.memo(EditDatasetsContextMenuContainer);
