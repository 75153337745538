import styled from 'styled-components';


export const SubTtile = styled.p`
  color: #6c757d;
  font-size: 1.25rem;
`;

export const Description = styled.div`
  margin-bottom: 1rem;
`;
