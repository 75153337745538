import styled from 'styled-components';
import Typography from 'antd/lib/typography';

const { Title } = Typography;

export const StyledTitle = styled(Title)`
  margin-bottom: 0 !important;
`;

export const DescriptionText = styled.p`
  font-size: 16px;
  margin: 0;
  padding-bottom: .5rem;
`;

export const StyledPresentMapTitle = styled.div`
  margin: .5rem;
`;
