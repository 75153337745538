import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { datasetUpdate } from 'graphql/queries';
import { EditableText, EditPageTitle } from 'components';
import { useRouter } from 'utils';

import { StyledTitle } from './style';

const EditDatasetPageTitle = ({ title }) => {
  const { pageId } = useRouter();
  const [text, changeText] = useState(title);
  const { createVariables, CreateUpdateTitleMutation } = EditPageTitle;
  const { updateTitle, error } = CreateUpdateTitleMutation(datasetUpdate);

  useEffect(() => {
    if (error) {
      changeText(title);
    }
  }, [error, title]);

  return (
    <StyledTitle>
      <EditableText
        pageId={pageId}
        initialText={text}
        createVariables={createVariables}
        saveChanges={updateTitle}
        error={error}
      />
    </StyledTitle>
  );
};

EditDatasetPageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default EditDatasetPageTitle;
