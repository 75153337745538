import get from 'lodash/get';
import { transformToNumber } from 'utils';

const getMapDatasetIds = (formValues) => {
  const mapDatasetIdsString = get(formValues, 'mapDatasetIds', []);

  return transformToNumber(mapDatasetIdsString);
};

export const createMutationVariables = ({ formValues, pageId }) => {
  const mapId = Number(pageId);
  const displayName = get(formValues, 'name', '');
  const aggregateFunction = get(formValues, 'function', 'count').toUpperCase();
  const aggregateField = get(formValues, 'field', null);
  const mapDatasetIds = getMapDatasetIds(formValues);
  const showSubtotals = get(formValues, 'showSubtotals', false);
  const splitOn = get(formValues, 'splitOn', 'none').toUpperCase();

  return ({
    mapId,
    displayName,
    function: aggregateFunction,
    field: aggregateField,
    mapDatasetIds,
    showSubtotals,
    splitOn,
  });
};
