import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Upload from 'antd/lib/upload';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';

const UploadLocationsButton = ({ onCustomRequest, visible, enableImportButton }) => {
  const [list, changeList] = useState([]);

  const showLastUpload = ({ fileList }) => {
    const lastUploadIndex = fileList.length - 1;
    changeList([fileList[lastUploadIndex]]);
  };

  // clear imports list id modal closed
  useEffect(() => {
    if (!visible) {
      changeList([]);
      enableImportButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Upload
      customRequest={onCustomRequest}
      onChange={showLastUpload}
      fileList={list}
    >
      <Button>
        <Icon type="upload" />
        Click to upload
      </Button>
    </Upload>
  );
};

UploadLocationsButton.propTypes = {
  onCustomRequest: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default UploadLocationsButton;
