import styled from 'styled-components';
import Layout from 'antd/lib/layout';

const { Sider } = Layout;

const headerHeight = 64;

export const StyledSideBar = styled(Sider)`
  background: #fff;
  padding: 1rem 1rem 3rem 1rem;
  height: calc(100vh - ${headerHeight}px);
  overflow-x: hidden;
`;
