import { SHOW_SPINNER } from './types';

const spinnerReducer = (state = {}, { type, showSpinner }) => {
  switch (type) {
    case SHOW_SPINNER:
      return { ...state, showSpinner };
    default:
      return state;
  }
};

export default spinnerReducer;
