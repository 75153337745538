import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import { AggregateCardBody } from 'components';

import PresentMapDatasets from './PresentMapDatasets';
import PresentMapTitle from './PresentMapTitle';
import BackToEditButton from './BackToEditButton/container';

import { PresentMapDescriptionContainer, StyledAggregateCardContainer } from './styles';


const PresentMapInfo = ({
  name,
  description,
  closePresentMap,
  showTitleDescription,
  mapShapes,
  showLegend,
  aggregates,
  showInsights,
  mapDatasets,
}) => {
  const totals = get(aggregates, '[0].totals', []);
  const orderedAggregates = orderBy(aggregates, ['id'], ['asc']);

  return (
    <PresentMapDescriptionContainer>
      <BackToEditButton closePresentMap={closePresentMap} />
      {showTitleDescription && (
        <PresentMapTitle
          name={name}
          description={description}
          closePresentMap={closePresentMap}
        />
      )}
      {showLegend && <PresentMapDatasets mapDatasets={mapDatasets} />}
      <StyledAggregateCardContainer>
        {(!isEmpty(orderedAggregates) && showInsights) && orderedAggregates.map((item, index) => (
          <AggregateCardBody
            key={item.id}
            totals={totals}
            mapShapes={mapShapes}
            allMapDatasets={mapDatasets}
            aggregateInfo={orderedAggregates[index]}
            {...item}
          />
        ))}
      </StyledAggregateCardContainer>
    </PresentMapDescriptionContainer>
  );
};

PresentMapInfo.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  closePresentMap: PropTypes.func,
  showTitleDescription: PropTypes.bool,
  mapShapes: PropTypes.array,
  showLegend: PropTypes.bool,
  aggregates: PropTypes.array,
  showInsights: PropTypes.bool,
  mapDatasets: PropTypes.array,
};

export default PresentMapInfo;
