import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'antd/lib/typography';

import { idealTextColor } from 'utils';

import { PopupInfoContainer } from './style';

const { Text } = Typography;

const PopupInfo = ({ name, address, color }) => {
  const textColor = idealTextColor(color);

  return (
    <PopupInfoContainer textColor={textColor} color={color}>
      <Text strong>{name}</Text>
      <br />
      {address && <Text>{address}</Text>}
    </PopupInfoContainer>
  );
};

PopupInfo.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  color: PropTypes.string.isRequired,
};

export default PopupInfo;
