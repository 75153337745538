import SparkMD5 from 'spark-md5';

const base64OfMD5 = (data) => {
  const md5Buffer = SparkMD5.ArrayBuffer.hash(data, true);

  return btoa(md5Buffer);
};

const createFileChecksum = (file) => {
  let currentChunk = 0;
  const blobSlice = File.prototype.slice;
  // TODO: CHECK THIS
  const chunkSize = file.size;
  const fileSize = file.size;
  const MD5Buffer = new SparkMD5.ArrayBuffer();
  const fileReader = new FileReader();

  const chunks = Math.ceil(fileSize / chunkSize);

  const loadNextFileChunk = () => {
    const start = currentChunk * chunkSize;
    const sumOfChunks = start + chunkSize;
    const end = (sumOfChunks >= fileSize) ? fileSize : sumOfChunks;

    fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
  };

  const processFile = (fileBuffer) => {
    MD5Buffer.append(fileBuffer);
    currentChunk += 1;
    loadNextFileChunk();
  };

  return new Promise((resolve, reject) => {
    fileReader.onload = (progressEvent) => {
      const fileBuffer = progressEvent.target.result;
      const fileProcessed = currentChunk < chunks;

      if (!fileProcessed) {
        processFile(fileBuffer);
        return;
      }

      const encryptedFile = base64OfMD5(fileBuffer);

      resolve(encryptedFile);
    };

    fileReader.onerror = () => {
      reject(new Error('The file cannot be processed'));
    };

    loadNextFileChunk();
  });
};

const fetchUploadsUrl = async (file) => {
  const checksum = await createFileChecksum(file);
  const { name, size, type } = file;

  const body = {
    blob: {
      filename: name,
      content_type: type,
      byte_size: size,
      checksum,
    },
  };

  const uploadsUrl = await fetch('/api/v1/direct_uploads', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  return uploadsUrl.json();
};

const uploadFileToS3Bucket = async (file) => {
  const {
    signed_id,
    direct_upload: { url, headers },
  } = await fetchUploadsUrl(file);

  const uploadStatus = await fetch(url, {
    method: 'PUT',
    headers,
    body: file,
  });

  return {
    uploadStatus,
    signed_id,
  };
};

export default uploadFileToS3Bucket;
