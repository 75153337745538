import styled from 'styled-components';

const checkingColor = '#00c300';

export const StyledThumbnailTitle = styled.p`
  font-weight: 600;
  margin-top: 5px;
`;

export const StyledDescription = styled.div`
   p {
    margin-bottom: 5px;
   }
`;

export const StyledRadio = styled.input.attrs(({ visualisationType, selected, disabled }) => ({
  type: 'radio',
  name: 'VisualisationType',
  value: visualisationType,
  defaultChecked: selected,
  disabled,
}))`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  
  &:checked + div {
    position: relative;  
    outline: 2px solid ${checkingColor}; 
     
    &:after {
      content: "\\2713";
      position: absolute;
      top: 0;
      left: 10px;
      display: block;
      font-size: 35px;
      color: ${checkingColor};
    }
  }
`;

export const StyledRadioImage = styled.img`
  background: url('${props => props.imageSrc}') center center no-repeat;
  background-size: cover;
  width: 150px;
  height: 140px;
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};
`;

export const StyledRadioContainer = styled.div`
  float: left;
  margin-right: 15px;
`;
