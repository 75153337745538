const updateDownloadImageLink = ({ uri, fileName }) => () => {
  const downloadPreviewImageLink = document.getElementById('downloadPreviewImageLink');

  downloadPreviewImageLink.download = fileName;
  downloadPreviewImageLink.href = uri;
};

const createDownloadImageLink = ({ uri, fileName }) => () => {
  const downloadLink = document.createElement('a');
  downloadLink.setAttribute('id', 'downloadPreviewImageLink');

  downloadLink.download = fileName;
  downloadLink.href = uri;

  document.querySelector('body').appendChild(downloadLink);
};

export const DownloadImageLink = ({ blob, mapName, imageFormat }) => {
  const URLObj = window.URL || window.webkitURL;
  const uri = URLObj.createObjectURL(blob);
  const fileName = `${mapName}.${imageFormat}`;

  return {
    updateLink: updateDownloadImageLink({ uri, fileName }),
    createLink: createDownloadImageLink({ uri, fileName }),
  };
};
