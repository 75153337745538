import React from 'react';
import PropTypes from 'prop-types';
import Input from 'antd/lib/input';

import {
  LocationDataForm,
  AddressSearch,
  UpdateLocationButton,
  Label,
} from 'components';

const LocationForm = (props) => {
  const { values, handleChange } = props;
  const { name, description } = values;

  const submitOnEnter = ({ key }) => {
    const { handleSubmit } = props;
    const pressedEnter = key === 'Enter';

    if (pressedEnter) {
      handleSubmit();
    }
  };

  // TODO: find better solution for formik
  return (
    <form onKeyDown={submitOnEnter}>
      <Label colon={false} label="Name">
        <Input
          autoFocus
          name="name"
          size="large"
          onChange={handleChange}
          value={name}
        />
      </Label>
      <Label colon={false} label="Description">
        <Input
          name="description"
          size="large"
          value={description}
          onChange={handleChange}
        />
      </Label>
      <AddressSearch {...props} />
      <LocationDataForm handleChange={handleChange} {...props} />
      <UpdateLocationButton {...props} />
    </form>
  );
};

LocationForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default React.memo(LocationForm);
