import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'antd/lib/badge';

import { normalizeAggregatesCount } from '../../AggregatesNormalizer';
import { StyledSubtotal, StyledSubshapes } from './styles';

const SubShapes = ({ mapShapes }) => (
  mapShapes.map(({ id, name, value }) => (
    <StyledSubshapes key={id}>
      <span>{name}</span>
      <span>{normalizeAggregatesCount(value)}</span>
    </StyledSubshapes>
  ))
);

const MapDatasetSubTotals = ({
  mapShapes,
  mapDataset,
  showSubtotals,
  aggregatesCount,
}) => {
  const { name, color } = mapDataset;
  const count = normalizeAggregatesCount(aggregatesCount);

  return (
    <div>
      <StyledSubtotal>
        <Badge color={color} text={name} />
        <span>{count}</span>
      </StyledSubtotal>
      {showSubtotals && <SubShapes mapShapes={mapShapes} />}
    </div>
  );
};

MapDatasetSubTotals.propTypes = {
  mapShapes: PropTypes.array.isRequired,
  mapDataset: PropTypes.object.isRequired,
  showSubtotals: PropTypes.bool.isRequired,
  aggregatesCount: PropTypes.number.isRequired,
};

export default React.memo(MapDatasetSubTotals);
