import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';

import { destroyDatapointAction } from 'state/datapoint/actions';
import { datapointDestroy, fetchDataset } from 'graphql/queries';

const removePointFromMap = ({ data, dispatch }) => {
  const deletedDatapointId = get(data, 'data.datapointDestroy.deletedId', null);

  if (deletedDatapointId) {
    const deletedDatapoint = ({
      deletedDatapointId,
      datapointUpdate: null,
      datapointCreate: null,
    });

    dispatch(destroyDatapointAction(deletedDatapoint));
  }
};

const PopupRemoveService = (pageId, selectedDataPoint) => {
  const { datapointId } = selectedDataPoint;

  const dispatch = useDispatch();

  const [destroyDatapoint] = useMutation(datapointDestroy, {
    variables: {
      input: {
        id: datapointId,
      },
    },
    refetchQueries: [{ query: fetchDataset, variables: { id: pageId } }],
    update: (proxy, data) => {
      removePointFromMap({ dispatch, data });
    },
  });

  return ({ destroyDatapoint });
};

export default PopupRemoveService;
