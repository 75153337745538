import React from 'react';

import SkeletonCards from './SkeletonCards';

const Loader = ({
  loading,
  children,
}) => (loading && <SkeletonCards />) || children;

export default Loader;
