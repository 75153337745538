import React from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';
import find from 'lodash/find';

import { StyledBoundButtons } from './styles';

const BoundButtons = ({
  id,
  name,
  handleAddBounds,
  handleRemoveBounds,
  mapShapes,
}) => {
  const isBoundSelected = find(mapShapes, ({ shape }) => shape.id === id);

  return (
    <StyledBoundButtons>
      {!isBoundSelected
        ? <Button onClick={() => handleAddBounds(id, name)} size="small">Add</Button>
        : <Button onClick={() => handleRemoveBounds(id, name)} type="danger" size="small">Remove</Button>
      }
    </StyledBoundButtons>
  );
};

BoundButtons.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleAddBounds: PropTypes.func.isRequired,
  handleRemoveBounds: PropTypes.func.isRequired,
  mapShapes: PropTypes.array.isRequired,
};

export default BoundButtons;
