import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { SelectDatasets, MapDataset } from 'components';

const RenderEditMapDatasets = ({ mapDatasets, openModal, ...props }) => (
  !isEmpty(mapDatasets)
    ? <MapDataset mapDatasets={mapDatasets} {...props} />
    : <SelectDatasets openModal={openModal} {...props} />
);

RenderEditMapDatasets.propTypes = {
  mapDatasets: PropTypes.array,
  modalProps: PropTypes.object,
};

RenderEditMapDatasets.defaultProps = {
  mapDatasets: [],
};

export default RenderEditMapDatasets;
