import styled from 'styled-components';

export const ClusterLocationsContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
`;

export const ClusterLocationsDescription = styled.div`
  margin-top: -4px;
  margin-left: 12px;
`;
