import styled from 'styled-components';
import Button from 'antd/lib/button';

export const DatapointName = styled.div`
  font-weight: 600;
  margin-bottom: 1rem;
  max-width: 180px;
  word-wrap: break-word;
`;

export const DatapointAddress = styled.div``;

export const StyledPanelWrapper = styled.div`
  position: relative;
`;

export const NotFoundDataPointContainer = styled.div`
  & > div:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
`;

export const StyledButtonsContainer = styled.div`
  position: absolute;
  right: 0;
  top: -5px;
`;

export const RemoveImportButton = styled(Button)`
  position: absolute;
  top: 5px;
  right: 0;
`;
