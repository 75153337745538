import { useDispatch } from 'react-redux';

import { addBoundariesAction, removeBoundariesAction } from 'state/mapBoundaries/actions';

const UseBoundsDispatch = () => {
  const dispatch = useDispatch();

  const addBoundaries = data => dispatch(addBoundariesAction(data));
  const removeBoundaries = data => dispatch(removeBoundariesAction(data));

  return [addBoundaries, removeBoundaries];
};

export default UseBoundsDispatch;
