const createMapSize = (exportSettings, path) => `${exportSettings[path]}px`;

// TODO: USE FROM PREVIEW IMAGE
const calculatePercentageDiff = (size, maxSize) => {
  const diff = size - maxSize;

  return diff / size;
};

const getMapSize = (exportSettings, presentMapEnabled) => {
  if (presentMapEnabled) {
    const { offsetWidth, offsetHeight } = document.getElementById('root');
    const presentMapWidth = createMapSize(exportSettings, 'presentMapWidth');
    const presentMapHeight = createMapSize(exportSettings, 'presentMapHeight');
    let width = parseInt(presentMapWidth, 10) || offsetWidth;
    let height = parseInt(presentMapHeight, 10) || offsetHeight;


    if (width > offsetWidth) {
      const percentageDiff = calculatePercentageDiff(width, offsetWidth);

      height -= (height * percentageDiff);
      width = offsetWidth;
    }

    if (height > offsetHeight) {
      const percentageDiff = calculatePercentageDiff(height, offsetHeight);

      width -= (width * percentageDiff);
      height = offsetHeight;
    }

    return { width, height };
  }

  return { width: '100%', height: '100%' };
};

export default getMapSize;
