import React from 'react';
import PropTypes from 'prop-types';
import Select from 'antd/lib/select';

import { Label } from 'components';

import { StyledInputWrapper } from '../styles';

const { Option } = Select;

const PixelDensitySelect = ({ setFieldValue, changeExportSettings, values }) => {
  const { pixelDensity } = values;
  const changePixelDensitySelect = (pixelDensitySelectValue) => {
    setFieldValue('pixelDensity', pixelDensitySelectValue);

    changeExportSettings({
      pixelDensity: pixelDensitySelectValue,
    });
  };

  return (
    <StyledInputWrapper>
      <Label colon={false} label="Pixel density">
        <Select
          name="pixelDensity"
          value={pixelDensity}
          onChange={changePixelDensitySelect}
        >
          <Option value="150">150 ppi</Option>
          <Option value="300">300 ppi (print quality)</Option>
        </Select>
      </Label>
    </StyledInputWrapper>
  );
};

PixelDensitySelect.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  changeExportSettings: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default PixelDensitySelect;
