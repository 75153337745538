import { gql } from 'apollo-boost';

export const mapDatasetDestroy = gql`
    mutation mapDatasetDestroy($input: MapDatasetDestroyMutationInput!) {
        mapDatasetDestroy(input: $input) {
            deletedId
        }
    }
`;

export const mapDatasetCreate = gql`
    mutation mapDatasetCreate($input: MapDatasetCreateMutationInput!) {
        mapDatasetCreate(input: $input) {
            clientMutationId
            mapDataset {
                id
            }
        }
    }
`;

export const mapDatasetUpdate = gql`
    mutation mapDatasetUpdate($input: MapDatasetUpdateMutationInput!) {
        mapDatasetUpdate(input: $input) {
            clientMutationId
            mapDataset {
                id
            }
        }
    }
`;
