import { SELECT_EDIT_MAP_POINT } from './types';

const selectPointEditMap = (state = {}, { type, selectedDataPoint }) => {
  switch (type) {
    case SELECT_EDIT_MAP_POINT:
      return { ...state, selectedDataPoint };
    default:
      return {};
  }
};

export default selectPointEditMap;
