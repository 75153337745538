import styled from 'styled-components';
import List from 'antd/lib/list';

export const ListItem = styled(List.Item)`
  justify-content: space-between;
  padding-left: 16px !important;
  padding-right: 10px !important;
`;

export const LocationContainer = styled.div`
 display: flex;
 width: max-content;
 align-items: center;
 margin-top: 5px;
 height: 30px;
 
 & > div {
  position: absolute;
  background-color: #f8f9fa;
  padding: 0 5px;
 }
`;
