import React, { Fragment } from 'react';

import { AddLocationModal, OpenAddLocationModal } from 'components';
import { useModal } from 'utils';


const AddLocation = (props) => {
  const { openModal, ...modalProps } = useModal();

  return (
    <Fragment>
      <OpenAddLocationModal openModal={openModal} />
      <AddLocationModal {...modalProps} {...props} />
    </Fragment>
  );
};

export default React.memo(AddLocation);
