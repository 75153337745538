import styled from 'styled-components';
import card from 'antd/lib/card';

const sectionBorders = '1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #fff inset, 0 1px 0 0 #e8e8e8 inset';

// TODO: remove style duplication because we describe a Section style in different files
export const Section = styled(card.Grid)`
  width: 100%;
  box-shadow: ${sectionBorders};
  
  &:hover {
    box-shadow: ${sectionBorders};
  }
  
  & > div:not(:last-child) {
    padding-bottom: 0.3rem;
    border-bottom: 1px solid #e8e8e8;
  }
  
  & > div:not(:first-child) {
    padding-top: 0.3rem;
  }
`;
