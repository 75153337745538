import { useMutation } from '@apollo/react-hooks';

import {
  mapShapeDestroy,
  mapShapeCreate,
  fetchMap,
  mapShapeGroupDestroy,
  mapShapeGroupCreate,
  mapShapesSet,
} from 'graphql/queries';

const BoundariesSelectService = (pageId) => {
  const refetchQueries = [{ query: fetchMap, variables: { id: pageId } }];

  const AddBounds = () => {
    const [addShape] = useMutation(mapShapeCreate, { refetchQueries });

    const createBoundariesMutation = (id) => {
      addShape({
        variables: {
          input: {
            mapId: Number(pageId),
            shapeId: Number(id),
          },
        },
      });
    };

    return (id) => {
      if (id) {
        createBoundariesMutation(id);
      }
    };
  };
  const RemoveBounds = () => {
    const [destroyShape] = useMutation(mapShapeDestroy, { refetchQueries });

    const removeBoundariesMutation = (id) => {
      destroyShape({
        variables: {
          input: {
            mapId: pageId,
            shapeId: id,
          },
        },
      });
    };

    return (options) => {
      if (options) {
        removeBoundariesMutation(options);
      }
    };
  };

  const AddShapeGroup = () => {
    const [createShapeGroup] = useMutation(mapShapeGroupCreate, { refetchQueries });

    const createBoundariesGroupMutation = (id) => {
      createShapeGroup({
        variables: {
          input: {
            mapId: pageId,
            shapeGroupId: id,
          },
        },
      });
    };

    return (options) => {
      if (options) {
        createBoundariesGroupMutation(options);
      }
    };
  };
  const RemoveShapeGroup = () => {
    const [destroyShapeGroup] = useMutation(mapShapeGroupDestroy, { refetchQueries });

    const removeBoundariesGroupMutation = (id) => {
      destroyShapeGroup({
        variables: {
          input: {
            mapId: pageId,
            shapeGroupId: id,
          },
        },
      });
    };

    return (options) => {
      if (options) {
        removeBoundariesGroupMutation(options);
      }
    };
  };

  const MapShapesSet = () => {
    const [setShapesIds] = useMutation(mapShapesSet, { refetchQueries });

    const createBoundariesGroupMutation = (ids) => {
      setShapesIds({
        variables: {
          input: {
            mapId: Number(pageId),
            shapeIds: ids,
          },
        },
      });
    };

    return (options) => {
      if (options) {
        createBoundariesGroupMutation(options);
      }
    };
  };

  return {
    AddBounds,
    RemoveBounds,
    AddShapeGroup,
    RemoveShapeGroup,
    MapShapesSet,
  };
};

export default BoundariesSelectService;
