import React from 'react';
import isEmpty from 'lodash/isEmpty';

const SuggestedStatistics = ({ statisticsFields }) => (
  !isEmpty(statisticsFields) && (
    <datalist id="statistics">
      {statisticsFields.map(item => <option key={item} value={item} />)}
    </datalist>
  )
);

export default SuggestedStatistics;
