import React from 'react';
import { NavigationControl } from 'react-map-gl';

import NavigationControlContainer from './style';

const MapControl = () => (
  <NavigationControlContainer data-html2canvas-ignore>
    <NavigationControl />
  </NavigationControlContainer>
);

export default MapControl;
