import { useMutation } from '@apollo/react-hooks';

import { importDestroy, fetchDataset } from 'graphql/queries';

const BrokenImportedFileService = (id, pageId) => {
  const [destroyImport] = useMutation(importDestroy, {
    variables: {
      input: {
        id: Number(id),
      },
    },
    refetchQueries: [{ query: fetchDataset, variables: { id: pageId } }],
  });

  return ({ destroyImport });
};

export default BrokenImportedFileService;
