import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectPointEditMap } from 'state/pointEditMap/actions';
import { useRouter } from 'utils';

import NotFoundDataPointModal from './NotFoundDataPointModal';

import { DatapointAddress, DatapointName, StyledPanelWrapper } from '../style';

const NotFoundDataPoint = ({ notFoundDatapoint, ...props }) => {
  const { pageId } = useRouter();
  const { name, address } = notFoundDatapoint;

  return (
    <StyledPanelWrapper>
      <DatapointName>{name}</DatapointName>
      <NotFoundDataPointModal
        pageId={pageId}
        notFoundDatapoint={notFoundDatapoint}
        {...props}
      />
      <DatapointAddress>{address}</DatapointAddress>
    </StyledPanelWrapper>
  );
};

NotFoundDataPoint.propTypes = {
  id: PropTypes.string.isRequired,
  notFoundDatapoint: PropTypes.object.isRequired,
  selectPointEditMap: PropTypes.func.isRequired,
};

const mapDispatchToProps = { selectPointEditMap };

export default connect(null, mapDispatchToProps)(NotFoundDataPoint);
