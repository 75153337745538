import { defaultDataIdFromObject } from 'apollo-cache-inmemory';

/*
providing custom keys for the apollo cache
to solve a problem with data duplication in aggregates subtotals

https://www.apollographql.com/docs/react/caching/cache-configuration/#custom-identifiers
*/
export const customAggregateSubtotalsKeysInCache = ({
  dataIdFromObject: (object) => {
    const { __typename, key } = object;
    const areAggregatesTotalsTypes = __typename === 'AggregateSplitOnSubtotals'
      || __typename === 'AggregateSplitOnTotals';

    if (areAggregatesTotalsTypes) {
      return key;
    }

    return defaultDataIdFromObject(object);
  },
});
