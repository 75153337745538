import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'antd/lib/switch';

import {
  ClusterLocationsContainer,
  ClusterLocationsDescription,
} from './styles';

const ClusterLocations = ({ switchClusterLocation, clustered }) => {
  const onChange = e => switchClusterLocation(e);

  return (
    <ClusterLocationsContainer>
      <Switch size="small" onChange={onChange} defaultChecked={clustered} />
      <ClusterLocationsDescription>
        <h3>Cluster locations</h3>
        <p>
          If you have many locations in your dataset,
          clustering can make your map more readable.
        </p>
      </ClusterLocationsDescription>
    </ClusterLocationsContainer>
  );
};

ClusterLocations.propTypes = {
  switchClusterLocation: PropTypes.func.isRequired,
  clustered: PropTypes.bool,
};

export default ClusterLocations;
