import { gql } from 'apollo-boost';

export const createMap = gql`
    mutation mapCreate($input: MapCreateMutationInput!) {
        mapCreate(input: $input) {
            map {
                id
                name
            }
        }
    }
`;

export const mapUpdate = gql`
    mutation mapUpdate($input: MapUpdateMutationInput!) {
        mapUpdate(input: $input) {
            map {
                id
                name
                description
                basemap
                visualizationType
                shapeBorderColor
            }
        }
    }
`;

export const destroyMap = gql`
    mutation mapDestroy($input: MapDestroyMutationInput!) {
        mapDestroy(input: $input) {
            deletedId
        }
    }
`;
