import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { ColorPicker } from 'components';

const ShapeColor = ({ initialColor, pickColor }) => (
  <Fragment>
    <h3>Boundary Colour</h3>
    <ColorPicker initialColor={initialColor} pickColor={pickColor} />
  </Fragment>
);

ShapeColor.propTypes = {
  pickColor: PropTypes.func.isRequired,
  initialColor: PropTypes.string.isRequired,
};

export default React.memo(ShapeColor);
