import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Gradient } from './styles';

const HeatmapGradient = ({ gradient, showMidpoint }) => {
  if (isEmpty(gradient)) {
    return null;
  }

  const { lowestValue, midpointValue, highestValue } = gradient;

  return (
    <>
      <h3>Colour Gradient</h3>
      <Gradient
        showMidpoint={showMidpoint}
        lowestValue={lowestValue}
        midpointValue={midpointValue || '#fff'}
        highestValue={highestValue}
      />
    </>
  );
};

HeatmapGradient.propTypes = {
  showMidpoint: PropTypes.bool.isRequired,
  gradient: PropTypes.shape({
    lowestValue: PropTypes.string,
    midpointValue: PropTypes.string,
    highestValue: PropTypes.string,
  }).isRequired,
};

export default React.memo(HeatmapGradient);
