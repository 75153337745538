import { OPEN_CONTEXT_MENU, CLOSE_CONTEXT_MENU } from './types';

const contextMenuReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case OPEN_CONTEXT_MENU:
      return { ...state, ...payload };
    case CLOSE_CONTEXT_MENU:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default contextMenuReducer;
