import get from 'lodash/get';

export const createMutationVariables = ({
  clustered,
  color,
  formValues,
  pageId,
  id,
  type,
}) => {
  const mapId = Number(pageId);
  const displayName = get(formValues, 'displayName', '');
  const datasetId = get(formValues, 'datasetId', null);
  const mapDatasetUpdateId = (id && type === 'update') ? ({ id: Number(id) }) : ({});

  return ({
    variables: {
      input: {
        mapId,
        color,
        datasetId: Number(datasetId),
        displayName,
        clustered,
        ...mapDatasetUpdateId,
      },
    },
  });
};

export const validateDatasetsForm = ({ variables }) => {
  const datasetId = get(variables, 'input.datasetId', false);
  const isDatasetNotExist = datasetId === 0;

  if (isDatasetNotExist) {
    return ({ isValid: false, validationError: 'Choose dataset' });
  }

  return ({ isValid: true, validationError: false });
};
