import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { AddressSuggestions, PreviewLocations } from 'components';

import PreviewMap from '.';

const getDatapointCoordinates = (selectedDataPoint) => {
  if (isEmpty(selectedDataPoint.coordinates)) {
    return undefined;
  }

  return {
    longitude: selectedDataPoint.coordinates[0],
    latitude: selectedDataPoint.coordinates[1],
  };
};

const PreviewMapContainer = ({
  getAddress,
  getLocations,
  selectedDataPoint,
  addressCoordinates,
  updateAddressInput,
}) => {
  const initialCoordinates = getDatapointCoordinates(selectedDataPoint);
  const [locations, changeLocations] = useState(initialCoordinates);
  const [marker, changeMarker] = useState(initialCoordinates);

  const onMarkerChange = async (location) => {
    changeMarker(location);

    getLocations(location);
  };

  const updatePreviewMap = (eventLocations) => {
    changeLocations(eventLocations);

    getLocations(eventLocations);
  };

  useEffect(() => {
    if (!isEmpty(addressCoordinates)) {
      changeLocations(addressCoordinates);
      changeMarker(addressCoordinates);
    } else if (!isEmpty(selectedDataPoint)) {
      const { long, lat } = selectedDataPoint;
      getLocations({ longitude: long, latitude: lat });
    }

    // eslint-disable-next-line
  }, [addressCoordinates]);

  return (
    <Fragment>
      <AddressSuggestions
        locations={marker}
        getAddress={getAddress}
        changeAddress={updateAddressInput}
      />
      <PreviewLocations {...marker} />
      <PreviewMap
        locations={locations}
        marker={marker}
        onMarkerChange={onMarkerChange}
        changeLocations={changeLocations}
        getAddress={getAddress}
        updatePreviewMap={updatePreviewMap}
      />
    </Fragment>
  );
};

PreviewMapContainer.propTypes = {
  selectedDataPoint: PropTypes.object,
  addressCoordinates: PropTypes.object,
  getAddress: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  updateAddressInput: PropTypes.func.isRequired,
};

PreviewMapContainer.defaultProps = {
  selectedDataPoint: {
    longitude: 0,
    latitude: 0,
  },
};

export default PreviewMapContainer;
