import styled from 'styled-components';

export const PopupContainer = styled.div`
  .mapboxgl-popup-content {
    padding: 0;
  }
  
  .mapboxgl-popup {
    max-width: 350px;
    cursor: auto;
    touch-action: auto;
    user-select: text;
  }
`;
