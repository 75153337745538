import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

import { ImportLocationProgressStatusSelector } from 'state/progressImportLocations/selectors';
import { useRouter } from 'utils';

import { CreateImportsChannelSubscriptions } from '../ImportsChannel';
import ImportedFileCards from './ImportedFileCards';

const isPageHasImport = pageId => localStorage.getItem(`${pageId}`);

const subscribeToActiveImportFileChannel = (imports, dispatch) => {
  const activeImport = imports.filter((item) => {
    const { id, fileName, percentComplete } = item;

    if (percentComplete !== 100) {
      CreateImportsChannelSubscriptions(id, fileName, dispatch);
      return item;
    }

    return false;
  });

  return activeImport;
};

const ImportedFileCardsContainer = (props) => {
  const { imports } = props;
  const { pageId } = useRouter();
  const dispatch = useDispatch();
  const importInProgress = useSelector(state => ImportLocationProgressStatusSelector(state));

  const hasActiveImport = find(imports, ({ percentComplete }) => percentComplete < 100);

  if (hasActiveImport && !isEmpty(isPageHasImport(pageId))) {
    subscribeToActiveImportFileChannel(imports, dispatch, pageId);
  }

  return <ImportedFileCards pageId={pageId} {...importInProgress} {...props} />;
};

ImportedFileCardsContainer.propTypes = {
  imports: PropTypes.array.isRequired,
  percent: PropTypes.number,
  fileName: PropTypes.string,
};

export default ImportedFileCardsContainer;
