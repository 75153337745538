import styled from 'styled-components';
import Button from 'antd/lib/button';

export const StyledProgressBarWrapper = styled.div`
  padding: 1rem 2rem 1rem 1rem;
  background: #fafafa;
`;

export const StyledButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 10px;
`;
