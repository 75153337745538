import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import useSelectBounds from 'state/selectBounds/useSelectBounds';
import ContextMenu from 'components/shared/ContextMenu';
import showBoundariesPopup from 'components/shared/Map/showBoundariesPopup';


const EditMapBoundContextMenu = ({
  selectedBoundPopupInfo,
  canOpenContextMenu,
  closeContextMenu,
}) => {
  const { selectBound } = useSelectBounds();

  if (isEmpty(selectedBoundPopupInfo)) {
    return null;
  }

  const handleContextMenuClick = () => {
    showBoundariesPopup(selectedBoundPopupInfo, selectBound);
    closeContextMenu();
  };

  const { lngLat } = selectedBoundPopupInfo;
  const [longitude, latitude] = lngLat;

  return canOpenContextMenu ? (
    <ContextMenu
      latitude={latitude}
      longitude={longitude}
      buttonText="Show boundaries"
      handleOnCloseClick={handleContextMenuClick}
    />
  ) : null;
};

EditMapBoundContextMenu.propTypes = {
  canOpenContextMenu: PropTypes.bool,
  closeContextMenu: PropTypes.func,
  selectedBoundPopupInfo: PropTypes.object,
};

export default EditMapBoundContextMenu;
