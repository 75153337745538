import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Modal from 'antd/lib/modal';

import { showInfoNotification } from 'components';
import { createLink } from 'utils';

import Card from '.';
import CardService from './service';

const { confirm } = Modal;

const showConfirm = ({
  showNotification,
  runMutation,
  data,
  props,
}) => {
  const { name } = props;

  confirm({
    title: `Are you sure you want to delete ${name}?`,
    okButtonProps: { id: 'confirm-card-delete' },
    onOk() {
      runMutation();
      showNotification(data, props);
    },
  });
};

const showNotification = ({ data }) => {
  const deletedMapId = get(data, 'mapDestroy.deletedId', false);

  if (deletedMapId) {
    showInfoNotification(`Deleted the map with id ${deletedMapId}`);
  }
};

const getCardLocations = (datapoints) => {
  if (!datapoints) {
    return null;
  }

  const { totalCount } = datapoints;

  if (totalCount > 0) {
    return `${totalCount} Locations`;
  }

  return 'Empty';
};

const CardContainer = (props) => {
  const {
    id,
    __typename,
    description,
    datapoints,
  } = props;

  const { runMutation, data } = CardService(id, __typename);

  const deleteCard = () => showConfirm({
    showNotification,
    runMutation,
    data,
    props,
  });

  const editLink = createLink('edit', id);
  // TODO: create separate props for locations
  const cardDescription = getCardLocations(datapoints) || description;

  return (
    <Card
      editLink={editLink}
      deleteCard={deleteCard}
      cardDescription={cardDescription}
      {...props}
    />
  );
};

CardContainer.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  mapShapes: PropTypes.array,
  description: PropTypes.string,
  datapoints: PropTypes.object,
  __typename: PropTypes.string.isRequired,
};

CardContainer.defaultProps = {
  mapShapes: [],
  datapoints: null,
  description: '',
};

export default CardContainer;
