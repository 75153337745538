import React from 'react';
import PropTypes from 'prop-types';

import { Card } from 'components';

import { FlexRow } from './style';

const RenderCards = ({ cards }) => cards.map(props => <Card key={props.id} {...props} />);

const Cards = ({ cards }) => cards && (
  <FlexRow id="cards" gutter={30}>
    <RenderCards cards={cards} />
  </FlexRow>
);

Cards.propTypes = {
  cards: PropTypes.array.isRequired,
};

export default Cards;
