import { fromJS } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

import { boundariesLayer, createBoundaries } from './layers/boundaries';
import { clustersLayer, clustersCountLayer } from './layers/clusters';


export const datapointsLayer = (color, index, source) => ({
  id: `point${color}${index}`,
  source,
  type: 'circle',
  paint: {
    'circle-radius': 5,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#fff',
    'circle-color': color,
  },
});

export const setMapDatasets = (mapStyle, data, color) => {
  const defaultMapStyle = fromJS(mapStyle);

  return defaultMapStyle
    .setIn(['sources', 'datasets'], fromJS({ data, type: 'geojson' }))
    .set('layers', defaultMapStyle.get('layers')
      .push(datapointsLayer(color, 1, 'datasets'))
      .push(clustersLayer(color, 1, 'datasets'))
      .push(clustersCountLayer(color, 1, 'datasets')));
};

export const extendDefaultMapWith = (
  defaultMapStyle,
  dataMap,
  { lineColor, visualizationType },
) => {
  const defaultMapStyleClone = cloneDeep(defaultMapStyle);

  const [mapDatasets, boundariesShapes] = dataMap;

  const mapPoints = mapDatasets.map(datapoint => datapoint.datapointsGeojson);

  if (!isEmpty(boundariesShapes)) {
    const boundaries = createBoundaries(boundariesShapes);

    defaultMapStyleClone.sources.boundaries = {
      data: boundaries,
      type: 'geojson',
    };

    defaultMapStyleClone.layers.push(boundariesLayer(lineColor));
  }

  const extendedMapStyles = mapPoints.reduce((mapStylesAccumulator, datapoints, index) => {
    const { color, clustered } = mapDatasets[index];
    const source = `datapoints-${index}`;

    /* eslint no-param-reassign: "error" */
    mapStylesAccumulator.sources[source] = {
      data: datapoints,
      type: 'geojson',
      cluster: clustered,
      clusterMaxZoom: 14,
    };

    if (visualizationType !== 'HEATMAP') {
      mapStylesAccumulator.layers.push(datapointsLayer(color, index, source));
      mapStylesAccumulator.layers.push(clustersLayer(color, index, source));
      mapStylesAccumulator.layers.push(clustersCountLayer(color, index, source));
    }

    return mapStylesAccumulator;
  }, defaultMapStyleClone);

  return fromJS(extendedMapStyles);
};

// eslint-disable-next-line
export const setEditMapData = async (mapStyle, data, options) => extendDefaultMapWith(mapStyle, data, options);
