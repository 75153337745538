import React from 'react';

import { StyledBoundItem, StyledBoundName, StyledBoundState } from './styles';

const EditMapBoundsList = ({
  shapes,
  handleAddBounds,
  handleRemoveBounds,
  renderBoundriesButtons,
}) => (
  shapes.map(({ id, name, shapeGroup }) => (
    <StyledBoundItem key={id}>
      <StyledBoundName>{name}</StyledBoundName>
      <StyledBoundState>{shapeGroup.name}</StyledBoundState>
      {renderBoundriesButtons && (
        renderBoundriesButtons({
          id, name, handleAddBounds, handleRemoveBounds,
        })
      )}
    </StyledBoundItem>
  ))
);

export default EditMapBoundsList;
