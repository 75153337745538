import React from 'react';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Skeleton from 'antd/lib/skeleton';
import Card from 'antd/lib/card';

import { generateUniqKey } from 'utils';

const emptySkeletonCards = [...new Array(4)];

const SkeletonCards = () => (
  <Row gutter={30}>
    {emptySkeletonCards.map((data, index) => (
      <Col key={generateUniqKey(index)} span={6}>
        <Card>
          <Skeleton active />
        </Card>
      </Col>
    ))}
  </Row>
);

export default SkeletonCards;
