import get from 'lodash/get';
import { showErrorNotification } from 'components/shared/Notification';

const showErrorMessage = (error) => {
  const errorMessage = get(error, 'message', false);

  showErrorNotification(errorMessage);
};

export default showErrorMessage;
