import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'antd/lib/switch';

import { Label } from 'components';

import { StyledSwitch } from '../styles';

const TitleDescriptionSwitch = ({ changeExportSettings }) => {
  const handleTitleDescriptionSwitch = (show) => {
    changeExportSettings({
      showTitleDescription: show,
    });
  };

  return (
    <StyledSwitch>
      <Label colon={false} label="Title and description">
        <Switch defaultChecked size="small" onChange={handleTitleDescriptionSwitch} />
      </Label>
    </StyledSwitch>
  );
};

TitleDescriptionSwitch.propTypes = {
  changeExportSettings: PropTypes.func.isRequired,
};

export default TitleDescriptionSwitch;
