import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import List from 'antd/lib/list';
import isEmpty from 'lodash/isEmpty';

import { SuggestionsList } from 'components';

const AddressSuggestions = ({
  changeAddress,
  getAddress,
  suggestions,
}) => !isEmpty(suggestions) && (
  <Fragment>
    <h4>Nearest suggestions</h4>
    <List
      dataSource={suggestions}
      renderItem={item => (
        <SuggestionsList
          getAddress={getAddress}
          changeAddress={changeAddress}
          address={item}
        />
      )}
    />
  </Fragment>
);

AddressSuggestions.propTypes = {
  suggestions: PropTypes.array,
  changeAddress: PropTypes.func.isRequired,
};

AddressSuggestions.defaultProps = {
  suggestions: [],
};

export default AddressSuggestions;
