import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useRouter } from 'utils';
import { mapUpdate } from 'graphql/queries';
import { EditableText, EditPageTitle } from 'components';

import { StyledTitle } from './styles';


const EditMapPageTitle = ({ title }) => {
  const { pageId } = useRouter();
  const [text, changeText] = useState(title);

  const { createVariables, CreateUpdateTitleMutation } = EditPageTitle;
  const { updateTitle, error } = CreateUpdateTitleMutation(mapUpdate);

  useEffect(() => {
    if (error) {
      changeText(title);
    }
  }, [error, title]);

  return (
    <StyledTitle id="editable-title">
      <EditableText
        pageId={pageId}
        initialText={text}
        createVariables={createVariables}
        saveChanges={updateTitle}
      />
    </StyledTitle>
  );
};

EditMapPageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default React.memo(EditMapPageTitle);
