import {
  CHANGE_LOWEST_COLOR,
  CHANGE_MIDPOINT_COLOR,
  CHANGE_HIGHEST_COLOR,
  FETCH_INITIAL_COLORS,
} from './types';

const initialState = {
  lowestValue: 'rgba(33,102,172,0)',
  midpointValue: 'white',
  highestValue: 'blue',
};

const heatmapSettings = (state = initialState, { type, color }) => {
  switch (type) {
    case CHANGE_LOWEST_COLOR:
      return { ...state, lowestValue: color };
    case CHANGE_MIDPOINT_COLOR:
      return { ...state, midpointValue: color };
    case CHANGE_HIGHEST_COLOR:
      return { ...state, highestValue: color };
    case FETCH_INITIAL_COLORS:
      return { ...state, ...color };
    default:
      return state;
  }
};

export default heatmapSettings;
