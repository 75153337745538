import ApolloClient from 'apollo-boost';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { fetchBounds } from 'graphql/queries';

const client = new ApolloClient();
const fetchBoundaries = async (lngLat) => {
  const [longitude, latitude] = lngLat.map(coordinate => String(coordinate));

  const { data } = await client.query({
    query: fetchBounds,
    variables: {
      overlappingLat: latitude,
      overlappingLon: longitude,
    },
  });

  return data;
};

const showBoundariesPopup = async ({ preventDefault, lngLat }, selectBound) => {
  preventDefault();

  const boundaries = await fetchBoundaries(lngLat);
  const shapes = get(boundaries, 'shapes', []);

  if (!isEmpty(shapes)) {
    selectBound({ shapes, lngLat });

    return true;
  }

  // close a BoundariesPopup on outside click
  selectBound({});

  return false;
};

export default showBoundariesPopup;
