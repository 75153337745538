import { gql } from 'apollo-boost';

export const mapShapeDestroy = gql`
    mutation mapShapeDestroy($input: MapShapeDestroyMutationInput!) {
        mapShapeDestroy(input: $input) {
            clientMutationId
        }
    }
`;

export const mapShapeCreate = gql`
    mutation mapShapeCreate($input: MapShapeCreateMutationInput!) {
        mapShapeCreate(input: $input) {
            clientMutationId
        }
    }
`;

export const mapShapeGroupDestroy = gql`
    mutation mapShapeGroupDestroy($input: MapShapeGroupDestroyMutationInput!) {
        mapShapeGroupDestroy(input: $input) {
            clientMutationId
        }
    }
`;

export const mapShapeGroupCreate = gql`
    mutation mapShapeGroupCreate($input: MapShapeGroupCreateMutationInput!) {
        mapShapeGroupCreate(input: $input) {
            clientMutationId
        }
    }
`;
