import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { MAP_DEFAULT_COLOR } from 'utils/constants';

import EditMapPopup from '.';


const EditMapPopupContainer = ({ color, showEditButtons }) => {
  const { selectedDataPoint } = useSelector(({ selectPointEditMap }) => selectPointEditMap);

  // don't show popup on edit location event
  const showPopup = get(selectedDataPoint, 'showPopup', true);
  const colorBg = get(selectedDataPoint, 'color', color);

  const shouldShowPopup = (
    showPopup !== false
    && !isEmpty(selectedDataPoint)
    && selectedDataPoint.name
  );

  return shouldShowPopup ? (
    <EditMapPopup
      color={colorBg}
      selectedDataPoint={selectedDataPoint}
      showEditButtons={showEditButtons}
    />
  ) : null;
};

EditMapPopupContainer.propTypes = {
  color: PropTypes.string,
  showEditButtons: PropTypes.bool.isRequired,
};

EditMapPopup.defaultProps = {
  color: MAP_DEFAULT_COLOR,
};

export default EditMapPopupContainer;
