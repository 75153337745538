import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { changeExportSettings } from 'state/exportSettings/actions';

import ImageSize from '.';

const ImageSizeContainer = ({ changeExportSettings }) => {
  const handleRadioChanges = ({ target }) => {
    changeExportSettings({ presentMapUnits: target.value });
  };

  return <ImageSize handleRadioChanges={handleRadioChanges} />;
};

const mapDispatchToProps = {
  changeExportSettings,
};

ImageSizeContainer.propTypes = {
  changeExportSettings: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(ImageSizeContainer);
