import React from 'react';

import { fetchDatasets } from 'graphql/queries';
import { MainPage } from 'components';

const Datasets = () => (
  <MainPage
    title="Datasets"
    subtitle="Collections of locations."
    buttonText="New Dataset"
    query={fetchDatasets}
    type="Dataset"
  />
);

export default Datasets;
