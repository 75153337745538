import styled from 'styled-components';
import Typography from 'antd/lib/typography';

const { Paragraph } = Typography;

export const StyledEditableText = styled(Paragraph)`
  margin-bottom: 0 !important;
  max-width: 360px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.85);
  
  & > div {
    font-size: 16px;
  }
`;
