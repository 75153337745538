import { gql } from 'apollo-boost';

export const fetchBounds = gql`
    query Shape($overlappingLat: String!, $overlappingLon: String!) {
        shapes(overlappingLat: $overlappingLat, overlappingLon: $overlappingLon) {
            id
            name
            shapeGroup {
                id
                name
            }
        }
    }
`;
