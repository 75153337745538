import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from 'antd/lib/input';
import get from 'lodash/get';

import { Label } from 'components';

import { convertUnitsToPx, convertWithUnit } from '../utils';
import { StyledInputWrapper } from '../styles';


const WidthInput = ({
  presentMapUnits,
  values,
  ppi,
  changeExportSettings,
  setFieldValue,
}) => {
  const initialWidth = get(values, 'width', '');
  const changeWidth = ({ target }) => {
    const width = target.value;
    const widthInPixels = convertUnitsToPx(presentMapUnits, width, ppi);

    changeExportSettings({ presentMapWidth: widthInPixels });

    setFieldValue('width', width);
  };

  useEffect(() => {
    const width = document.querySelector('#editMapsMap > div').offsetWidth;
    const height = document.querySelector('#editMapsMap > div').offsetHeight;

    setFieldValue('width', convertWithUnit(presentMapUnits, width, ppi));
    setFieldValue('height', convertWithUnit(presentMapUnits, height, ppi));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentMapUnits]);

  return (
    <StyledInputWrapper>
      <Label colon={false} label="Width">
        <Input
          name="width"
          value={initialWidth}
          addonAfter={presentMapUnits}
          onChange={changeWidth}
        />
      </Label>
    </StyledInputWrapper>
  );
};

WidthInput.propTypes = {
  changeExportSettings: PropTypes.func.isRequired,
  presentMapUnits: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default WidthInput;
