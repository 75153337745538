import styled from 'styled-components';

export const StyledEditDatasetPageInfo = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  display: inline-block;
  padding: 0 15px 8px 15px;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
`;

export const StyledSkeletonContainer = styled.div`
  width: 182px
`;
