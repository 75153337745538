import React from 'react';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';

const AddField = ({ arrayHelpers, statistics }) => {
  const handleClick = () => arrayHelpers.insert(statistics.length + 1, '');

  return (
    <Button type="dashed" onClick={handleClick}>
      <Icon type="plus" />
      Add field
    </Button>
  );
};


export default AddField;
