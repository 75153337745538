import React from 'react';
import PropTypes from 'prop-types';
import { MAP_DEFAULT_COLOR } from 'utils/constants';

import { Color, PickedColorContainer } from './styles';


const PickedColor = ({ color }) => (
  <PickedColorContainer>
    <Color color={color} />
  </PickedColorContainer>
);

PickedColor.propTypes = {
  color: PropTypes.string,
};

PickedColor.defaultProps = {
  color: MAP_DEFAULT_COLOR,
};

export default PickedColor;
