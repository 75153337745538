import React from 'react';
import PropTypes from 'prop-types';
import Collapse from 'antd/lib/collapse';

import { useRouter } from 'utils';

import ImportProgressBar from './ImportProgressBar';
import NotFoundDataPoint from './NotFoundDataPoint';

import { NotFoundDataPointContainer } from './style';

const { Panel } = Collapse;

const FileCard = ({ notFoundDatapoints, ...props }) => {
  const { pageId } = useRouter();
  const importProgressBar = (
    <ImportProgressBar
      strokeColor="orange"
      notFoundDatapoints={notFoundDatapoints}
      pageId={pageId}
      {...props}
    />
  );

  return (
    <div>
      <Collapse expandIconPosition="right">
        <Panel key="panel" header={importProgressBar}>
          <NotFoundDataPointContainer>
            {notFoundDatapoints.nodes.map(({ id, ...datapointData }) => (
              <NotFoundDataPoint key={id} id={id} notFoundDatapoint={datapointData} />
            ))}
          </NotFoundDataPointContainer>
        </Panel>
      </Collapse>
    </div>
  );
};

FileCard.propTypes = {
  id: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  percentComplete: PropTypes.number.isRequired,
  notFoundDatapoints: PropTypes.object.isRequired,
};

export default FileCard;
