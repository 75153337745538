import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { EditMapDatasets, setMapDatasets, initialMapSettings } from 'components';
import showPopup, { createPopupData } from 'components/shared/Map/showPopup';

import usePointEditMap from 'state/pointEditMap/usePointEditMap';
import useContextMenu from 'state/contextMenu/useContextMenu';

import { defaultMapStyles } from 'utils';
import { MAP_DEFAULT_COLOR } from 'utils/constants';


const EditMapDatasetsContainer = ({ datapointsGeojson, statisticsFields }) => {
  const { selectPointEditMap } = usePointEditMap();
  const { closeContextMenu, openContextMenu } = useContextMenu();
  const [mapViewport, changeMapViewport] = useState({ viewport: initialMapSettings });

  const showMapPopup = (event) => {
    if (event.rightButton) {
      return false;
    }

    const popupData = createPopupData({ event, datapointsGeojson, statisticsFields });
    if (popupData) {
      closeContextMenu();
      showPopup(selectPointEditMap, popupData);

      return false;
    }

    // close popup on outside click
    selectPointEditMap({});
    closeContextMenu();
  };

  const handleContextMenu = (event) => {
    event.preventDefault();

    const { target, lngLat } = event;

    const isClickOnMap = target.className === 'overlays';
    if (isClickOnMap) {
      const contextMenuInfo = {
        lngLat,
        targetClass: target.className,
      };
      openContextMenu(contextMenuInfo);
    }
  };

  const onViewportChange = (event) => {
    // don't change width for map
    const { width, height, ...viewport } = event;

    changeMapViewport({ viewport });
  };

  const mapDatapoints = setMapDatasets(defaultMapStyles, datapointsGeojson, MAP_DEFAULT_COLOR);

  return (
    <EditMapDatasets
      mapViewport={mapViewport}
      mapDatapoints={mapDatapoints}
      showMapPopup={showMapPopup}
      closeContextMenu={closeContextMenu}
      onViewportChange={onViewportChange}
      changeMapViewport={changeMapViewport}
      selectPointEditMap={selectPointEditMap}
      handleContextMenu={handleContextMenu}
    />
  );
};

EditMapDatasetsContainer.propTypes = {
  datapointsGeojson: PropTypes.object.isRequired,
  statisticsFields: PropTypes.array.isRequired,
};

export default EditMapDatasetsContainer;
