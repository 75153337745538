import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AutoComplete from 'antd/lib/auto-complete';

import { PreviewMap, Label } from 'components';

const disableSubmit = (e) => {
  const pressedEnter = e.key === 'Enter';

  if (pressedEnter) {
    e.preventDefault();
    e.stopPropagation();
  }
};

const AddressSearch = ({
  autoCompleteAddresses,
  addressInputValue,
  searchAddress,
  updatePreviewMap,
  updateAddressInput,
  getAddress,
  selectedDataPoint,
  addressCoordinates,
  getLocations,
}) => (
  <Fragment>
    <Label colon={false} label="Address">
      <div onKeyDown={disableSubmit}>
        <AutoComplete
          allowClear
          size="large"
          placeholder="Start typing..."
          dataSource={autoCompleteAddresses}
          value={addressInputValue}
          onChange={searchAddress}
          onSelect={updatePreviewMap}
        />
      </div>
    </Label>
    <PreviewMap
      selectedDataPoint={selectedDataPoint}
      updateAddressInput={updateAddressInput}
      getAddress={getAddress}
      addressCoordinates={addressCoordinates}
      getLocations={getLocations}
    />
  </Fragment>
);

AddressSearch.propTypes = {
  addressCoordinates: PropTypes.object,
  selectedDataPoint: PropTypes.object,
  autoCompleteAddresses: PropTypes.array.isRequired,
  addressInputValue: PropTypes.string,
  getLocations: PropTypes.func.isRequired,
  getAddress: PropTypes.func.isRequired,
  searchAddress: PropTypes.func.isRequired,
  updatePreviewMap: PropTypes.func.isRequired,
  updateAddressInput: PropTypes.func.isRequired,
};

export default React.memo(AddressSearch);
