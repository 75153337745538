import styled from 'styled-components';

export const NavigationContainer = styled.div`
  position: relative;
  
  .mapboxgl-ctrl {
    position: absolute;
    right: 0;
    margin: 10px;
  }
`;
