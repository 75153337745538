import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import get from 'lodash/get';

import { showErrorNotification } from 'components/shared/Notification';
import { datapointCreate, fetchDataset } from 'graphql/queries';
import { createDatapointAction } from 'state/datapoint/actions';

// TODO: remove duplication
const normalizeDatapoint = (data) => {
  data.data.datapointCreate.datapoint.statistics.forEach((item) => {
    delete item.__typename;
  });

  data.data.datapointCreate.datapoint.data = data.data.datapointCreate.datapoint.statistics;

  const { datapoint } = data.data.datapointCreate;

  return datapoint;
};

const createLongLat = ({ latitude, longitude }) => `POINT(${longitude} ${latitude})`;
export const createMutationVariables = ({
  name,
  description,
  datasetId,
  address,
  locations,
  statistics,
}) => {
  const lonlat = createLongLat(locations);

  return {
    variables: {
      input: {
        name,
        description,
        datasetId,
        address,
        lonlat,
        statistics,
      },
    },
  };
};

const AddLocationService = (pageId, closeModal) => {
  const dispatch = useDispatch();

  const [addLocation, { error }] = useMutation(datapointCreate, {
    refetchQueries: [{ query: fetchDataset, variables: { id: pageId } }],
    onCompleted: () => {
      closeModal();
    },
    update: (proxy, data) => {
      // create a data point on the map at once
      const datapoint = normalizeDatapoint(data);

      if (datapoint) {
        const createdDatapoint = ({
          deletedDatapointId: null,
          datapointUpdate: null,
          datapointCreate: datapoint,
        });

        dispatch(createDatapointAction(createdDatapoint));
      }
    },
    onError: (error) => {
      const errorMessage = get(error, 'graphQLErrors[0].message', false);
      showErrorNotification(errorMessage);
    },
  });


  return ({ addLocation, error });
};

export default AddLocationService;
