import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

const MapBoundariesLicense = ({ exportSettings }) => {
  const hasMapBounds = get(exportSettings, 'isBoundsShowsOnMap', false);

  return hasMapBounds ? (
    <div>
      Administrative Boundaries © PSMA Australia Limited licensed by the Commonwealth of Australia
      under Creative Commons Attribution 4.0 International licence (CC BY 4.0).
    </div>
  ) : null;
};

MapBoundariesLicense.propTypes = {
  exportSettings: PropTypes.object.isRequired,
};

const mapStateToProps = ({ exportSettings }) => ({ exportSettings });

export default connect(mapStateToProps, null)(MapBoundariesLicense);
