import bbox from '@turf/bbox';
import { initialMapSettings } from 'components';

const createCoordinates = (data) => {
  // eslint-disable-next-line no-unused-vars
  const [minLng, minLat, maxLng, maxLat] = bbox(data.toJS());

  return [minLng, maxLat];
};

export const getInitialCoordinates = (mapStyles) => {
  const { latitude, longitude } = initialMapSettings;
  const defaultCoordinates = [longitude, latitude];

  const boundaries = mapStyles.getIn(['sources', 'boundaries', 'data', 'geometry']);
  if (boundaries) {
    return createCoordinates(boundaries);
  }

  const datapoints = mapStyles.getIn(['sources', 'datapoints', 'data']);
  if (datapoints) {
    return createCoordinates(datapoints);
  }

  return defaultCoordinates;
};
