import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { EditableText } from 'components';
import { StyledDescription, DescriptionTip } from './style';

const handleEmptyDescription = description => (isEmpty(description) ? '' : description);

const EditableDescription = ({
  name,
  pageId,
  description,
  updateDescription,
  createVariables,
}) => {
  const [isDescriptionHide, toggleDescription] = useState(false);

  const hideDescription = () => {
    toggleDescription(true);
  };

  const showDescription = () => {
    toggleDescription(false);
  };

  const shouldShowDescription = isEmpty(description) && !isDescriptionHide;
  const initialDescription = handleEmptyDescription(description);

  return (
    <StyledDescription>
      {shouldShowDescription && <DescriptionTip>Add Description</DescriptionTip>}
      <div
        tabIndex="0"
        role="textbox"
        onKeyPress={hideDescription}
        onClick={hideDescription}
        onBlur={showDescription}
      >
        <EditableText
          name={name}
          pageId={pageId}
          saveChanges={updateDescription}
          initialText={initialDescription}
          createVariables={createVariables}
        />
      </div>
    </StyledDescription>
  );
};

EditableDescription.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  pageId: PropTypes.string.isRequired,
  createVariables: PropTypes.func.isRequired,
  updateDescription: PropTypes.func.isRequired,
};

export default React.memo(EditableDescription);
