import { useDispatch, useSelector } from 'react-redux';

import { closeContextMenuAction, openContextMenuAction } from './actions';

const useContextMenu = () => {
  const dispatch = useDispatch();
  const contextMenu = useSelector(state => state.contextMenuReducer);

  const closeContextMenu = () => dispatch(closeContextMenuAction());
  const openContextMenu = args => dispatch(openContextMenuAction(args));

  return { contextMenu, closeContextMenu, openContextMenu };
};

export default useContextMenu;
