import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';

import { Header } from 'layout';
import {
  Datasets,
  EditDatasets,
  EditMaps,
  Maps,
} from 'pages';
import store from 'state/store';

const RedirectToMapsPage = () => <Redirect to="/maps/" />;
const RedirectToDatasetsPage = () => <Redirect to="/datasets/" />;

const Index = () => (
  <Provider store={store}>
    <BrowserRouter path="/maps/">
      <Header data-html2canvas-ignore />
      <Route path={['/', '/maps']} exact component={RedirectToMapsPage} />
      <Route path={['/datasets']} exact component={RedirectToDatasetsPage} />
      <Route path="/maps/" exact component={Maps} />
      <Route path="/maps/edit/:id" component={EditMaps} />
      <Route path="/datasets/" exact component={Datasets} />
      <Route path="/datasets/edit/:id" component={EditDatasets} />
    </BrowserRouter>
  </Provider>
);

export default Index;
