import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import getImportLocationProgressStatus from 'state/progressImportLocations/actions';

import { ImportLocationsProgressBar } from 'components';

import ImportService from './service';
import { StyledProgressBarWrapper, StyledButton } from './style';


const ImportLocationsProgress = ({
  id,
  fileName,
  percent,
}) => {
  const { destroyImport } = ImportService(id);
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(getImportLocationProgressStatus({ canceled: true }));
    destroyImport();
  };

  return (
    <StyledProgressBarWrapper>
      <StyledButton type="link" onClick={handleCancel}>Cancel</StyledButton>
      <ImportLocationsProgressBar
        fileName={fileName}
        percent={percent}
        status="active"
      />
    </StyledProgressBarWrapper>
  );
};

ImportLocationsProgress.propTypes = {
  id: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default React.memo(ImportLocationsProgress);
