import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';

import { MAP_DEFAULT_COLOR } from 'utils/constants';
import { ColorPicker, ClusterLocations, Label } from 'components';

import SaveMapDatasetForm from './SaveMapDatasetForm';
import DatasetOptions from './DatasetOptions/container';
import { GithubPicker } from 'react-color';

const MapDatasetsForm = ({
  id,
  type,
  pageId,
  closeModal,
  updateMapDataset,
  clustered,
  handleChange,
  values,
  color = MAP_DEFAULT_COLOR,
  datasets,
  setFieldValue,
}) => {
  const { displayName } = values;

  const [isClustered, switchClusterLocation] = useState(clustered);
  const [pickedColor, pickColor] = useState(color);

  return (
    <Form>
      <DatasetOptions
        selectedDatasets={datasets}
        setFieldValue={setFieldValue}
        initialDataset={values.initialDatasetsName}
      />
      <Label colon={false} label="Display name">
        <Input name="displayName" value={displayName} onChange={handleChange} />
      </Label>
      <ColorPicker colorPicker={GithubPicker} initialColor={pickedColor} pickColor={pickColor} />
      <ClusterLocations switchClusterLocation={switchClusterLocation} clustered={isClustered} />
      <SaveMapDatasetForm
        id={id}
        type={type}
        values={values}
        pageId={pageId}
        closeModal={closeModal}
        updateMapDataset={updateMapDataset}
        pickedColor={pickedColor}
        isClustered={isClustered}
      />
    </Form>
  );
};

MapDatasetsForm.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  pageId: PropTypes.string,
  closeModal: PropTypes.func,
  datasets: PropTypes.array,
  color: PropTypes.string,
  clustered: PropTypes.bool,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  updateMapDataset: PropTypes.func,
};

export default MapDatasetsForm;
