import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { SPLIT_ON } from 'components/maps/Aggregates/constants';
import MapDatasetSubTotals from './MapDatasetSubTotals/container';
import ShapesSubTotals from './ShapesSubTotals/container';


import { Section } from './styles';

const { NONE, MAP_DATASET, MAP_SHAPE } = SPLIT_ON;

const RenderSubtotals = ({ aggregateInfo, mapShapes, mapDatasets }) => (
  <Fragment>
    {aggregateInfo.splitOn === MAP_DATASET && (
      <MapDatasetSubTotals
        mapShapes={mapShapes}
        mapDatasets={mapDatasets}
        aggregateInfo={aggregateInfo}
      />
    )}
    {aggregateInfo.splitOn === MAP_SHAPE && (
      <ShapesSubTotals
        mapShapes={mapShapes}
        mapDatasets={mapDatasets}
        aggregateInfo={aggregateInfo}
      />
    )}
  </Fragment>
);

RenderSubtotals.propTypes = {
  mapShapes: PropTypes.array.isRequired,
  mapDatasets: PropTypes.array.isRequired,
  aggregateInfo: PropTypes.object.isRequired,
};

const shouldRenderSubtotals = ({ splitOn, totals }) => {
  const isMapDatasetsExists = splitOn === MAP_DATASET && !isEmpty(totals.mapDatasets);
  const isMapShapesExists = splitOn === MAP_SHAPE && !isEmpty(totals.mapShapes);

  return (isMapDatasetsExists || isMapShapesExists) && splitOn !== NONE;
};

const CardTotal = ({ mapShapes, mapDatasets, aggregateInfo }) => {
  if (!shouldRenderSubtotals(aggregateInfo)) {
    return null;
  }

  return (
    <Section>
      <RenderSubtotals
        mapShapes={mapShapes}
        mapDatasets={mapDatasets}
        aggregateInfo={aggregateInfo}
      />
    </Section>
  );
};

CardTotal.propTypes = {
  mapShapes: PropTypes.array,
  mapDatasets: PropTypes.array,
  aggregateInfo: PropTypes.object,
};

export default CardTotal;
