import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { customAggregateSubtotalsKeysInCache } from './cacheConfig';

const cache = new InMemoryCache({ ...customAggregateSubtotalsKeysInCache });

const client = new ApolloClient({
  uri: '/graphql',
  cache,
});

export default client;
