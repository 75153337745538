import styled from 'styled-components';

const overlayZindex = 2;

export const ColorPickerContainer = styled.div`
  position: absolute;
  top: 40px;
  z-index: 1;
  
  .github-picker {
    z-index: 3;
  }
`;

export const ColorPickerOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${overlayZindex};
`;
