import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import MapDatasetSubTotals from '.';

const getMapShapesNames = ({ mapShapes, subMapShapes }) => (
  subMapShapes.map(({ id, value }) => ({ ...find(mapShapes, { id }), value }))
);

const MapDatasetSubTotalsContainer = ({ mapShapes, aggregateInfo, mapDatasets }) => {
  const { showSubtotals, totals } = aggregateInfo;

  return !isEmpty(totals) && totals.mapDatasets.map(({ id, subMapShapes, value }) => {
    const mapDataset = find(mapDatasets, { id });
    const normalizedMapShapes = getMapShapesNames({ subMapShapes, mapShapes });

    return (
      <MapDatasetSubTotals
        key={id}
        mapDataset={mapDataset}
        showSubtotals={showSubtotals}
        mapShapes={normalizedMapShapes}
        aggregatesCount={value}
      />
    );
  });
};

MapDatasetSubTotalsContainer.propTypes = {
  mapShapes: PropTypes.array.isRequired,
  mapDatasets: PropTypes.array.isRequired,
  aggregateInfo: PropTypes.object.isRequired,
};

export default MapDatasetSubTotalsContainer;
