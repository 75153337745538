import { DESTROY_DATA_POINT, UPDATE_DATA_POINT, CREATE_DATA_POINT } from './types';

export const destroyDatapointAction = payload => ({
  type: DESTROY_DATA_POINT,
  payload,
});

export const updateDatapointAction = payload => ({
  type: UPDATE_DATA_POINT,
  payload,
});

export const createDatapointAction = payload => ({
  type: CREATE_DATA_POINT,
  payload,
});
