import { useMutation } from '@apollo/react-hooks';

import {
  fetchMap,
  mapDatasetDestroy,
} from 'graphql/queries';

const DatasetItemService = (id, pageId) => {
  const [destroyDataset] = useMutation(mapDatasetDestroy, {
    variables: {
      input: {
        id,
      },
    },
    refetchQueries: [{
      query: fetchMap,
      variables: ({ id: pageId }),
    }],
  });

  return ({ destroyDataset });
};

export default DatasetItemService;
