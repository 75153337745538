import { useMutation } from '@apollo/react-hooks';

import { datapointDestroy, fetchDataset } from 'graphql/queries';

const NotFoundDataPointService = (id, pageId) => {
  const [destroyDatapoint] = useMutation(datapointDestroy, {
    variables: {
      input: {
        id,
      },
    },
    refetchQueries: [{ query: fetchDataset, variables: { id: pageId } }],
  });

  return ({ destroyDatapoint });
};

export default NotFoundDataPointService;
