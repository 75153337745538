import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Thumbnail from './Thumbnail';

import { BASEMAP } from '../../constants';
import { thumbnails } from './thumbnails';
import { StyledTitle, StyledWrapper } from './styles';

const {
  LIGHT,
  DARK,
  TERRAIN,
  NONE,
} = BASEMAP;

const BaseMap = ({ onChangeBasemap, basemap }) => {
  const handleOnChangeBasemap = ({ target }) => onChangeBasemap(target.value);

  return (
    <Fragment>
      <StyledTitle>Base Map Style</StyledTitle>
      <fieldset onChange={handleOnChangeBasemap}>
        <StyledWrapper>
          {thumbnails.map(({ id, title, imageSrc }) => (
            <Thumbnail
              key={id}
              title={title}
              imageSrc={imageSrc}
              selected={title.toUpperCase() === basemap}
            />
          ))}
        </StyledWrapper>
      </fieldset>
    </Fragment>
  );
};

BaseMap.propTypes = {
  onChangeBasemap: PropTypes.func.isRequired,
  basemap: PropTypes.oneOf([LIGHT, DARK, TERRAIN, NONE]).isRequired,
};

export default BaseMap;
