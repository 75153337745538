import * as Sentry from '@sentry/browser';

import Environment from './Environment';

const environment = Environment.get();

const initErrorReporting = () => Sentry.init({
  dsn: 'https://10b0c0263c4e48fd8cda9e951c967e17@sentry.io/1479111',
  environment,
});

export default initErrorReporting;
