import { gql } from 'apollo-boost';

export const datapointCreate = gql`
    mutation datapointCreate($input: DatapointCreateMutationInput!) {
        datapointCreate(input: $input) {
            datapoint {
                id
                name
                address
                description
                lonlat
                statistics {
                    id
                    name
                    value
                }
            }
        }
    }
`;

export const datapointDestroy = gql`
    mutation datapointDestroy($input: DatapointDestroyMutationInput!) {
        datapointDestroy(input: $input) {
            deletedId
        }
    }
`;

export const datapointUpdate = gql`
    mutation datapointUpdate($input: DatapointUpdateMutationInput!) {
        datapointUpdate(input: $input) {
            datapoint {
                id
                name
                address
                description
                lonlat
                statistics {
                    id
                    name
                    value
                }
            }
        }
    }
`;
