import styled from 'styled-components';
import Button from 'antd/lib/button';
import card from 'antd/lib/card';

const dangerColor = '#dc3545';
const dangerColorHover = '#a71d2a';
const sectionBorders = '1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #fff inset, 0 1px 0 0 #e8e8e8 inset';

export const DangerLink = styled(Button)`
  color: ${dangerColor};
  
  &:hover {
    color: ${dangerColorHover};
  }
`;

export const Link = styled(Button)`
  padding-left: 0;
`;

export const Section = styled(card.Grid)`
  width: 100%;
  box-shadow: ${sectionBorders};
  padding: 10px 24px;
  
  &:hover {
    box-shadow: ${sectionBorders};
  }
`;
