import React from 'react';
import PropTypes from 'prop-types';

import { StaffContainer, LocationText, StyledLocationContainer } from './style';

const LocationInfo = ({ statistics }) => (
  <StaffContainer>
    {statistics.map(({ name, value }) => (
      <StyledLocationContainer key={`${name}_${value}`}>
        <LocationText>
          <strong>{name}</strong>
        </LocationText>
        <LocationText>{value}</LocationText>
      </StyledLocationContainer>
    ))}
  </StaffContainer>
);

LocationInfo.propTypes = {
  statistics: PropTypes.array.isRequired,
};

export default LocationInfo;
