import { gql } from 'apollo-boost';

export const fetchShapes = gql`
    {
        shapeGroups {
            id
            name
            shapes {
                id
                name
            }
        }
    }
`;

export const fetchMaps = gql`
    {
        maps {
            id
            name
            description
            datasets {
                id
            }
            mapShapes {
                id
                name
            }
            mapDatasets {
                id
                name
                color
            }
        }
    }
`;

export const fetchMap = gql`
    query Map($id: ID!) {
        map(id: $id) {
            id
            name
            description
            aggregatableFields {
                name
                min
                max
            }
            datasets {
                id
                name
            }
            mapShapes {
                id
                name
                shape {
                    id
                    geojsonUrl
                    shapeGroup {
                        id
                        name
                    }
                }
            }
            mapDatasets {
                id
                name
                displayName
                color
                datapointsGeojson
                clustered
                dataset {
                    id
                    statisticsFields
                }
                datapoints {
                    totalCount
                }
            }
            aggregates {
                id
                name
                displayName
                function
                field
                mapDatasets {
                    id
                    name
                    color
                }
                showSubtotals
                splitOn
                totals {
                    value
                    mapDatasets {
                        id
                        value
                        subMapShapes: mapShapes {
                            id
                            value
                        }
                    }
                    mapShapes {
                        id
                        value
                        subMapDatasets: mapDatasets {
                            id
                            value
                        }
                    }
                }
            }
            basemap
            visualizationType
            shapeBorderColor
            heatmapWeightField
            heatmapGradient {
              lowestValue
              midpointValue
              highestValue
            }
        }
    }
`;
