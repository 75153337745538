export const fetchBoundariesShapes = async (mapShapesUrls) => {
  let boundariesShapes;
  try {
    const promises = mapShapesUrls.map(url => (
      fetch(url)
        .then(fetchedShapes => fetchedShapes.status === 200 && fetchedShapes.json())));

    return Promise.all(promises).then(shapes => shapes);
  } catch (e) {
    boundariesShapes = {};
  }

  return boundariesShapes;
};
