import { useDispatch } from 'react-redux';

import { selectBoundAction } from 'state/selectBounds/actions';

const useSelectBounds = () => {
  const dispatch = useDispatch();
  const selectBound = args => dispatch(selectBoundAction(args));

  return { selectBound };
};

export default useSelectBounds;
