import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { BoundariesSubtotals, DatasetSelect, SplitOn } from '../AggregateDataFormItems';
import { StyledFilterTitle, StyledOptional } from '../styles';

const FilterAndSplitForm = ({
  form,
  aggregates,
  insightId,
  splitValue,
  changeSplitOnValue,
  children,
  formValues,
  updateInsight,
  mapDatasets,
  allMapDatasets,
}) => (
  <Fragment>
    <StyledFilterTitle>Filter & Split</StyledFilterTitle>
    <StyledOptional>Optional</StyledOptional>
    <DatasetSelect
      form={form}
      aggregates={aggregates}
      insightId={insightId}
      mapDatasets={mapDatasets}
      allMapDatasets={allMapDatasets}
    />
    <SplitOn splitValue={splitValue} form={form} changeSplitOnValue={changeSplitOnValue} />
    {splitValue !== 'NONE' && <BoundariesSubtotals insightId={insightId} splitValue={splitValue} aggregates={aggregates} form={form} />}
    {children(formValues, form, updateInsight)}
  </Fragment>
);

FilterAndSplitForm.propTypes = {
  form: PropTypes.object.isRequired,
  aggregates: PropTypes.array,
  insightId: PropTypes.string,
  splitValue: PropTypes.string,
  changeSplitOnValue: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  mapDatasets: PropTypes.array.isRequired,
  updateInsight: PropTypes.func,
  allMapDatasets: PropTypes.array,
  formValues: PropTypes.object,
};

export default FilterAndSplitForm;
