import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { MapDatasetModal } from 'components';
import { useRouter, useModal } from 'utils';

import RenderListView from '.';
import LocationsCount from './LocationsCount';
import DatasetItemService from './service';


const DatasetItem = (props) => {
  const { id } = props;
  const modalProps = useModal();
  const { pageId } = useRouter();
  const { destroyDataset } = DatasetItemService(id, pageId);

  return (
    <Fragment>
      <RenderListView
        LocationsCount={LocationsCount}
        destroyDataset={destroyDataset}
        {...modalProps}
        {...props}
      />
      <MapDatasetModal type="update" {...modalProps} {...props} />
    </Fragment>
  );
};

DatasetItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  color: PropTypes.string,
  datapoints: PropTypes.object,
};

DatasetItem.defaultProps = {
  name: '',
  color: '',
  datapoints: null,
};

export default DatasetItem;
