import React from 'react';
import PropTypes from 'prop-types';

import { useRouter } from 'utils';

import CreateMapDatasetService from './service';
import createMapDatasetForm from './createMapDatasetForm';

const CreateMapDatasets = ({ closeModal, ...props }) => {
  const { pageId } = useRouter();
  const { createMapDataset } = CreateMapDatasetService(pageId);
  const CreateMapDatasetsForm = createMapDatasetForm(props);

  return (
    <CreateMapDatasetsForm
      pageId={pageId}
      updateMapDataset={createMapDataset}
      closeModal={closeModal}
      {...props}
    />
  );
};

CreateMapDatasets.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default CreateMapDatasets;
