import React from 'react';

import { fetchMaps } from 'graphql/queries';
import { MainPage } from 'components';

const Maps = () => (
  <MainPage
    title="Maps"
    subtitle="Visualise datasets across electoral districts."
    buttonText="New Map"
    query={fetchMaps}
    type="Map"
  />
);

export default Maps;
