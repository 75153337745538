import React from 'react';
import PropTypes from 'prop-types';

import { datasetUpdate } from 'graphql/queries';
import { EditableDescription, EditPageDescription } from 'components';
import { useRouter } from 'utils';


const EditDatasetPageDescription = (props) => {
  const { pageId } = useRouter();
  const { UpdateDescriptionMutation, createVariables } = EditPageDescription;
  const updateDescription = UpdateDescriptionMutation(datasetUpdate);

  return (
    <EditableDescription
      pageId={pageId}
      createVariables={createVariables}
      updateDescription={updateDescription}
      {...props}
    />
  );
};

EditDatasetPageDescription.propTypes = {
  description: PropTypes.string,
};

export default EditDatasetPageDescription;
