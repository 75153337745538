import React from 'react';
import PropTypes from 'prop-types';

import { PageDescription } from 'layout';
import { Cards, Create } from 'components';

import Content from './style';

const MainPage = ({ title, subtitle, ...props }) => (
  <Content>
    <PageDescription title={title} subtitle={subtitle} />
    <Create {...props} />
    <Cards {...props} />
  </Content>
);

MainPage.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default React.memo(MainPage);
