import {
  accessToken,
  MapGLStyleLink,
  editPagePath,
  geocoderApi,
  reverseGeocoderApiLink,
} from 'utils/constants/maps';
import { invalidSession } from 'utils/constants/errors';

const sessionCookie = '_advoc8_development_session';
const rootPath = '/';
export const MAP_DEFAULT_COLOR = '#6610f2';

export {
  accessToken,
  MapGLStyleLink,
  editPagePath,
  sessionCookie,
  invalidSession,
  rootPath,
  geocoderApi,
  reverseGeocoderApiLink,
};
