import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { AddLocationModal } from 'components';
import ContextMenu from 'components/shared/ContextMenu';

const EditDatasetsContextMenu = ({
  latitude,
  longitude,
  selectedDataPoint,
  isShowPopup,
  handleContextMenuClick,
  modalProps,
}) => (
  <Fragment>
    {isShowPopup && (
      <ContextMenu
        latitude={latitude}
        longitude={longitude}
        buttonText="Add location"
        handleOnCloseClick={handleContextMenuClick}
      />
    )}
    <AddLocationModal selectedDataPoint={selectedDataPoint} {...modalProps} />
  </Fragment>
);

EditDatasetsContextMenu.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  selectedDataPoint: PropTypes.object,
  isShowPopup: PropTypes.bool.isRequired,
  handleContextMenuClick: PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired,
};

export default React.memo(EditDatasetsContextMenu);
