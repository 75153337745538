import React, { Fragment } from 'react';
import get from 'lodash/get';

import { FormItems } from 'components';
import { StyledSubTitle, StyledTitle } from './style';

const parseStatisticsData = (statisticsData) => {
  let statistics = statisticsData;
  if (typeof statisticsData === 'string') {
    statistics = JSON.parse(statisticsData);
  }

  return statistics;
};

const LocationDataForm = (props) => {
  const statistics = get(props, 'values.statistics', null);
  const statisticsFields = get(props, 'values.statisticsFields', null);

  const statisticsData = parseStatisticsData(statistics);

  return (
    <Fragment>
      <StyledTitle>Location Data</StyledTitle>
      <StyledSubTitle>Optional</StyledSubTitle>
      <FormItems statistics={statisticsData} statisticsFields={statisticsFields} {...props} />
    </Fragment>
  );
};

export default React.memo(LocationDataForm);
