import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Boundaries, EditMapPageInfo } from 'components';
import { useModal } from 'utils';

import MapDataset from '../MapDataset';
import Aggregates from '../Aggregates';
import SideBarSkeleton from './SideBarSkeleton';

import { StyledSideBar } from './styles';

const SideBar = ({ pageInfo, props }) => {
  const { mapShapes, mapDatasets } = pageInfo;
  const modalProps = useModal();

  return (
    <StyledSideBar width="360">
      {pageInfo ? (
        <>
          <EditMapPageInfo {...pageInfo} />
          <Boundaries boundaries={mapShapes} />
          <MapDataset modalProps={modalProps} {...pageInfo} {...props} />
          {!isEmpty(mapDatasets) && <Aggregates modalProps={modalProps} pageInfo={pageInfo} />}
        </>
      )
        : <SideBarSkeleton />
      }
    </StyledSideBar>
  );
};

SideBar.propTypes = {
  props: PropTypes.object,
  pageInfo: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.object.isRequired,
  ]),
};

export default SideBar;
