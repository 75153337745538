import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import { showPresentMap } from 'state/presentMap/actions';

import PresentMapInfo from '.';

const PresentMapInfoContainer = (props) => {
  const dispatchClosePresentMap = useDispatch();
  const { presentMapEnabled } = useSelector(({ presentMap }) => presentMap);
  const exportedSettings = useSelector(({ exportSettings }) => exportSettings);

  const closePresentMap = () => dispatchClosePresentMap(showPresentMap(false));

  const { name, description, mapDatasets } = props;
  const aggregates = get(props, 'aggregates', []);
  const showTitleDescription = get(exportedSettings, 'showTitleDescription', true);
  const showLegend = get(exportedSettings, 'showLegend', true);
  const showInsights = get(exportedSettings, 'showInsights', true);

  return presentMapEnabled && (
    <PresentMapInfo
      name={name}
      description={description}
      aggregates={aggregates}
      mapDatasets={mapDatasets}
      showTitleDescription={showTitleDescription}
      showLegend={showLegend}
      showInsights={showInsights}
      closePresentMap={closePresentMap}
      {...props}
    />
  );
};

PresentMapInfoContainer.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  mapDatasets: PropTypes.array,
};

export default React.memo(PresentMapInfoContainer);
