import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton } from './style';

const OpenLocationsModal = ({ onClick }) => (
  <StyledButton
    type="default"
    icon="import"
    onClick={onClick}
  >
    Import
  </StyledButton>
);

OpenLocationsModal.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default React.memo(OpenLocationsModal, () => true);
