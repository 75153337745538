import { PRODUCTION, PRODUCTION_HOST_NAME } from './constants';

class Environment {
  static getNamespace = (host) => {
    const isHostHasNamespace = host.includes('-');

    if (isHostHasNamespace) {
      const startNamespace = host.split('-').pop();

      return startNamespace.split('.')[0];
    }

    return null;
  };

  static get = () => {
    const { NODE_ENV } = process.env;
    const { hostname } = window.location;
    const namespace = Environment.getNamespace(hostname);

    if (NODE_ENV === PRODUCTION && hostname === PRODUCTION_HOST_NAME) {
      return PRODUCTION;
    }

    return namespace;
  };
}

export default Environment;
