import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'antd/lib/badge';

import { Section, BadgeContainer } from './styles';

const MapDatasetsBadges = ({ mapDatasets, children }) => (
  <Section>
    {mapDatasets.map(({
      id,
      name,
      color,
      value,
    }) => (
      <BadgeContainer key={id}>
        <Badge color={color} text={name} />
        {children && children(value)}
      </BadgeContainer>
    ))}
  </Section>
);

MapDatasetsBadges.propTypes = {
  mapDatasets: PropTypes.array.isRequired,
  children: PropTypes.func,
};

export default React.memo(MapDatasetsBadges);
