import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { reverseGeocoderApiLink } from 'utils/constants';

import AddressSuggestions from '.';

const createTopOf3Suggestions = (suggestions) => {
  const top3ofSuggestions = suggestions.slice(0, 3);

  return top3ofSuggestions.map(item => item.title);
};

const fetchSuggestions = async ({ latitude, longitude }) => {
  const geocodeLocationsLink = `${reverseGeocoderApiLink}${latitude},${longitude}`;
  const locationsResponse = await fetch(geocodeLocationsLink);
  const locations = await locationsResponse.json();

  return get(locations, 'items', []);
};

const AddressSuggestionsContainer = ({ locations, ...props }) => {
  const [suggestions, changeSuggestions] = useState();

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    fetchSuggestions(locations).then((fetchedSuggestions) => {
      const topOf3Suggestions = createTopOf3Suggestions(fetchedSuggestions);
      changeSuggestions(topOf3Suggestions);
    });
  }, [locations]);

  return <AddressSuggestions suggestions={suggestions} {...props} />;
};

AddressSuggestionsContainer.propTypes = {
  locations: PropTypes.object,
};

export default AddressSuggestionsContainer;
