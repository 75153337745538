import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import Icon from 'antd/lib/icon';

import { StyledIcon } from './style';

const { Item } = Menu;

const MenuList = ({ coreUrl, signOutUrl }) => (
  <Menu>
    <Item key="0">
      <a href={coreUrl}>
        <StyledIcon type="rollback" />
        Back to Advoc8 Core
      </a>
    </Item>
    <Item key="1">
      <a href={signOutUrl}>
        <StyledIcon type="logout" />
        Sign out
      </a>
    </Item>
  </Menu>
);

MenuList.propTypes = {
  coreUrl: PropTypes.string.isRequired,
  signOutUrl: PropTypes.string.isRequired,
};

const UserDropdown = props => (
  <Dropdown overlay={<MenuList {...props} />} trigger={['click']}>
    <Icon type="down" />
  </Dropdown>
);

export default UserDropdown;
