import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'antd/lib/switch';

import { Label } from 'components';

import { StyledSwitch } from '../styles';

const InsightsSwitch = ({ changeExportSettings }) => {
  const handleInsightsSwitch = (show) => {
    changeExportSettings({
      showInsights: show,
    });
  };

  return (
    <StyledSwitch>
      <Label colon={false} label="Insights">
        <Switch defaultChecked size="small" onChange={handleInsightsSwitch} />
      </Label>
    </StyledSwitch>
  );
};

InsightsSwitch.propTypes = {
  changeExportSettings: PropTypes.func.isRequired,
};

export default InsightsSwitch;
