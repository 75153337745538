import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { fetchDataset } from 'graphql/queries';

import ImportLocationsProgress from '.';
import checkFileProcess from './checkFileProcess';


const ImportLocationsProgressContainer = (props) => {
  const { percent, pageId, errorMessage } = props;
  const isImportCanceled = get(props, 'canceled', false);
  const id = get(props, 'id', false);

  // TODO: it should be handle by import channel function
  const { refetch } = useQuery(fetchDataset, { variables: { id: pageId } });
  const isFileInProcess = checkFileProcess(props);

  useEffect(() => {
    const fileUploaded = percent === 100 || !isEmpty(errorMessage);

    if (fileUploaded) {
      refetch();
    }
  }, [percent, refetch, errorMessage]);

  const isPageHasImport = String(id) === JSON.parse(localStorage.getItem(`${pageId}`));

  return (isFileInProcess && !isImportCanceled && isPageHasImport) ? <ImportLocationsProgress {...props} /> : null;
};

ImportLocationsProgressContainer.propTypes = {
  percent: PropTypes.number,
  fileName: PropTypes.string,
  errorMessage: PropTypes.string,
  pageId: PropTypes.string,
};

export default React.memo(ImportLocationsProgressContainer);
