import ActionCable from 'actioncable';
import getImportLocationProgressStatus from 'state/progressImportLocations/actions';

const createWebsocketHost = () => {
  const { host, protocol } = window.location;
  const { NODE_ENV } = process.env;

  const wsProtocol = protocol === 'http:' ? 'ws' : 'wss';
  const hostUrl = NODE_ENV === 'development' ? 'polimap.grm.test:4000' : host;

  return `${wsProtocol}://${hostUrl}/cable`;
};

const WEBSOCKET_HOST = createWebsocketHost();
const cable = ActionCable.createConsumer(WEBSOCKET_HOST);

const getProcessStatus = (percent) => {
  let status = 'active';
  const isProcessDone = percent === 100;

  if (isProcessDone) {
    status = 'success';
  }

  return status;
};

export const CreateImportsChannelSubscriptions = (id, fileName, dispatch, closeModal) => {
  const consumer = cable.subscriptions.create(
    { channel: 'ImportsChannel', id },
    {
      connected: () => {
        if (closeModal) {
          closeModal();
        }
      },
      received: ({ percent_complete, error_message }) => {
        const status = getProcessStatus(percent_complete);

        if (percent_complete === 100 || error_message) {
          consumer.unsubscribe();
        }

        dispatch(getImportLocationProgressStatus({
          id,
          percent: percent_complete,
          status,
          fileName,
          errorMessage: error_message,
        }));
      },
    },
  );
};
