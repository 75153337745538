import React from 'react';
import { connect } from 'react-redux';
import { changeExportSettings } from 'state/exportSettings/actions';

import { InsightsSwitch, LegendSwitch, TitleDescriptionSwitch } from '../ExportSettingsFormItems';

import { StyledContainer, StyledOptions } from './styles';

const ExportSettingsOptions = ({ changeExportSettings }) => (
  <StyledContainer>
    <StyledOptions>Options</StyledOptions>
    <TitleDescriptionSwitch changeExportSettings={changeExportSettings} />
    <LegendSwitch changeExportSettings={changeExportSettings} />
    <InsightsSwitch changeExportSettings={changeExportSettings} />
  </StyledContainer>
);

const mapDispatchToProps = { changeExportSettings };

const mapStateToProps = ({ exportSettings }) => ({ ...exportSettings });

export default connect(mapStateToProps, mapDispatchToProps)(ExportSettingsOptions);
