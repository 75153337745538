import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'antd/lib/modal';

import { useModal } from 'utils';

import MapCustomizationForm from './MapCustomizationForm';
import StyleMapButton from './StyleMapButton';


const MapCustomization = () => {
  const { openModal, closeModal, visible } = useModal();
  const { presentMapEnabled } = useSelector(({ presentMap }) => presentMap);

  return (
    <Fragment>
      {!presentMapEnabled && <StyleMapButton openModal={openModal} />}
      <Modal
        destroyOnClose
        title="Style Map"
        width={800}
        visible={visible}
        onCancel={closeModal}
        footer={null}
      >
        <MapCustomizationForm closeModal={closeModal} />
      </Modal>
    </Fragment>
  );
};

export default React.memo(MapCustomization);
