import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { StyledSpinner } from './styles';

const Circle = () => (
  <svg>
    <circle cx="50%" cy="50%" r="18px" />
  </svg>
);

const Spinner = ({ children }) => {
  const { showSpinner } = useSelector(({ spinnerReducer }) => spinnerReducer);

  return (
    <StyledSpinner>
      {showSpinner && <Circle />}
      {children}
    </StyledSpinner>
  );
};

Spinner.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Spinner;
