import { withFormik } from 'formik';
import { LocationForm } from '../../LocationForm';

const AddLocationForm = withFormik({
  handleSubmit: async (values, { props }) => {
    await props.createLocation(values);
  },
})(LocationForm);

export default AddLocationForm;
