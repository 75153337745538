import React from 'react';
import { connect } from 'react-redux';

import Dimensions from './Dimensions/container';
import ImageSize from './ImageSize/container';
import PreviewImageModal from './PreviewImageModal';
import ExportSettingsForm from './ExportSettingsForm';

import { ExportSettingsCard } from './styles';

const ExportSettings = props => (
  <ExportSettingsCard
    data-html2canvas-ignore
    title="Print export settings"
    extra={<ImageSize />}
  >
    <Dimensions />
    <ExportSettingsForm />
    <PreviewImageModal {...props} />
  </ExportSettingsCard>
);

const mapStateToProps = ({ exportSettings }) => ({ ...exportSettings });

export default connect(mapStateToProps, null)(ExportSettings);
