import message from 'antd/lib/message';

const showMessageDuration = 3;

export const showInfoNotification = (info) => {
  message.success(info, showMessageDuration);
};

export const showErrorNotification = (error) => {
  message.error(error, showMessageDuration);
};
