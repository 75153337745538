import React from 'react';

import { activeStyle, Link } from 'layout/Header/style';

const NavLinks = [{
  name: 'Maps',
  path: '/maps/',
}, {
  name: 'Datasets',
  path: '/datasets/',
}];

const PagesNavigation = () => (
  NavLinks.map(({ name, path }) => (
    <Link key={name} activeStyle={activeStyle} to={path}>{name}</Link>
  )));

export default PagesNavigation;
