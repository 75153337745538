import React from 'react';
import Icon from 'antd/lib/icon';

import { ReactComponent as PinIcon } from './pin.svg';

const size = {
  height: '25px',
  width: '25px',
};

const StyledPin = () => <PinIcon style={size} />;

const Pin = () => <Icon component={StyledPin} />;

export default Pin;
