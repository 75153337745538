import React from 'react';
import PropTypes from 'prop-types';

import { StyledMapButton } from './styles';

const StyleMapButton = ({ openModal }) => (
  <StyledMapButton onClick={openModal}>Style Map</StyledMapButton>
);

StyleMapButton.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default StyleMapButton;
