import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'antd/lib/form';
import get from 'lodash/get';
import find from 'lodash/find';

import FilterAndSplitForm from './FilterAndSplitForm';
import { FunctionSelect, FieldSelect, NameInput } from './AggregateDataFormItems';
import { StyledData, StyledSpace, StyledDataTitle } from './styles';

const AggregateDataFormCreator = ({
  form,
  mapDatasets,
  aggregatableFields,
  children,
  aggregateName,
  aggregates,
  insightId,
  updateInsight,
  allMapDatasets,
}) => {
  const aggregate = find(aggregates, { id: insightId });
  const splitOn = get(aggregate, 'splitOn', 'NONE');
  const [splitValue, changeSplitOnValue] = useState(() => splitOn);

  const { getFieldsValue } = form;
  const formValues = getFieldsValue(['name', 'function', 'field', 'mapDatasetIds', 'showSubtotals', 'splitOn']);

  return (
    <Fragment>
      <Form>
        <NameInput form={form} aggregateName={aggregateName} />
        <StyledDataTitle>Data</StyledDataTitle>
        <StyledData>
          <FunctionSelect
            form={form}
            insightId={insightId}
            aggregates={aggregates}
            aggregatableFields={aggregatableFields}
          />
          <StyledSpace>of</StyledSpace>
          <FieldSelect
            form={form}
            insightId={insightId}
            aggregates={aggregates}
            aggregatableFields={aggregatableFields}
          />
        </StyledData>
        <br />
        <FilterAndSplitForm
          form={form}
          aggregates={aggregates}
          insightId={insightId}
          splitValue={splitValue}
          changeSplitOnValue={changeSplitOnValue}
          formValues={formValues}
          updateInsight={updateInsight}
          mapDatasets={mapDatasets}
          allMapDatasets={allMapDatasets}
        >
          {children}
        </FilterAndSplitForm>
      </Form>
    </Fragment>
  );
};

AggregateDataFormCreator.propTypes = {
  form: PropTypes.object.isRequired,
  mapDatasets: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired,
  updateInsight: PropTypes.func,
  allMapDatasets: PropTypes.array,
  aggregatableFields: PropTypes.array.isRequired,
  aggregateName: PropTypes.string,
  aggregates: PropTypes.array,
  insightId: PropTypes.string,
};

const AggregateDataForm = React.memo(
  Form.create()(
    AggregateDataFormCreator,
  ),
);

export default AggregateDataForm;
