import styled from 'styled-components';

export const PickedColorContainer = styled.div`
  display: inline-block;
  padding: 5px;
  margin-left: 1rem;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
`;

export const Color = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${props => props.color};
`;
