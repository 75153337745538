import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Section, DangerLink, Link as LinkContainer } from './styles';

const CardInsightsButtons = ({ id, openModal, destroyInsight }) => {
  const destroy = () => destroyInsight(id);

  return (
    <Section>
      <LinkContainer type="link">
        <Link to="#" onClick={openModal}>Edit</Link>
      </LinkContainer>
      <DangerLink type="link" onClick={destroy}>Remove</DangerLink>
    </Section>
  );
};

CardInsightsButtons.propTypes = {
  id: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  destroyInsight: PropTypes.func.isRequired,
};

export default CardInsightsButtons;
