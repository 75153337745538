import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from 'antd/lib/icon';

import { ExportSettings } from 'components';
import { ToggleExportSettingsButtonContainer } from './styles';


const ToggleExportSettingsButton = ({ handleToggleExportSettings, showExportSettings }) => (
  <Fragment>
    <ToggleExportSettingsButtonContainer
      data-html2canvas-ignore
      onClick={handleToggleExportSettings}
    >
      <Icon type="download" />
    </ToggleExportSettingsButtonContainer>
    {showExportSettings && <ExportSettings />}
  </Fragment>
);

ToggleExportSettingsButton.propTypes = {
  handleToggleExportSettings: PropTypes.func.isRequired,
  showExportSettings: PropTypes.bool,
};

export default React.memo(ToggleExportSettingsButton);
