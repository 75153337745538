import styled from 'styled-components';

export const StaffContainer = styled.div`
  margin: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const StyledLocationContainer = styled.div`
  display: flex;
`;

export const LocationText = styled.span`
  width: 50%;
`;
