import React from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/lib/button';

import AggregateCreateService from '../service';
import { ButtonLayout } from './styles';

const CreateAggregateButton = ({ formValues, resetFormFields, closeModal }) => {
  const [createAggregate] = AggregateCreateService(closeModal);

  const handleCreateAggregate = () => {
    resetFormFields();
    createAggregate(formValues);
  };

  return (
    <ButtonLayout>
      <Button type="primary" onClick={handleCreateAggregate}>Save</Button>
    </ButtonLayout>
  );
};

CreateAggregateButton.propTypes = {
  formValues: PropTypes.object.isRequired,
  resetFormFields: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default CreateAggregateButton;
