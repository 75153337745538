import React from 'react';
import PropTypes from 'prop-types';
import Col from 'antd/lib/col';
import isEmpty from 'lodash/isEmpty';

import { MapDatasetsBadges } from 'components';

import Shapes from './Shapes';
import CardButtons from './CardButtons';

import { Card as CardView, CardDescription } from './styles';

const Card = ({
  name,
  cardDescription,
  mapShapes,
  editLink,
  deleteCard,
  mapDatasets,
}) => (
  <Col span={6} id={name}>
    <CardView>
      <CardDescription title={name} description={cardDescription} />
      <Shapes shapes={mapShapes} />
      {!isEmpty(mapDatasets) && <MapDatasetsBadges mapDatasets={mapDatasets} />}
      <CardButtons editLink={editLink} deleteCard={deleteCard} />
    </CardView>
  </Col>
);

Card.propTypes = {
  name: PropTypes.string.isRequired,
  cardDescription: PropTypes.string,
  mapShapes: PropTypes.array,
  editLink: PropTypes.string.isRequired,
  deleteCard: PropTypes.func.isRequired,
  mapDatasets: PropTypes.array,
};

Card.defaultProps = {
  mapShapes: [],
  mapDatasets: [],
  description: '',
  cardDescription: '',
};

export default Card;
