import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import ShapesSubTotals from '.';


const getSubDatasets = ({ subMapDatasets, mapDatasets }) => (
  subMapDatasets.map(({ id, value }) => ({ ...find(mapDatasets, { id }), value }))
);

const ShapesSubTotalsContainer = ({ mapDatasets, mapShapes, aggregateInfo }) => {
  const { showSubtotals, totals } = aggregateInfo;

  return !isEmpty(totals) && totals.mapShapes.map(({ subMapDatasets, id }) => {
    const shape = find(mapShapes, { id });
    const shapeTotals = find(totals.mapShapes, { id });
    const subDatasets = getSubDatasets({ subMapDatasets, mapDatasets });

    return (
      <ShapesSubTotals
        key={id}
        shape={shape}
        shapeTotals={shapeTotals}
        showSubtotals={showSubtotals}
        subDatasets={subDatasets}
      />
    );
  });
};

ShapesSubTotalsContainer.propTypes = {
  mapShapes: PropTypes.array.isRequired,
  mapDatasets: PropTypes.array.isRequired,
  aggregateInfo: PropTypes.object.isRequired,
};

export default ShapesSubTotalsContainer;
