import React from 'react';
import PropTypes from 'prop-types';

import VisualisationThumbnail from './VisualisationThumbnail';

import { thumbnails } from './thumbnails';
import { VISUALISATION_TYPES } from 'utils/constants/visualisationTypes';
import { StyledWrapper, StyledTitle } from './styles';

const { LOCATION, HEATMAP, CHOROPLETH } = VISUALISATION_TYPES;

const VisualisationType = ({ visualizationType, onChangeVisualizationType }) => {
  const handleOnChangeVisualizationType = ({ target }) => onChangeVisualizationType(target.value);

  return (
    <>
      <StyledTitle>Visualisation Type</StyledTitle>
      <fieldset onChange={handleOnChangeVisualizationType}>
        <StyledWrapper>
          {thumbnails.map(({
            id,
            title,
            text,
            imageSrc,
            disabled,
            type,
          }) => (
            <VisualisationThumbnail
              key={id}
              type={type}
              text={text}
              title={title}
              disabled={disabled}
              imageSrc={imageSrc}
              selected={type === visualizationType}
            />
          ))}
        </StyledWrapper>
      </fieldset>
    </>
  );
};

VisualisationType.propTypes = {
  onChangeVisualizationType: PropTypes.func.isRequired,
  visualizationType: PropTypes.oneOf([LOCATION, HEATMAP, CHOROPLETH]).isRequired,
};

export default React.memo(VisualisationType);
