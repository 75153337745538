import React from 'react';
import PropTypes from 'prop-types';

import { StyledFileName } from './style';

const ProgressFileName = ({ fileName }) => <StyledFileName>{fileName}</StyledFileName>;

ProgressFileName.propTypes = {
  fileName: PropTypes.string.isRequired,
};

export default ProgressFileName;
