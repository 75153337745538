import { useDispatch, useSelector } from 'react-redux';

import { selectPointEditMap as selectPointEditMapAction } from 'state/pointEditMap/actions';

const usePointEditMap = () => {
  const dispatch = useDispatch();
  const { selectedDataPoint } = useSelector(state => state.selectPointEditMap);

  const selectPointEditMap = args => dispatch(selectPointEditMapAction(args));

  return { selectedDataPoint, selectPointEditMap };
};

export default usePointEditMap;
