import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { BrokenImportedFile } from 'components';

const FilesWithError = ({ imports }) => (
  imports.map(item => !isEmpty(item.errorMessage) && <BrokenImportedFile key={item.id} {...item} />)
);

FilesWithError.propTypes = {
  imports: PropTypes.array.isRequired,
};

export default FilesWithError;
