import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import CardTotal from './CardTotal';

import { normalizeAggregatesCount } from './AggregatesNormalizer';
import { Card as CardView } from './styles';

const InsightCount = ({ totals }) => {
  if (isEmpty(totals)) {
    return null;
  }

  const count = normalizeAggregatesCount(totals.value);

  return <strong>{count}</strong>;
};

const AggregateCardBody = ({
  name,
  totals,
  children,
  mapShapes,
  aggregateInfo,
  allMapDatasets,
}) => (
  <CardView title={name} extra={<InsightCount totals={totals} />}>
    <CardTotal
      mapShapes={mapShapes}
      mapDatasets={allMapDatasets}
      aggregateInfo={aggregateInfo}
    />
    {!isEmpty(children) && children}
  </CardView>
);

AggregateCardBody.propTypes = {
  name: PropTypes.string,
  totals: PropTypes.object,
  children: PropTypes.object,
  mapShapes: PropTypes.array,
  aggregateInfo: PropTypes.object,
  allMapDatasets: PropTypes.array,
};

export default AggregateCardBody;
