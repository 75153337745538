import { ADD_BOUNDARIES, REMOVE_BOUNDARIES } from './types';

export const addBoundariesAction = payload => ({
  type: ADD_BOUNDARIES,
  payload,
});

export const removeBoundariesAction = payload => ({
  type: REMOVE_BOUNDARIES,
  payload,
});
