import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-map-gl';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

import Pin from './Pin';

const PreviewMapMarker = ({ onMarkerChange, marker }) => {
  const isMarkerExist = get(marker, 'latitude', false);

  const onMarkerDragEnd = ({ lngLat }) => {
    const [longitude, latitude] = lngLat;

    onMarkerChange({ longitude, latitude });
  };

  return isMarkerExist ? (
    <Marker
      draggable
      {...marker}
      offsetTop={-25}
      offsetLeft={-12.5}
      onDragEnd={onMarkerDragEnd}
    >
      <Pin />
    </Marker>
  ) : null;
};

PreviewMapMarker.propTypes = {
  onMarkerChange: PropTypes.func.isRequired,
  marker: PropTypes.object.isRequired,
};

export default React.memo(PreviewMapMarker, (prev, next) => isEqual(prev.marker, next.marker));
