import React, { Fragment } from 'react';
import { Field, FieldArray } from 'formik';
import Input from 'antd/lib/input';

import { Label } from 'components';

import { InputContainer, OptionContainer } from './style';
import SuggestedStatistics from '../SuggestedStatistics';
import AddField from '../AddField';
import DeleteItemButton from '../DeleteItemButton';

const FieldsArray = ({ statistics, statisticsFields }) => (
  <FieldArray
    name="statistics"
    render={arrayHelpers => (
      <Fragment>
        {statistics.map((statistic, index) => (
          <OptionContainer key={index}>
            <InputContainer>
              <Label colon={false} label="Field">
                <Field
                  name={`statistics[${index}].name`}
                  render={({ field }) => (
                    <Fragment>
                      <Input list="statistics" placeholder="e.g. Employees" {...field} />
                      <SuggestedStatistics statisticsFields={statisticsFields} />
                    </Fragment>
                  )}
                />
              </Label>
            </InputContainer>
            <InputContainer>
              <Label colon={false} label="Value">
                <Field
                  name={`statistics.${index}.value`}
                  render={({ field }) => (
                    <Input placeholder="500" {...field} />
                  )}
                />
              </Label>
            </InputContainer>
            <DeleteItemButton arrayHelpers={arrayHelpers} index={index} />
          </OptionContainer>
        ))}
        <AddField arrayHelpers={arrayHelpers} statistics={statistics} />
      </Fragment>
    )}
  />
);

export default FieldsArray;
